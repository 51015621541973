import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  MenuDivider,
} from "@chakra-ui/react";
import { ChangeEvent, useContext } from "react";
import { FiSearch } from "react-icons/fi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../State/Reach/ReachUsersContext";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HvTextInput } from "../../Common/HvTextInput";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  Disabled_Bg,
  Equity_Black,
  INPUT_PRIMARY_TEXT_COLOR,
  REACH_USERS_FILTER_BORDER_COLOR,
} from "../../../Styles/HeadversityStyle";
import { createAndDownloadCSV } from "../../../Utils/Helpers";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ReachUsersSearchSelects } from "./ReachUsersSearchSelects";
import { useReachGlobal } from "../../../Hooks/Reach/useReachGlobal";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ReachUsersToolbarProps {
  onSearchFilterChanged: (filter: string) => void;
  setIsViewJobsOpen: (isOpen: boolean) => void;
  onShareInviteCodeClicked: () => void;
}
const ReachUsersToolbar = ({
  onSearchFilterChanged,
  setIsViewJobsOpen,
  onShareInviteCodeClicked,
}: ReachUsersToolbarProps) => {
  const {
    setUserModalOpen,
    searchFilter,
    setSearchFilter,
    downloadCSVUsers,
    certsFilter,
    completionStatusFilter,
    inviteCodesForReach,
  } = useContext(ReachUsersContext);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const searchUsers = () => {
    onSearchFilterChanged(searchFilter);
    track(REACH_EVENTS.ReachUsersSearchUsers);
  };

  const onSearchFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
  };

  const clearUserFilter = () => {
    setSearchFilter("");
    onSearchFilterChanged("");
    track(REACH_EVENTS.ReachUsersClearUserFilter);
  };

  const { reformatUsersDataForCSV } = useReachGlobal();
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const downloadCSV = async () => {
    let users = await downloadCSVUsers(
      Number.MAX_SAFE_INTEGER,
      1,
      "email",
      "asc",
      searchFilter,
      certsFilter,
      completionStatusFilter
    );
    const reformattedUsers = reformatUsersDataForCSV(
      users.data,
      inviteCodesForReach,
      selectedUserLanguage
    );
    createAndDownloadCSV(
      reformattedUsers,
      HVLocalizeStrings.REACH_USERS_DOWNLOAD_CSV_FILE_NAME
    );
  };

  return (
    <Flex justifyContent={"space-between"} w={"100%"} py={2}>
      <Flex
        direction="column"
        w={"100%"}
        justifyContent="space-between"
        gap={2}
      >
        <Flex direction="row" alignItems="center">
          <InputGroup width={"300px"}>
            <InputRightElement>
              <Box pt={2} pr={1} as="button" onClick={searchUsers}>
                <IconButton
                  bg={"transparent"}
                  as={FiSearch}
                  boxSize={5}
                  color={INPUT_PRIMARY_TEXT_COLOR}
                  onClick={searchUsers}
                  aria-label={HVLocalizeStrings.REACH_USER_SEARCH}
                  cursor={"pointer"}
                  _hover={{ bg: "transparent" }}
                  data-testid={HVTestId.ReachUsersToolbar.searchButton}
                />
              </Box>
            </InputRightElement>
            <HvTextInput
              placeholder={HVLocalizeStrings.REACH_USER_SEARCH}
              onEnterKeyDown={searchUsers}
              onChange={onSearchFilterChange}
              value={searchFilter}
              borderColor={REACH_USERS_FILTER_BORDER_COLOR}
              dataTestId={HVTestId.ReachUsersToolbar.usersSearch}
            />
          </InputGroup>
          {searchFilter && (
            <Button
              color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
              colorScheme="orange"
              variant="link"
              size="sm"
              onClick={clearUserFilter}
              textDecoration={"underline"}
              ml={isMobile ? 1 : 6}
              mt={isMobile ? 2 : 0}
              fontWeight={"normal"}
              data-testid={HVTestId.ReachUsersToolbar.clearSearch}
            >
              {HVLocalizeStrings.REACH_USERS_CLEAR_SEARCH}
            </Button>
          )}
        </Flex>
        <Flex direction="row" w={"100%"} alignItems="center">
          {isDesktop && <ReachUsersSearchSelects />}
          {isDesktop && <Spacer />}
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Fourth}
            text={HVLocalizeStrings.REACH_USERS_ADD_USER}
            onClick={() => {
              setUserModalOpen(UserModalOpen.ADD);
              track(REACH_EVENTS.ReachUsersOpenAddSingleUserForm);
            }}
            mr="5px"
            boxShadow={"lg"}
            w={"auto"}
            dataTestId={HVTestId.ReachUsersToolbar.addUser}
          />
          <Menu>
            <MenuButton
              h="44px"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              data-testid={HVTestId.ReachUsersToolbar.dropdown}
            >
              {HVLocalizeStrings.REACH_USERS_ACTIONS}
            </MenuButton>
            <MenuList
              sx={{
                transition: "none !important",
                transform: "none !important",
              }}
              border={`1px solid ${Equity_Black}`}
            >
              <MenuItem
                onClick={() => {
                  setUserModalOpen(UserModalOpen.BULK);
                  track(REACH_EVENTS.ReachUsersOpenBulkUserModal);
                }}
                data-testid={HVTestId.ReachUsersToolbar.upload}
              >
                {HVLocalizeStrings.REACH_USERS_BULK_UPLOAD}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onShareInviteCodeClicked();
                  track(REACH_EVENTS.ReachUsersOpenShareInviteCodeModal);
                }}
              >
                {HVLocalizeStrings.REACH_USERS_INVITE_USERS}
              </MenuItem>
              <MenuDivider borderColor={Disabled_Bg} />
              <MenuItem
                onClick={() => {
                  setIsViewJobsOpen(true);
                  track(REACH_EVENTS.ReachUsersOpenHistoryModal);
                }}
              >
                {HVLocalizeStrings.VIEW_HISTORY}
              </MenuItem>
              <MenuDivider borderColor={Disabled_Bg} />
              <MenuItem
                onClick={() => {
                  downloadCSV();
                }}
              >
                {HVLocalizeStrings.REACH_USERS_DOWNLOAD_REPORT}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReachUsersToolbar;
