import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ErrorCodeType } from "@headversity/contract";

export const generateErrorFromBackend = (
  errorCode: number,
  inviteCode: string | null
) => {
  inviteCode = inviteCode ?? "";
  switch (errorCode) {
    case ErrorCodeType.EmailInvalid:
      return HVLocalizeStrings.EMAIL_IS_INVALID;
    case ErrorCodeType.EmailAlreadyExists:
      return HVLocalizeStrings.EMAIL_IS_USED_ALREADY;
    case ErrorCodeType.FullNameInvalid:
      return HVLocalizeStrings.FULL_NAME_IS_MANDATORY;
    case ErrorCodeType.PasswordInvalid:
      return HVLocalizeStrings.PASSWORD_NEEDS_TO_RESPECT_FORMAT;
    case ErrorCodeType.InviteCodeInvalid:
      return HVLocalizeStrings.INVITE_CODE_IS_INVALID;
    case ErrorCodeType.InvalidOrAlreadyUsedCode:
      return HVLocalizeStrings.INVALID_OR_ALREADY_USED_CODE;
    case ErrorCodeType.InviteCodeIsExpired:
      return HVLocalizeStrings.ACCOUNT_EXPIRED;
    case ErrorCodeType.TeamCodeDoesNotExist:
      return HVLocalizeStrings.TEAM_ERROR_TEAM_CODE_DOES_NOT_EXIST;
    case ErrorCodeType.TeamCodeIsOnWrongCompany:
      return HVLocalizeStrings.TEAM_ERROR_TEAM_CODE_IS_ON_WRONG_COMPANY;
    case ErrorCodeType.TeamOnWrongCompany:
      return HVLocalizeStrings.TEAM_ERROR_TEAM_IS_ON_WRONG_COMPANY;
    case ErrorCodeType.UserDoesNotBelongToTeam:
      return HVLocalizeStrings.TEAM_ERROR_USER_DOES_NOT_BELONG_TO_TEAM;
    case ErrorCodeType.EmailHasProblem:
      return HVLocalizeStrings.EMAIL_HAS_PROBLEM;
    case ErrorCodeType.EmailDomainNotAllowed:
      return HVLocalizeStrings.EMAIL_DOMAIN_NOT_ALLOWED;
    case ErrorCodeType.UserNotInCompany:
      return HVLocalizeStrings.TEAM_ERROR_USER_REGISTERED_WITH_DIFFERENT_COMPANY;
  }
  return null;
};

export const generateErrorFromErrorName = (errorName: string) => {
  switch (errorName) {
    case "Invalid request body. All and only of client_id, credential_type, username, otp, realm are required.":
      return HVLocalizeStrings.LOGIN_ERROR_INVALID_REQUEST_BODY;
    case "Cross origin login not allowed.":
      return HVLocalizeStrings.LOGIN_ERROR_CROSS_ORIGIN_NOT_ALLOWED;
    case "Unknown credential type parameter.":
      return HVLocalizeStrings.LOGIN_ERROR_UNKNOWN_CREDENTIAL_TYPE_PARAMETER;
    case "Unknown realm non-existent-connection.":
      return HVLocalizeStrings.LOGIN_ERROR_UNKNOWN_REALM;
    case "Wrong email or password.":
      return HVLocalizeStrings.LOGIN_ERROR_UNKNOWN_EMAIL_OR_PASSWORD;
    case "Authentication error":
      return HVLocalizeStrings.LOGIN_ERROR_AUTHENTICATION_ERROR;
    case "Blocked user":
      return HVLocalizeStrings.LOGIN_ERROR_BLOCKED_USER;
    case "This login attempt has been blocked because the password you're using was previously disclosed through a data breach (not in this application).":
      return HVLocalizeStrings.LOGIN_ERROR_DATA_BREACH;
    case "Your account has been blocked after multiple consecutive login attempts. We’ve sent you an email with instructions on how to unblock it.":
      return HVLocalizeStrings.LOGIN_ERROR_MULTIPLE_ATTEMPTS;
    case "We have detected suspicious login behavior and further attempts will be blocked. Please contact the administrator.":
      return HVLocalizeStrings.LOGIN_ERROR_SUSPICIOUS_BEHAVIOR;
    case "Password change required.":
      return HVLocalizeStrings.LOGIN_ERROR_RESET_PASSWORD;
    case "Unknown error from provider":
      return HVLocalizeStrings.LOGIN_ERROR_UNKNOWN_ERROR;
    case "Invite code is expired":
      return HVLocalizeStrings.ACCOUNT_EXPIRED;
    case "Too many attempts":
      return HVLocalizeStrings.LOGIN_ERROR_TOO_MANY_ATTEMPTS;
    default:
      return errorName;
  }
};
