import {
  AvailableReportsDto,
  CertDto,
  CertReport,
  GetSelfServeUsersDto,
  HelpResourcesReport,
  InviteCodeForReachDto,
  PromotionalMaterialAssetDto,
  PromotionalMaterialDto,
  PsychographicsReport,
  SelfServeUserDto,
  SoloTeamReport,
  UpdateSelfServeUserDto,
  UserCertStatsDto,
  UsersReport,
  GoalWinThemeDto,
} from "@headversity/contract";
import {
  EligibilityFileJobDto,
  GetEligibilityFileJobsDto,
} from "@headversity/contract/Dto/EligibilityFileJobDto";
import { AxiosResponse } from "axios";
import { EligibilityImportType } from "../../State/Reach/ReachUsersContext";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
const REACH_BASE_URL = "/api/promotionalMaterials";

export const getPromoMaterials = (
  token: string
): Promise<AxiosResponse<PromotionalMaterialDto[]>> => {
  return AxiosClient.get<PromotionalMaterialDto[]>(
    REACH_BASE_URL,
    getConfig(token)
  );
};

const isFalsy = (val?: string): boolean => {
  return val === undefined || val === null || val === "";
};

export const getReachUsers = async (filters: {
  token: string;
  usersPerPage: number;
  page: number;
  sortBy?: string;
  sortDir?: string;
  searchFilter?: string;
  certsFilter?: number[];
  completionStatusesFilter?: number[];
  fetchForCsv?: boolean;
}): Promise<AxiosResponse<GetSelfServeUsersDto>> => {
  const {
    token,
    usersPerPage,
    page,
    sortBy,
    sortDir,
    searchFilter,
    certsFilter,
    completionStatusesFilter,
    fetchForCsv,
  } = filters;

  let queryString = `page=${page}&limit=${usersPerPage}&sortBy=${
    !isFalsy(sortBy) ? sortBy : "email"
  }&sortDir=${sortDir ?? "asc"}&searchFilter=${
    searchFilter ?? ""
  }&fetchForCsv=${fetchForCsv ?? false}`;

  if (certsFilter && certsFilter.length > 0) {
    const certsFilterString = certsFilter.join(",");
    queryString += `&certsFilter=${certsFilterString}`;
  }

  if (completionStatusesFilter && completionStatusesFilter.length > 0) {
    const completionStatusesFilterString = completionStatusesFilter.join(",");
    queryString += `&completionStatusesFilter=${completionStatusesFilterString}`;
  }

  return AxiosClient.get<GetSelfServeUsersDto>(
    `/api/users?${queryString}`,
    getConfig(token)
  );
};

export const getEligibilityJobs = async (
  token: string,
  limit?: number,
  page?: number
): Promise<AxiosResponse<GetEligibilityFileJobsDto>> => {
  return AxiosClient.get<GetEligibilityFileJobsDto>(
    `/api/users/jobs?sortBy=updated_at&sortDir=desc&filter=${page ?? 1}&limit=${
      limit ?? 100
    }`,
    getConfig(token)
  );
};

export const getCompanyInviteCodesForReach = async (
  token: string
): Promise<AxiosResponse<InviteCodeForReachDto[]>> => {
  return AxiosClient.get<InviteCodeForReachDto[]>(
    "/api/users/inviteCodes",
    getConfig(token)
  );
};

export const deleteReachUser = async (
  token: string,
  userId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(`/api/users/${userId}`, getConfig(token));
};

export const upsertReachUser = async (
  token: string,
  userDto?: UpdateSelfServeUserDto
): Promise<AxiosResponse<SelfServeUserDto>> => {
  if (userDto?.id) {
    return AxiosClient.put<SelfServeUserDto>(
      `/api/users/${userDto.id}`,
      userDto,
      getConfig(token)
    );
  }
  return AxiosClient.post<SelfServeUserDto>(
    `/api/users`,
    userDto,
    getConfig(token)
  );
};

export const uploadEligibilityFileCsv = async (
  file: any,
  importType: EligibilityImportType,
  timezone: string,
  certAssignments: number[],
  token: string
): Promise<AxiosResponse<EligibilityFileJobDto>> => {
  // create a new FormData object and append the file to it
  const formData = new FormData();
  formData.append("file", file);
  formData.append("importType", EligibilityImportType[importType]);
  formData.append("certAssignments", certAssignments.join(","));
  return AxiosClient.post<any>(
    `/api/users/upload?tz=${timezone}`,
    formData,
    getConfig(token)
  );
};

export const getCompanyCertsForReach = async (
  companyId: number,
  token: string
): Promise<AxiosResponse<CertDto[]>> => {
  return AxiosClient.get<CertDto[]>(
    `/api/companies/${companyId}/certs`,
    getConfig(token)
  );
};

export const confirmEligibilityFileJob = async (
  jobId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `/api/users/jobs/${jobId}/confirm`,
    null,
    getConfig(token)
  );
};

export const savePromoMaterialDownloaded = (
  token: string,
  promoMaterialAssetId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `${REACH_BASE_URL}/assetDownloaded/${promoMaterialAssetId}`,
    null,
    getConfig(token)
  );
};

export const getScormUrl = (
  certId: number,
  token: string
): Promise<AxiosResponse<string>> => {
  return AxiosClient.get<string>(`/api/scorm/${certId}`, getConfig(token));
};

export const getPromoMaterialAssets = (
  promoMaterialId: number,
  token: string
): Promise<AxiosResponse<PromotionalMaterialAssetDto[]>> => {
  return AxiosClient.get<PromotionalMaterialAssetDto[]>(
    `${REACH_BASE_URL}/${promoMaterialId}/assets`,
    getConfig(token)
  );
};

export const getCertsStatsForUser = async (
  userId: number,
  token: string
): Promise<AxiosResponse<UserCertStatsDto[]>> => {
  return AxiosClient.get<UserCertStatsDto[]>(
    `/api/users/${userId}/certs`,
    getConfig(token)
  );
};

export const unAssignCertFromUser = async (
  userId: number,
  certId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(
    `/api/users/${userId}/certs/${certId}`,
    getConfig(token)
  );
};

export const assignCertToUser = async (
  userId: number,
  certId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `/api/users/${userId}/certs/${certId}`,
    null,
    getConfig(token)
  );
};

export const saveCompanyGoalWinThemes = (
  goalWinThemes: GoalWinThemeDto[],
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `api/goal-win-themes`,
    goalWinThemes,
    getConfig(token)
  );
};

export const reportUsers = async (
  token: string
): Promise<AxiosResponse<UsersReport>> => {
  return AxiosClient.get<UsersReport>(`/api/reports/users`, getConfig(token));
};

export const reportCert = async (
  token: string
): Promise<AxiosResponse<CertReport>> => {
  return AxiosClient.get<CertReport>(`/api/reports/cert`, getConfig(token));
};

export const reportSoloTeam = async (
  token: string
): Promise<AxiosResponse<SoloTeamReport>> => {
  return AxiosClient.get<SoloTeamReport>(
    `/api/reports/soloTeam`,
    getConfig(token)
  );
};

export const reportPsychographics = async (
  token: string
): Promise<AxiosResponse<PsychographicsReport>> => {
  return AxiosClient.get<PsychographicsReport>(
    `/api/reports/psychographics`,
    getConfig(token)
  );
};

export const reportGetHelp = async (
  token: string
): Promise<AxiosResponse<HelpResourcesReport>> => {
  return AxiosClient.get<HelpResourcesReport>(
    `/api/reports/helpResources`,
    getConfig(token)
  );
};

export const getAvailableReports = async (
  token: string
): Promise<AxiosResponse<AvailableReportsDto>> => {
  return AxiosClient.get<AvailableReportsDto>(
    `/api/reports/available`,
    getConfig(token)
  );
};
