import { AppType } from "../../State/GlobalContext";
import { useState } from "react";

export type IResourceLock = {
  resourceType: string;
  resourceId: number;
};

export interface IAppProvider {
  currentApp: AppType | null;
  setCurrentApp: (currentApp: AppType | null) => void;
  soloEnabled: boolean | null;
  setSoloEnabled: (soloEnabled: boolean | null) => void;
  teamEnabled: boolean | null;
  setTeamEnabled: (teamEnabled: boolean | null) => void;
  reachEnabled: boolean | null;
  setReachEnabled: (reachEnabled: boolean | null) => void;
  certEnabled: boolean | null;
  setCertEnabled: (certEnabled: boolean | null) => void;
  isReachAdminEnabled: boolean | null;
  setIsReachAdminEnabled: (isReachAdminEnabled: boolean | null) => void;
  resourceLock: IResourceLock | null;
  setResourceLock: (resourceLock: IResourceLock | null) => void;
}
export const useApp = (): IAppProvider => {
  const [currentApp, setCurrentApp] = useState<AppType | null>(null);
  const [soloEnabled, setSoloEnabled] = useState<boolean | null>(null);
  const [teamEnabled, setTeamEnabled] = useState<boolean | null>(null);
  const [reachEnabled, setReachEnabled] = useState<boolean | null>(null);
  const [certEnabled, setCertEnabled] = useState<boolean | null>(null);
  const [isReachAdminEnabled, setIsReachAdminEnabled] = useState<
    boolean | null
  >(null);
  const [resourceLock, setResourceLock] = useState<IResourceLock | null>(null);

  return {
    currentApp,
    setCurrentApp,
    soloEnabled,
    setSoloEnabled,
    teamEnabled,
    setTeamEnabled,
    reachEnabled,
    setReachEnabled,
    certEnabled,
    setCertEnabled,
    isReachAdminEnabled,
    setIsReachAdminEnabled,
    resourceLock,
    setResourceLock,
  };
};
