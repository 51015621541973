import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CreateHelpResourceDto, LocalizationText } from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import {
  createGetHelpResource,
  saveGetHelpResource,
} from "../../../Api/Reach/ReachOrganizationsApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { getKey } from "../../../Utils/Helpers";
import { stringOrEmpty } from "../../../Utils/StringUtils";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { LargeModal } from "../../Common/LargeModal";
import ReachHelpResourcesModalTab, {
  ModalTabProps,
} from "./ReachHelpResourcesModalTab";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";

interface ReachOrganizationsHelpResourceModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const ReachHelpResourcesModal = (
  props: ReachOrganizationsHelpResourceModalProps
) => {
  const { isOpen } = props;

  const isDesktop = useBreakpointValue({ base: false, lg: true }) ?? false;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isMissingFields, setIsMissingFields] = useState<boolean>(true);

  const { self } = useContext(GlobalContext);
  const orgContext = useContext(ReachHelpResourcesContext);
  useEffect(() => {
    if (orgContext.selectedHelpResource) {
      // editing existing resource
      const resource = orgContext.selectedHelpResource;
      setEnglishTabData({
        name: stringOrEmpty((resource.name as LocalizationText).en as string),
        description: stringOrEmpty(
          (resource.description as LocalizationText).en as string
        ),
        logoUrl: stringOrEmpty(
          (resource.logoUrl as LocalizationText).en as string
        ),
        phoneNumber: stringOrEmpty(
          (resource.phoneNumber as LocalizationText).en as string
        ),
        textNumber: stringOrEmpty(
          (resource.textNumber as LocalizationText).en as string
        ),
        iosAppLink: stringOrEmpty(
          (resource.iosAppUrl as LocalizationText).en as string
        ),
        androidAppLink: stringOrEmpty(
          (resource.androidAppUrl as LocalizationText).en as string
        ),
        websiteLink: stringOrEmpty(
          (resource.websiteUrl as LocalizationText).en as string
        ),
      });
      setFrenchTabData({
        name: stringOrEmpty((resource.name as LocalizationText).fr as string),
        description: stringOrEmpty(
          (resource.description as LocalizationText).fr as string
        ),
        logoUrl: stringOrEmpty(
          (resource.logoUrl as LocalizationText).fr as string
        ),
        phoneNumber: stringOrEmpty(
          (resource.phoneNumber as LocalizationText).fr as string
        ),
        textNumber: stringOrEmpty(
          (resource.textNumber as LocalizationText).fr as string
        ),
        iosAppLink: stringOrEmpty(
          (resource.iosAppUrl as LocalizationText).fr as string
        ),
        androidAppLink: stringOrEmpty(
          (resource.androidAppUrl as LocalizationText).fr as string
        ),
        websiteLink: stringOrEmpty(
          (resource.websiteUrl as LocalizationText).fr as string
        ),
      });
      setSpanishTabData({
        name: stringOrEmpty((resource.name as LocalizationText).es as string),
        description: stringOrEmpty(
          (resource.description as LocalizationText).es as string
        ),
        logoUrl: stringOrEmpty(
          (resource.logoUrl as LocalizationText).es as string
        ),
        phoneNumber: stringOrEmpty(
          (resource.phoneNumber as LocalizationText).es as string
        ),
        textNumber: stringOrEmpty(
          (resource.textNumber as LocalizationText).es as string
        ),
        iosAppLink: stringOrEmpty(
          (resource.iosAppUrl as LocalizationText).es as string
        ),
        androidAppLink: stringOrEmpty(
          (resource.androidAppUrl as LocalizationText).es as string
        ),
        websiteLink: stringOrEmpty(
          (resource.websiteUrl as LocalizationText).es as string
        ),
      });
    }
  }, [orgContext.selectedHelpResource]);

  const getInitialTabData = (): ModalTabProps => {
    return {
      name: "",
      description: "",
      logoUrl: "",
      phoneNumber: "",
      textNumber: "",
      iosAppLink: "",
      androidAppLink: "",
      websiteLink: "",
      isValidAndroidAppLink: true,
      isValidIosAppLink: true,
      isValidWebsiteAppLink: true,
    };
  };
  const [englishTabData, setEnglishTabData] = useState<ModalTabProps>(
    getInitialTabData()
  );
  const [frenchTabData, setFrenchTabData] = useState<ModalTabProps>(
    getInitialTabData()
  );
  const [spanishTabData, setSpanishTabData] = useState<ModalTabProps>(
    getInitialTabData()
  );
  const validateTabData = (tabData: ModalTabProps | undefined) => {
    const isDataInvalid = !tabData?.name?.trim();
    setIsMissingFields(isDataInvalid);
  };

  useEffect(() => {
    validateTabData(englishTabData);
  }, [englishTabData, frenchTabData, spanishTabData]);

  const handleCreateHelpResource = () => {
    setIsSaving(true);
    const createHelpResourceDto: CreateHelpResourceDto = {
      sortOrder: orgContext.selectedHelpResource?.sortOrder || 0,
      name: {
        en: englishTabData.name,
        fr: frenchTabData.name,
        es: spanishTabData.name,
      },
      description: {
        en: englishTabData.description,
        fr: frenchTabData.description,
        es: spanishTabData.description,
      },
      logoUrl: {
        en: englishTabData.logoUrl,
        fr: frenchTabData.logoUrl,
        es: spanishTabData.logoUrl,
      },
      phoneNumber: {
        en: englishTabData.phoneNumber,
        fr: frenchTabData.phoneNumber,
        es: spanishTabData.phoneNumber,
      },
      textNumber: {
        en: englishTabData.textNumber,
        fr: frenchTabData.textNumber,
        es: spanishTabData.textNumber,
      },
      iosAppUrl: {
        en: englishTabData.iosAppLink,
        fr: frenchTabData.iosAppLink,
        es: spanishTabData.iosAppLink,
      },
      androidAppUrl: {
        en: englishTabData.androidAppLink,
        fr: frenchTabData.androidAppLink,
        es: spanishTabData.androidAppLink,
      },
      websiteUrl: {
        en: englishTabData.websiteLink,
        fr: frenchTabData.websiteLink,
        es: spanishTabData.websiteLink,
      },
      inviteCodeId: orgContext.selectedInviteCodeId
        ? orgContext.selectedInviteCodeId
        : self!.inviteCodeId,
    };

    if (orgContext.selectedHelpResource) {
      saveGetHelpResource(
        getKey(),
        orgContext.selectedHelpResource.id,
        createHelpResourceDto
      ).then(() => {
        setIsSaving(false);
        props.setIsOpen(false);
        orgContext.setSelectedHelpResource(undefined);
        orgContext.refreshHelpResources();
        track(REACH_EVENTS.ReachHelpResEditedHelpResource);
      });
    } else {
      createGetHelpResource(getKey(), createHelpResourceDto).then(() => {
        setIsSaving(false);
        props.setIsOpen(false);
        orgContext.setSelectedHelpResource(undefined);
        orgContext.refreshHelpResources();
        track(REACH_EVENTS.ReachHelpResAddedHelpResource);
      });
    }
  };

  return (
    <>
      {isOpen && (
        <LargeModal
          open={isOpen}
          setOpen={props.setIsOpen}
          size={isDesktop ? undefined : "full"}
          onCloseTasks={() => {
            // clear the selected
            orgContext.setSelectedHelpResource(undefined);
          }}
        >
          <Flex flexDirection="column">
            <Text as="b" fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR} m={4}>
              {orgContext.selectedHelpResource
                ? HVLocalizeStrings.REACH_HELP_RESOURCE
                : HVLocalizeStrings.REACH_HELP_RESOURCE_ADD_NEW}
            </Text>
            <Box p={2} borderWidth="1px" borderRadius="lg">
              <Tabs variant="line" m={2}>
                <TabList>
                  <LanguageTab language={HVLocalizeStrings.LANGUAGE_ENGLISH} />
                  <LanguageTab language={HVLocalizeStrings.LANGUAGE_FRENCH} />
                  <LanguageTab language={HVLocalizeStrings.LANGUAGE_SPANISH} />
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <ReachHelpResourcesModalTab
                      onInputChanged={(
                        field: string,
                        value: string | boolean
                      ) => {
                        const prev = englishTabData;
                        const next = { ...prev, [field]: value };
                        setEnglishTabData(next);
                      }}
                      tabProps={englishTabData}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ReachHelpResourcesModalTab
                      onInputChanged={(
                        field: string,
                        value: string | boolean
                      ) => {
                        const prev = frenchTabData;
                        const next = { ...prev, [field]: value };
                        setFrenchTabData(next);
                      }}
                      tabProps={frenchTabData}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ReachHelpResourcesModalTab
                      onInputChanged={(
                        field: string,
                        value: string | boolean
                      ) => {
                        const prev = spanishTabData;
                        const next = { ...prev, [field]: value };
                        setSpanishTabData(next);
                      }}
                      tabProps={spanishTabData}
                    />
                  </TabPanel>
                </TabPanels>
                <Flex flexDirection="column">
                  <Center>
                    <AnimatedButton
                      colorSet={AnimatedButtonColorSet.Secondary}
                      w={210}
                      text={
                        orgContext.selectedHelpResource
                          ? HVLocalizeStrings.SAVE
                          : HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_SAVE
                      }
                      onClick={handleCreateHelpResource}
                      disabled={
                        isSaving ||
                        isMissingFields ||
                        orgContext.isUploading ||
                        !englishTabData.isValidAndroidAppLink ||
                        !englishTabData.isValidIosAppLink ||
                        !englishTabData.isValidWebsiteAppLink ||
                        !frenchTabData.isValidAndroidAppLink ||
                        !frenchTabData.isValidIosAppLink ||
                        !frenchTabData.isValidWebsiteAppLink ||
                        !spanishTabData.isValidAndroidAppLink ||
                        !spanishTabData.isValidIosAppLink ||
                        !spanishTabData.isValidWebsiteAppLink
                      }
                    />
                  </Center>
                </Flex>
              </Tabs>
            </Box>
          </Flex>
        </LargeModal>
      )}
    </>
  );
};

interface LanguageTabProps {
  language: string;
}

const LanguageTab = ({ language }: LanguageTabProps) => {
  return (
    <Tab
      color={MODAL_PRIMARY_TEXT_COLOR}
      _selected={{
        color: MODAL_THIRD_TEXT_COLOR,
        borderBottom: "3px solid",
      }}
    >
      {language}
    </Tab>
  );
};

export default ReachHelpResourcesModal;
