export enum QuestionType {
  Single,
  Multiple,
  FreeText,
  Headzone,
  Feedback,
  Scale5,
  Scale7,
  Scale11,
  Slider5,
  Slider7,
  Slider11,
}
