import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Center, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import {
  CertDto,
  CreateSelfServeUserDto,
  UpdateSelfServeUserDto,
  UserRole,
} from "@headversity/contract";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../../State/Reach/ReachUsersContext";
import {
  DESTRUCTIVE_SECONDARY_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HvSelect, HvSelectOption } from "../../../Common/HvSelect";
import { HvTextInput } from "../../../Common/HvTextInput";
import { LargeModal } from "../../../Common/LargeModal";
import { validateEmail } from "../../../../Utils/EmailUtil";
import { useReachGlobal } from "../../../../Hooks/Reach/useReachGlobal";
import { GlobalContext } from "../../../../State/GlobalContext";
import { ActivationDateTimePicker } from "./ActivationDateTimePicker";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface ReachUserModalProps {
  isOpen: boolean;
  user: CreateSelfServeUserDto | UpdateSelfServeUserDto;
  setUser: (user: CreateSelfServeUserDto | UpdateSelfServeUserDto) => void;
  fullName: string;
  setFullName: (fullName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  language: string;
  setLanguage: (language: string) => void;
  handleActivationDateTimeChanged: (date: Date) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  isMissingFields: boolean;
  upsertReachUser: (certsSelected?: CertDto[]) => void;
  resetStates: () => void;
  startingUser: CreateSelfServeUserDto;
}

const UserAddModal = (props: ReachUserModalProps) => {
  const {
    isOpen,
    user,
    setUser,
    fullName,
    setFullName,
    email,
    setEmail,
    language,
    setLanguage,
    handleActivationDateTimeChanged,
    isSaving,
    setIsSaving,
    isMissingFields,
    upsertReachUser,
    resetStates,
    startingUser,
  } = props;

  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isDirtyEmail, setIsDirtyEmail] = useState<boolean>(false);
  const [isValidFullName, setIsValidFullName] = useState<boolean>(true);
  const [isDirtyFullName, setIsDirtyFullName] = useState<boolean>(false);

  const { inviteCodesForReach, setUserModalOpen, companyCerts } =
    useContext(ReachUsersContext);

  // cert assignments are only required for learners in cert-only companies
  const [certOnlyRequireCert, setCertOnlyRequireCert] =
    useState<boolean>(false);
  const [showCertAssignmentsOptional, setShowCertAssignmentsOptional] =
    useState<boolean>(false);
  const [isDirtyRole, setIsDirtyRole] = useState<boolean>(false);
  const [isDirtyCertAssignments, setIsDirtyCertAssignments] =
    useState<boolean>(false);

  const { getCertOptions } = useReachGlobal();
  const [certsSelected, setCertsSelected] = useState<CertDto[]>([]);
  const certOptions = getCertOptions(companyCerts);
  const initialCertOptions = getCertOptions(certsSelected);
  const { soloEnabled, teamEnabled } = useContext(GlobalContext);

  useEffect(() => {
    const role = user.userFields.role;
    const isSoloTeamOrNoCompanyCerts =
      soloEnabled || teamEnabled || companyCerts.length === 0;
    if (isSoloTeamOrNoCompanyCerts || role !== UserRole.Learner) {
      setCertOnlyRequireCert(false);
    } else {
      setCertOnlyRequireCert(certsSelected.length === 0);
    }
    setShowCertAssignmentsOptional(
      isSoloTeamOrNoCompanyCerts || role === UserRole.Admin
    );
  }, [user, certsSelected]);

  const handleSave = () => {
    setIsSaving(true);
    upsertReachUser(certsSelected);
    setUserModalOpen(UserModalOpen.NONE);
    setUser(startingUser);
    resetStates();
    setIsSaving(false);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDirtyEmail(true);
    setEmail(event.target.value);
    setUser({
      ...user,
      userFields: {
        ...user?.userFields,
        email: event.target.value,
      },
    });
    setIsValidEmail(validateEmail(event.target.value) !== false);
  };

  const handleFullNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDirtyFullName(true);
    setFullName(event.target.value);
    setUser({
      ...user,
      userFields: {
        ...user?.userFields,
        fullName: event.target.value,
      },
    });
    setIsValidFullName(event.target.value.trim().includes(" "));
  };

  return (
    <LargeModal
      open={isOpen}
      setOpen={() => {}}
      size={"sizeOnContent"}
      onCloseTasks={() => {
        resetStates();
        setUserModalOpen(UserModalOpen.NONE);
      }}
      dataTestId={HVTestId.UserAddModal.modal}
    >
      <Flex flexDir={"column"} gap={4} color={MODAL_PRIMARY_TEXT_COLOR}>
        <Flex alignItems={"center"} gap={2}>
          <Icon ml="4px" mt={"-5px"} as={FiInfo} boxSize={6} />
          <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.REACH_USERS_TOOLTIP}
          </Text>
        </Flex>
        <Flex flexDir={"column"} gap={1}>
          <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.FULL_NAME}
          </Text>
          <HvTextInput
            type="text"
            value={fullName}
            onChange={handleFullNameChange}
            dataTestId={HVTestId.UserAddModal.fullName}
          />
          {isDirtyFullName && !isValidFullName && (
            <Text fontSize={"xs"} color={DESTRUCTIVE_SECONDARY_TEXT_COLOR}>
              {HVLocalizeStrings.REACH_USER_INVALID_FULL_NAME}
            </Text>
          )}
        </Flex>
        <Flex flexDir={"column"} gap={1}>
          <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.EMAIL_ADDRESS}
          </Text>
          <HvTextInput
            type="email"
            value={email}
            onChange={handleEmailChange}
            dataTestId={HVTestId.UserAddModal.email}
          />
          {isDirtyEmail && !isValidEmail && (
            <Text fontSize={"xs"} color={DESTRUCTIVE_SECONDARY_TEXT_COLOR}>
              {HVLocalizeStrings.REACH_USER_INVALID_EMAIL_ADDRESS}
            </Text>
          )}
        </Flex>
        <Flex flexDir={"column"} gap={1}>
          <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.REACH_USER_ROLE}
          </Text>
          <HvSelect
            placeholder={HVLocalizeStrings.SELECT}
            value={user?.userFields.role}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setUser({
                ...user,
                userFields: {
                  ...user?.userFields,
                  role: parseInt(event.target.value),
                },
              });
              setIsDirtyRole(true);
            }}
            data-testid={HVTestId.UserAddModal.role}
          >
            <option value={UserRole.Admin}>Admin</option>
            <option value={UserRole.Learner}>Learner</option>
          </HvSelect>
        </Flex>
        {companyCerts.length > 0 && (
          <Flex flexDir={"column"} gap={1}>
            <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
              {HVLocalizeStrings.REACH_USERS_CERTIFICATION_ASSIGNMENTS}
              {showCertAssignmentsOptional &&
                ` (${HVLocalizeStrings.REACH_USERS_OPTIONAL})`}
            </Text>
            <HvSelect
              isMultiSelection={true}
              options={certOptions}
              selectedValues={initialCertOptions}
              onMultiSelectChange={(value) => {
                const certs = value as HvSelectOption[];
                const certIds = certs.map((cert) => cert.value);
                const selectedCerts = companyCerts.filter((cert) =>
                  certIds.includes(cert.id)
                );
                setCertsSelected(selectedCerts);
                setIsDirtyCertAssignments(true);
              }}
              placeHolder={HVLocalizeStrings.REACH_CERT_SELECT}
              hasBlackBorder={true}
            />
            {isDirtyRole && isDirtyCertAssignments && certOnlyRequireCert && (
              <Text fontSize={"xs"} color={DESTRUCTIVE_SECONDARY_TEXT_COLOR}>
                {HVLocalizeStrings.REACH_USER_NO_CERT_ASSIGNMENTS_SELECTED}
              </Text>
            )}
          </Flex>
        )}
        <Flex flexDir={"column"} gap={1}>
          <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.LANGUAGE_TITLE} (
            {HVLocalizeStrings.REACH_USERS_OPTIONAL})
          </Text>
          <HvSelect
            placeholder={HVLocalizeStrings.SELECT}
            value={language}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setLanguage(event.target.value);
              setUser({
                ...user,
                userFields: {
                  ...user?.userFields,
                  language: event.target.value,
                },
              });
            }}
            data-testid={HVTestId.UserAddModal.language}
          >
            <option key={"en"} value={"en"}>
              {HVLocalizeStrings.LANGUAGE_ENGLISH}
            </option>
            <option key={"fr"} value={"fr"}>
              {HVLocalizeStrings.LANGUAGE_FRENCH}
            </option>
            <option key={"es"} value={"es"}>
              {HVLocalizeStrings.LANGUAGE_SPANISH}
            </option>
          </HvSelect>
        </Flex>

        <Flex flexDir={"column"} gap={1}>
          <Flex>
            <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
              {`${HVLocalizeStrings.REACH_ACTIVATION_EMAIL_SEND_DATE} (${HVLocalizeStrings.REACH_USERS_OPTIONAL})`}
            </Text>
            <Tooltip
              label={HVLocalizeStrings.REACH_USERS_ADD_USER_TOOLTIP}
              hasArrow={true}
              placement="top"
            >
              <span>
                <Icon ml={2} mt={-2} as={FiInfo} boxSize={4} />
              </span>
            </Tooltip>
          </Flex>
          <ActivationDateTimePicker
            onChange={handleActivationDateTimeChanged}
          />
          <Text fontSize="sm">
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </Text>
        </Flex>
        <Flex flexDir={"column"} gap={1}>
          <Text fontSize="lg" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.REACH_INVITE_CODE}
          </Text>
          <HvSelect
            placeholder={HVLocalizeStrings.SELECT}
            value={user?.userFields.inviteCodeId}
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              setUser({
                ...user,
                userFields: {
                  ...user?.userFields,
                  inviteCodeId: parseInt(event.target.value),
                },
              })
            }
            data-testid={HVTestId.UserAddModal.inviteCode}
          >
            {inviteCodesForReach?.map((inviteCode) => {
              return (
                <option key={inviteCode.id} value={inviteCode.id}>
                  {inviteCode.code}
                </option>
              );
            })}
          </HvSelect>
        </Flex>
        <Center pt={3}>
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Secondary}
            w={210}
            text={HVLocalizeStrings.SAVE}
            onClick={handleSave}
            disabled={
              isSaving ||
              isMissingFields ||
              !isValidEmail ||
              !isValidFullName ||
              certOnlyRequireCert
            }
            dataTestId={HVTestId.UserAddModal.save}
          />
        </Center>
      </Flex>
    </LargeModal>
  );
};

export default UserAddModal;
