import { Box, useToken } from "@chakra-ui/react";
import {
  DateTimePicker,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import "./ActivationDateTimePicker.css";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import styled from "styled-components";
import { Popup } from "@progress/kendo-react-popup";
import { Black, White } from "../../../../Styles/HeadversityStyle";

export interface ActivationDateTimePickerProps {
  currentDate?: Date;
  onChange: (date: Date) => void;
  isLightTheme?: boolean;
}
export const ActivationDateTimePicker = (
  props: ActivationDateTimePickerProps
) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { selectedUserLanguage, selectedUserLocale } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (props.currentDate) {
      setSelectedDate(props.currentDate);
    }
  }, []);
  const handleChange = (event: DateTimePickerChangeEvent) => {
    if(event.value) {
      props.onChange(event.value);
      setSelectedDate(event.value);
    }
  };
  const buttonPrimaryColor = useToken("colors", [
    "BUTTON_PRIMARY_BACKGROUND_COLOR",
  ]);
  const inputBackgroundColor = useToken("colors", ["INPUT_BACKGROUND_COLOR"]);
  const inputPrimaryTextColor = useToken("colors", [
    "INPUT_PRIMARY_TEXT_COLOR",
  ]);

  let customInputPrimaryTextColor = props.isLightTheme ?  Black : inputPrimaryTextColor;
  let customInputBackgroundColor = props.isLightTheme ? White : inputBackgroundColor

  const StyledDateTimePicker = styled(DateTimePicker)`
    & .k-input-inner {
      background-color: ${customInputBackgroundColor};
      color: ${customInputPrimaryTextColor};
    }
  `;

  const StyledCustomPopup = styled(Popup)`
    & .k-selected {
      background-color: ${buttonPrimaryColor};
      border-color: ${buttonPrimaryColor};
    }

    // hide today and now buttons
    & .k-calendar-nav.k-hstack {
      display: none;
    }
    & .k-time-now {
      display: none;
    }

    & .k-time-list .k-item:hover {
      color: ${buttonPrimaryColor};
    }
    & .k-calendar .k-calendar-view .k-today {
      color: ${buttonPrimaryColor};
    }
    & .k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
    .k-calendar .k-calendar-td.k-selected .k-link {
      background-color: ${buttonPrimaryColor};
    }
    & .k-time-accept {
      background-color: ${buttonPrimaryColor};
      border-color: ${buttonPrimaryColor};
    }
  `;

  return (
    <Box>
      <LocalizationProvider language={selectedUserLocale}>
        <IntlProvider locale={selectedUserLanguage}>
          <StyledDateTimePicker
            format={{
              skeleton: HVLocalizeStrings.DATE_FORMAT_KENDO_DATETIME,
            }}
            size="large"
            // show={true}
            popup={StyledCustomPopup}
            value={selectedDate}
            onChange={handleChange}
          />
        </IntlProvider>
      </LocalizationProvider>
    </Box>
  );
};
