import { Center, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { LikeAndDislikeButton } from "../../Common/LikeAndDislikeButton";
import { removeSearchParam } from "../../../Utils/Helpers";
import { useNavigate } from "react-router";

interface PresentationRatingViewProps {
  teamLessonUserInstanceId?: number;
  closeFunction: () => void;
}

export const PresentationRatingView = (props: PresentationRatingViewProps) => {
  const { closeFunction, teamLessonUserInstanceId } = props;

  const {
    submitTeamLessonUserInstanceRatingToServer,
    setTeamLessonFeedbackObtained,
  } = useContext<IGlobalProvider>(GlobalContext);

  const navigate = useNavigate();

  const [positivityRating, setPositivityRating] = useState<string>("");
  const [relevancyRating, setRelevancyRating] = useState<string>("");
  const [closeDisabled, setCloseDisabled] = useState<boolean>(true);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    setTeamLessonFeedbackObtained(closeDisabled);
  }, [closeDisabled]);

  useEffect(() => {
    const searchParams = removeSearchParam("teamLessonUserInstanceId");
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, []);

  return (
    <Flex flexDir={"column"} align={"center"} gap={"10"} minH={"500px"}>
      <BlockSectionTitle title={HVLocalizeStrings.TEAM_NAILED_IT} />
      <Text
        color={MODAL_PRIMARY_TEXT_COLOR}
        textAlign={"center"}
        w={isMobile ? "100%" : "60%"}
      >
        {HVLocalizeStrings.TEAM_FEEDBACK_DESCRIPTION}
      </Text>
      <Flex
        width={isMobile ? "100%" : "80%"}
        mx={"auto"}
        maxW={"auto"}
        px={{ base: 4, sm: 2 }}
        flexDir={"column"}
        gap={"5"}
      >
        <LikeAndDislikeButton
          result={relevancyRating}
          setResult={(relevancyRatingResult: string) => {
            track(TEAM_EVENTS.PresentationThumbsRelevant, {
              HV_Response: relevancyRatingResult === "like" ? "up" : "down",
            });

            setRelevancyRating(relevancyRatingResult);
            submitTeamLessonUserInstanceRatingToServer(
              teamLessonUserInstanceId as number,
              positivityRating === "like" ? 100 : 0,
              relevancyRatingResult === "like" ? 100 : 0
            ).then(() => {
              if (positivityRating) {
                setCloseDisabled(false);
              }
            });
          }}
          description={HVLocalizeStrings.TEAM_LESSON_SUCCESS_RELEVANT}
        />
        <LikeAndDislikeButton
          result={positivityRating}
          setResult={(positivityRatingResult: string) => {
            track(TEAM_EVENTS.PresentationThumbsMotivated, {
              HV_Response: positivityRatingResult === "like" ? "up" : "down",
            });

            setPositivityRating(positivityRatingResult);
            submitTeamLessonUserInstanceRatingToServer(
              teamLessonUserInstanceId as number,
              positivityRatingResult === "like" ? 100 : 0,
              relevancyRating === "like" ? 100 : 0
            ).then(() => {
              if (relevancyRating) {
                setCloseDisabled(false);
              }
            });
          }}
          description={HVLocalizeStrings.TEAM_LESSON_SUCCESS_POSITIVELY}
        />
        <Center mt={2}>
          <AnimatedButton
            text={HVLocalizeStrings.CLOSE}
            disabled={closeDisabled}
            onClick={() => {
              closeFunction();
            }}
          />
        </Center>
      </Flex>
    </Flex>
  );
};
