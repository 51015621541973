import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getKey } from "../Utils/Helpers";
import Pusher, { Channel } from "pusher-js";
import dayjs from "dayjs";
import { ITeamProvider, useTeamGlobal } from "../Hooks/Team/useTeamGlobal";
import { IReachProvider, useReachGlobal } from "../Hooks/Reach/useReachGlobal";
import { IFavoriteProvider, useFavorites } from "../Hooks/Solo/useFavorites";
import { IReminderProvider, useReminders } from "../Hooks/Solo/useReminders";
import {
  IPeopleProvider,
  usePeopleGlobal,
} from "../Hooks/People/usePeopleGlobal";
import {
  IPushNotificationGlobal,
  usePushNotificationGlobal,
} from "../Hooks/Shared/usePushNotificationGlobal";
import {
  ILiveUpdateGlobal,
  useLiveUpdateGlobal,
} from "../Hooks/Shared/useLiveUpdateGlobal";
import { isWebPlatform } from "../Utils/mobileUtils";
import { getPathTeamLessonInProgress } from "../Utils/TeamUtil";
import {
  IMicroLessonProvider,
  useMicroLesson,
} from "../Hooks/Solo/useMicroLesson";
import {
  IHeadscanQuestionProvider,
  useHeadscanQuestion,
} from "../Hooks/Solo/useHeadscanQuestion";
import { ISkillProvider, useSkills } from "../Hooks/Solo/useSkills";
import {
  ICustomFieldProvider,
  useCustomField,
} from "../Hooks/Shared/useCustomField";
import { IToolProvider, useTools } from "../Hooks/Solo/useTools";
import { IContentProvider, useContent } from "../Hooks/Solo/useContent";
import {
  IAudioLibraryProvider,
  useAudioLibrary,
} from "../Hooks/Solo/useAudioLibrary";
import {
  IFeatureImplementationsProvider,
  useFeatureImplementations,
} from "../Hooks/Solo/useFeatureImplementations";
import { IBlogPostProvider, useBlogPost } from "../Hooks/Solo/useBlogPost";
import {
  INanoPracticeProvider,
  useNanoPractice,
} from "../Hooks/Solo/useNanoPractices";
import { IProfileProvider, useProfile } from "../Hooks/Solo/useProfile";
import {
  INotificationProvider,
  useNotification,
} from "../Hooks/Solo/useNotification";
import { IHelpInfoProvider, useHelpInfo } from "../Hooks/Solo/useHelpInfo";
import {
  IAppVersionProvider,
  useAppVersion,
} from "../Hooks/Shared/useAppVersion";
import { IAppProvider, useApp } from "../Hooks/Shared/useApp";
import {
  INetworkingProvider,
  useNetworking,
} from "../Hooks/Shared/useNetworking";
import { IModalsProvider, useModals } from "../Hooks/Shared/useModals";
import { IPusherProvider, usePusher } from "../Hooks/Shared/usePusher";
import { IPageProvider, usePage } from "../Hooks/Shared/usePage";
import { ITourProvider, useTour } from "../Hooks/Shared/useTour";
import { EVENTS, identify, track } from "../Utils/Analytics";
import { currentAppVersion } from "../Api/Utils";
import { IUserSkillProvider, useUserSkill } from "../Hooks/Solo/useUserSkill";
import {
  CertDto,
  CompanyDto,
  CompletionState,
  InviteCodeDto,
  MicroLessonDto,
  NanoPracticeDto,
  ProductType,
  UserDto,
  UserRole,
  UserStatsResultsDto,
} from "../@headversity/contract";
import { ICertProvider, useCert } from "../Hooks/Cert/useCert";
import { ISurveyProvider, useSurvey } from "../Hooks/Cert/useSurvey";
import {
  IHelpResourceProvider,
  useHelpResource,
} from "../Hooks/Solo/useHelpResource";
import {
  IAdminProductTypeProvider,
  useAdminProductType,
} from "../Hooks/Reach/useAdminProductType";
import { isTimeForQualification } from "../Utils/CertUtil";
import {
  HUBSPOT_REDIRECT_URL_KEY,
  HUBSPOT_REDIRECT_URL_SLUG,
  TEAMS_REDIRECT_URL_SLUG,
} from "../Utils/LoginUtil";
import { FeatureFlags } from "../Utils/FeatureFlags";
import { getCompanyCertsForReach } from "../Api/Reach/ReachApi";
import {
  getAllActivityCompletionDates,
  getSortedSkillsForUser,
  getSuggestedLessonsForUser,
  getSuggestedPracticesForUser,
  needsSkillScore,
} from "../Utils/SkillsUtil";
import { cloneAndTranslate } from "../Utils/ObjectUtil";

export enum AppType {
  SOLO = "SOLO",
  TEAM = "TEAM",
  REACH = "REACH",
  CERT = "CERT",
}

export interface IGlobalProvider
  extends IProfileProvider,
    ISkillProvider,
    IMicroLessonProvider,
    IHeadscanQuestionProvider,
    IToolProvider,
    IContentProvider,
    IBlogPostProvider,
    IAudioLibraryProvider,
    ITeamProvider,
    IReachProvider,
    IFavoriteProvider,
    IReminderProvider,
    IPeopleProvider,
    IPushNotificationGlobal,
    ILiveUpdateGlobal,
    IFeatureImplementationsProvider,
    INanoPracticeProvider,
    ICustomFieldProvider,
    INotificationProvider,
    IHelpInfoProvider,
    IHelpResourceProvider,
    IAppProvider,
    IAppVersionProvider,
    IModalsProvider,
    INetworkingProvider,
    IPageProvider,
    IPusherProvider,
    IAdminProductTypeProvider,
    ITourProvider,
    IUserSkillProvider,
    ICertProvider,
    ISurveyProvider {
  loadAllData: (user: UserDto, onBaseDataLoaded: () => void) => void;
  baseDataLoaded: boolean;
  setBaseDataLoaded: (loaded: boolean) => void;
  clearGlobalActivities: () => void;
  navigateToLogin: () => void;
  openNextActivity: (
    trainingType: TrainingType,
    selectedPractice?: NanoPracticeDto,
    selectedLesson?: MicroLessonDto
  ) => void;
  openedActivityFromStartTraining: boolean;
  setOpenedActivityFromStartTraining: (launched: boolean) => void;
  getNextSuggestedPractices: (goalOnly: boolean) => NanoPracticeDto[];
  getNextSuggestedLessons: (goalOnly: boolean) => MicroLessonDto[];
  activityCompletionDates: dayjs.Dayjs[];
  showPointsModal: boolean;
  setShowPointsModal: (show: boolean) => void;
  showStreakModal: boolean;
  setShowStreakModal: (show: boolean) => void;
}

export enum TrainingType {
  Practice,
  Lesson,
}

export const GlobalContext = createContext<IGlobalProvider>({} as any);

export const GlobalProvider = (props: any) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const oneSignalGlobalVariables = usePushNotificationGlobal();

  const liveUpdateGlobalVariables = useLiveUpdateGlobal();

  const featureImplementationsVariables = useFeatureImplementations();
  const { getFeatureImplementationsFromServer, hasFeature } =
    featureImplementationsVariables;

  const adminProductTypeVariables = useAdminProductType();
  const { adminCertEnabled, setAdminCertEnabled, setAdminProductTypes } =
    adminProductTypeVariables;

  const skillsVariables = useSkills();
  const {
    skills,
    setSkills,
    getUserGoalFromServer,
    setGoalsAndWinThemes,
    selectedGoal,
    setSelectedSkill,
  } = skillsVariables;

  const userSkillVariables = useUserSkill(skills);
  const { setUserStatsToServer, userSkillStats } = userSkillVariables;

  const blogPostVariables = useBlogPost(setUserStatsToServer);
  const { getBlogsFromServer, setGlobalBlogPost, allResilienceFeedPosts } =
    blogPostVariables;

  const toolVariables = useTools();
  const { setTools } = toolVariables;

  const contentVariables = useContent();
  const { getContentFromServer } = contentVariables;

  const audioLibraryVariables = useAudioLibrary();
  const { getMindfulnessAudioSeriesFromServer } = audioLibraryVariables;

  const microLessonVariables = useMicroLesson();
  const { lastFetchedDate, setLastFetchedDate, ...nanoPracticeVariables } =
    useNanoPractice(setUserStatsToServer);

  const {
    getNanoPracticeInstancesFromServer,
    nanoPractices,
    nanoPracticeInstances,
    setNanoPractices,
    setNanoPracticesWithTrashed,
    setGlobalPractice,
    setNanoPracticeModalOpen,
  } = nanoPracticeVariables;

  const {
    isLessonPreviewOpen,
    lesson,
    lessons,
    getLessonsFromServer,
    setGlobalLesson,
    setIsLessonPreviewOpen,
    getSuggestedLessons,
  } = microLessonVariables;

  const headscanQuestionVariables = useHeadscanQuestion();

  const {
    getHeadScanQuestionsAndAnswersFromServer,
    setGlobalHeadScanQuestionModalOpen,
  } = headscanQuestionVariables;

  const teamGlobalVariables = useTeamGlobal();
  const {
    getTeamsFromServer,
    teams,
    setPusherTeamChannels,
    getPollInstancesFromServer,
    setTeamInitializing,
    currentTeam,
    paths,
    pusherTeamChannelNames,
    setPusherTeamChannelNames,
    presenterModalOpen,
    presenterPathTeamLesson,
    participantPathTeamLesson,
    participantModalOpen,
    currentTeamLessonUserInstanceId,
  } = teamGlobalVariables;

  const favoriteVariables = useFavorites();
  const { getFavoritesFromServer } = favoriteVariables;

  const reminderVariables = useReminders();
  const { getRemindersFromServer } = reminderVariables;

  const { certsWithScorms, setCertsWithScorms, ...reachGlobalVariables } =
    useReachGlobal();
  const { getPromoMaterialsFromServer } = reachGlobalVariables;

  const peopleGlobalVariables = usePeopleGlobal();

  const certGlobalVariables = useCert();
  const { getCertsFromServer } = certGlobalVariables;

  const location = useLocation();

  const { getPrivateCustomQuestionsFromServer, ...customFieldVariables } =
    useCustomField();

  const notificationVariables = useNotification();
  const { getNotificationsFromServer } = notificationVariables;

  const helpInfoVariables = useHelpInfo();
  const { getHelpInfoFromServer } = helpInfoVariables;

  const helpResourceVariables = useHelpResource();
  const { getHelpResourcesForUserFromServer } = helpResourceVariables;

  const appVersionVariables = useAppVersion();
  const { getAppVersionFromServer } = appVersionVariables;

  const surveyVariables = useSurvey();

  const modalsVariables = useModals();
  const {
    pusherUserChannel,
    setPusherUserChannel,
    pusherClient,
    setPusherClient,
    pusherCompanyChannel,
    setPusherCompanyChannel,
    pusherConnectionTime,
    setPusherConnectionTime,
  } = usePusher();
  const {
    currentApp,
    setCurrentApp,
    soloEnabled,
    setSoloEnabled,
    teamEnabled,
    setTeamEnabled,
    reachEnabled,
    setReachEnabled,
    certEnabled,
    setCertEnabled,
    setIsReachAdminEnabled,
    resourceLock,
    setResourceLock,
    ...appVariables
  } = useApp();
  const pageVariables = usePage({
    isLessonPreviewOpen,
    lesson,
    presenterModalOpen,
    presenterPathTeamLesson,
    participantModalOpen,
    participantPathTeamLesson,
  });
  const networkingVariables = useNetworking();
  const tourVariables = useTour();

  const {
    getUserCompanyFromServer,
    getInviteCodeCompanyFromServer,
    getRedirectUrlFromServer,
    loginWithMsTeamsTokenToServer,
    ...userProfileVariables
  } = useProfile();

  const { self } = userProfileVariables;

  const [baseDataLoaded, setBaseDataLoaded] = useState(false);
  const [isTeamPollInitDone, setIsTeamPollInitDone] = useState(false);

  const [activityCompletionDates, setActivityCompletionDates] = useState<
    dayjs.Dayjs[]
  >([]);

  const [openedActivityFromStartTraining, setOpenedActivityFromStartTraining] =
    useState(false);

  const [showPointsModal, setShowPointsModal] = useState(false);
  const [showStreakModal, setShowStreakModal] = useState(false);

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.toLowerCase();

      const forceTeam =
        location.search &&
        location.search.indexOf("team") > -1 &&
        location.search.indexOf("teamLessonUserInstanceId") === -1;
      const forceReach =
        location.search && location.search.indexOf("reach") > -1;
      const forceCert = location.search && location.search.indexOf("cert") > -1;
      if (resourceLock) {
        setCurrentApp(AppType.CERT);
      } else {
        setCurrentApp(
          teamEnabled &&
            (forceTeam ||
              path.indexOf("team") > -1 ||
              path.indexOf("join") > -1)
            ? AppType.TEAM
            : reachEnabled && (forceReach || path.indexOf("reach") > -1)
            ? AppType.REACH
            : certEnabled && (forceCert || path.indexOf("cert") > -1)
            ? AppType.CERT
            : soloEnabled
            ? AppType.SOLO
            : null
        );
      }
    }
  }, [location.pathname, teamEnabled, reachEnabled, certEnabled, soloEnabled]);

  const loadAllData = useCallback(
    async (user: UserDto, onBaseDataLoaded: () => void) => {
      const appVersion = await getAppVersionFromServer();
      if (currentAppVersion.value === "") {
        currentAppVersion.value = appVersion as string;
      }

      // to start, we need to get features
      const featureImplementations =
        await getFeatureImplementationsFromServer();

      const content = await getContentFromServer(
        !hasFeature(
          FeatureFlags.DISABLE_AI_NANO_PRACTICE,
          featureImplementations
        )
      );
      setTools(content.tools);
      setNanoPractices(content.nanoPractices.filter((x) => !x.deletedAt));
      setNanoPracticesWithTrashed(content.nanoPractices);
      setGoalsAndWinThemes(content.goals, content.winThemes);

      // then we can get skills
      const skills = content.skills;
      setSkills(skills);

      // then we wrap other critical calls in a promise, so we execute simultaneously and wait to load until they're complete
      const calls = [
        getCertsFromServer(),
        getUserCompanyFromServer(),
        getInviteCodeCompanyFromServer(),
        setUserStatsToServer(true),
        getLessonsFromServer(skills),
        getHeadScanQuestionsAndAnswersFromServer(),
        getNanoPracticeInstancesFromServer(),
        getUserGoalFromServer(content.goals),
        getFavoritesFromServer(),
      ];

      const results = await Promise.all(calls);

      const certs = results[0] as CertDto[];
      const company = results[1] as CompanyDto;
      const inviteCode = results[2] as InviteCodeDto;
      const userStats = results[3] as UserStatsResultsDto;

      // identify user for analytics
      identify(
        user.id,
        user.companyId,
        user.inviteCodeId,
        user.language ?? "en",
        company.isTest
      );
      track(EVENTS.Identified);

      // set enabled services
      const soloEnabled = !company.disableSolo;

      const teamEnabled =
        inviteCode.enableTeam ||
        (inviteCode.enableTeam === null && company.enableTeam);

      const reachEnabled =
        user.role < UserRole.Learner &&
        (inviteCode.enableReach ||
          (!inviteCode.enableReach && company.enableReach));

      const reachAdminFeature = hasFeature(
        FeatureFlags.REACH_ADMIN,
        featureImplementations
      );
      const isReachAdminEnabled =
        user.role <= UserRole.HVAdmin ||
        (user.role <= UserRole.Admin && reachAdminFeature);

      setSoloEnabled(soloEnabled);
      setTeamEnabled(teamEnabled);
      setReachEnabled(reachEnabled);
      setIsReachAdminEnabled(isReachAdminEnabled);

      // user has CERT if they have any assigned certs
      const certEnabled = certs.length > 0;
      setCertEnabled(certEnabled);

      // start on CERT if the user has an unstarted or incomplete cert/cert qualification
      // OR if the company does not have SOLO and the user has an assigned cert
      const hasCertThatNeedsAttention = certs.some(
        (c) =>
          c.certInstances.length === 0 ||
          c.certInstances.some((ci) => ci.state !== CompletionState.done) ||
          isTimeForQualification(c.certInstances[0].certQualification)
      );

      // check that every certQualification that exists on the certs array in each certInstances also exists on userStats.certQualifications, and if false, need to call setUserStatsToServer() to update the cached certQualifications
      // this can happen if a certQualification is added to a cert recently (because 90 days passed) but the user didn't do anything to trigger their stats to update
      let certQualifications = [] as any;
      certs.forEach((cert) => {
        cert.certInstances.forEach((certInstance) => {
          certQualifications.push(certInstance.certQualification);
        });
      });
      let userStatsCertQualificationsIds = userStats.certQualifications.map(
        (certQualification) => certQualification?.id
      );
      let certQualificationsIds = certQualifications.map(
        (certQualification: { id: any }) => certQualification?.id
      );
      let missingCertQualifications = certQualificationsIds.filter(
        (certQualificationId: number) =>
          !userStatsCertQualificationsIds.includes(certQualificationId)
      );
      if (missingCertQualifications.length > 0) {
        setUserStatsToServer();
      }

      const resourceLock = sessionStorage.getItem("resourceLock")
        ? JSON.parse(sessionStorage.getItem("resourceLock")!)
        : null;
      setResourceLock(resourceLock);
      if (resourceLock) {
        navigate("/cert", { replace: true });
      }

      // access to nothing?
      if (!soloEnabled && !teamEnabled && !certEnabled && !reachEnabled) {
        navigate("/invalid");
      }

      // determine default when on root page
      else if (
        window.location.pathname === "/" &&
        window.location.search === ""
      ) {
        // incomplete cert or cert-only non-admin goes to CERT
        if (
          hasCertThatNeedsAttention ||
          (company.disableSolo && certEnabled && !reachEnabled)
        ) {
          navigate("/cert");
        }

        // admins go to REACH
        else if (reachEnabled) {
          navigate("/reach");
        }

        // team-only goes to TEAM (not a real use case, but just in case)
        else if (!soloEnabled && teamEnabled) {
          navigate("/team");
        }
      }

      track(EVENTS.BaseDataLoaded);
      if (onBaseDataLoaded) {
        onBaseDataLoaded();
      }

      // lastly, trigger the rest of the calls that we don't need upfront
      getPrivateCustomQuestionsFromServer();
      getBlogsFromServer();
      getHelpInfoFromServer();
      getHelpResourcesForUserFromServer();
      getMindfulnessAudioSeriesFromServer();
      getNotificationsFromServer();
      getRemindersFromServer();
    },
    []
  );

  useEffect(() => {
    setActivityCompletionDates(
      getAllActivityCompletionDates(
        nanoPracticeInstances,
        lessons,
        allResilienceFeedPosts
      )
    );
  }, [nanoPracticeInstances, lessons, allResilienceFeedPosts]);

  useEffect(() => {
    // check for day change on an interval, and refresh data when it changes
    if (nanoPractices.length === 0) {
      return;
    }
    const interval = setInterval(() => {
      setLastFetchedDate((prev: any) => {
        const now = dayjs().format("YYYY-MM-DD");

        if (prev && now !== prev) {
          // reset nanoPractices when the day flips to trigger a re-render and force fresh completion check
          setNanoPractices([...nanoPractices]);

          // fetch instances again, to reset activity completion dates
          getNanoPracticeInstancesFromServer();
        }

        return now;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [nanoPractices]);

  const navigateToLogin = () => {
    if (isWebPlatform() || searchParams.get("isMobile") === "true") {
      let loginUrl = "/login";

      const urlParams = new URLSearchParams();

      if (searchParams.get("isMobile") === "true") {
        urlParams.append("isMobile", "true");
      }

      if (searchParams.get("certKey")) {
        urlParams.append("certKey", searchParams.get("certKey") as string);
      }

      if (searchParams.get("userId")) {
        urlParams.append("userId", searchParams.get("userId") as string);
      }

      const inviteCodeFromQueryString = searchParams.get("invite");
      if (inviteCodeFromQueryString) {
        urlParams.append("invite", inviteCodeFromQueryString);
      }

      if (urlParams.toString()) {
        loginUrl += "?" + urlParams.toString();
      }

      navigate(loginUrl, { replace: true });
    } else {
      navigate("/mobileLogin", { replace: true });
    }
  };

  const handleHubspotLogin = (page: string, urlParams: URLSearchParams) => {
    if (page === HUBSPOT_REDIRECT_URL_SLUG) {
      const redirectUrl = urlParams.get("redirect_url");
      if (redirectUrl) {
        localStorage.setItem(HUBSPOT_REDIRECT_URL_KEY, redirectUrl);
      }
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    const allowedUnsignedPages = [
      "authenticated",
      "waiting",
      "reset-password",
      "validate-registration",
      "validate-login",
      "accessToken",
      "cert-instance",
      "waitingTologin",
      "unsubscribe",
      TEAMS_REDIRECT_URL_SLUG,
      HUBSPOT_REDIRECT_URL_SLUG,
    ];
    const page = location.pathname.split("/")[1].split("?")[0];

    if (page === TEAMS_REDIRECT_URL_SLUG) {
      window.location.href = `/waitingTologin?isMsTeam=true`;
      return;
    }
    if (!getKey()) {
      if (allowedUnsignedPages.includes(page)) {
        const urlParams = new URLSearchParams(location.search);
        handleHubspotLogin(page, urlParams);
        return;
      }

      if (location.pathname.split("/")[1] === "forgotPassword") {
        sessionStorage.setItem("goToForgotPassword", `true`);
      } else if (location.pathname.split("/")[1] === "resend-activation") {
        sessionStorage.setItem("goToResendActivation", `true`);
      } else if (location.pathname.split("/")[1] !== "login") {
        sessionStorage.setItem(
          "redirectPath",
          `${location.pathname}${location.search}`
        );
      }

      navigateToLogin();
    } else if (page === HUBSPOT_REDIRECT_URL_SLUG) {
      const urlParams = new URLSearchParams(location.search);
      const hubspotRedirectUrl = urlParams.get("redirect_url")!;

      getRedirectUrlFromServer(
        hubspotRedirectUrl,
        process.env.REACT_APP_HUBSPOT_DOMAIN!
      ).then((path) => {
        localStorage.removeItem(HUBSPOT_REDIRECT_URL_KEY);
        window.location.href = path;
      });
    }
  }, []);

  useEffect(() => {
    /*
    Pusher.log = function (message) {
      if (window.console && window.console.debug) {
        window.console.debug(message);
      }
    };
    */

    if (process.env.REACT_APP_PUSHER_KEY === undefined) return;

    const localPusherClient = new Pusher(
      process.env.REACT_APP_PUSHER_KEY as any,
      {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? "",
      }
    );

    // setPusherConnectionTime will force data to be reloaded for TEAM
    // in case anything was missed.
    localPusherClient.connection.bind("state_change", (state: any) => {
      if (state?.current === "connected") {
        setPusherConnectionTime(dayjs.utc().unix());
      } else if (
        state?.current === "failed" ||
        state?.current === "disconnected"
      ) {
        // try to connect right away.
        localPusherClient.connect();

        // never connected, lets just go grab the data right away on failure.
        if (pusherConnectionTime === undefined) {
          setPusherConnectionTime(dayjs.utc().unix());
        }
      }
    });

    // backup for pulling data, if for some reason
    // pusher can't be connected.  Will pull every 30 seconds.
    // this will pull only if there is a teamLesson in progress
    // for the current team you are on.
    let disconnectedCount = 0;
    const intervalId = setInterval(() => {
      if (localPusherClient.connection.state !== "connected") {
        disconnectedCount++;
        if (disconnectedCount > 2) {
          if (currentTeam && getPathTeamLessonInProgress(paths, currentTeam)) {
            setPusherConnectionTime(dayjs.utc().unix());
          }
          disconnectedCount = 0;
        }
      }
    }, 10000);

    setPusherClient(localPusherClient);
    return () => {
      localPusherClient.connection.unbind_all();
      localPusherClient?.disconnect();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (self && pusherClient) {
      pusherUserChannel?.unsubscribe();
      const channelName = `user-${self.id}`;
      const newChannel = pusherClient?.subscribe(channelName);
      setPusherUserChannel(newChannel);
      return () => {
        pusherUserChannel?.unsubscribe();
        setPusherUserChannel(null);
      };
    }
  }, [self, pusherClient]);

  useEffect(() => {
    if (!pusherClient) {
      return;
    }
    let newPusherTeamChannels: Channel[] = [];
    if (pusherTeamChannelNames) {
      for (let i = 0; i < pusherTeamChannelNames?.length; i++) {
        const newChannel = pusherClient?.subscribe(pusherTeamChannelNames[i]);
        newPusherTeamChannels.push(newChannel);
      }
      if (newPusherTeamChannels.length > 0) {
        setPusherTeamChannels(newPusherTeamChannels);
      }
      return () => {
        if (newPusherTeamChannels) {
          for (let i = 0; i < newPusherTeamChannels.length; i++) {
            if (newPusherTeamChannels && newPusherTeamChannels[i]) {
              newPusherTeamChannels[i].unsubscribe();
            }
          }
        }
        setPusherTeamChannels(null);
      };
    }
  }, [pusherTeamChannelNames, pusherClient]);

  // This will ensure it will only resubscribe to the
  // pusher team channels if there are changes to a user
  // teams.  Such as user joining a new team, user leaving
  // a team.  This way calling getTeamFromServer won't
  // unsubscribe and resubscribe all the channels.
  useEffect(() => {
    if (teams) {
      let teamChannelNames = pusherTeamChannelNames || [];
      let haveChanges = false;
      for (let i = 0; i < teams.length; i++) {
        const channelName = `team-${teams[i].id}`;
        if (teamChannelNames.indexOf(channelName) === -1) {
          teamChannelNames.push(channelName);
          haveChanges = true;
        }
      }

      for (let i = 0; i < teamChannelNames.length; i++) {
        let removeItem = true;
        for (let j = 0; j < teams.length; j++) {
          if (teamChannelNames[i] === `team-${teams[j].id}`) {
            removeItem = false;
            break;
          }
        }
        if (removeItem) {
          teamChannelNames.splice(i, 1);
          haveChanges = true;
          i--;
        }
      }

      if (haveChanges) {
        setPusherTeamChannelNames([...teamChannelNames]);
      }
    }
  }, [teams]);

  // REACH Start up
  useEffect(() => {
    if (reachEnabled) {
      getPromoMaterialsFromServer();
    }
  }, [reachEnabled]);

  // TEAM Start up
  useEffect(() => {
    if (teamEnabled && pusherConnectionTime) {
      setTeamInitializing(true);

      const calls: any[] = [getTeamsFromServer()];

      // only get the poll instances at startup or when a user is in a team lesson (in case they need updated poll data)
      if (!isTeamPollInitDone || currentTeamLessonUserInstanceId) {
        setIsTeamPollInitDone(true);
        calls.push(getPollInstancesFromServer());
      }
      Promise.all(calls).then(() => {
        setTeamInitializing(false);
      });
    }
  }, [teamEnabled, pusherConnectionTime]);

  useEffect(() => {
    if (self && currentApp) {
      track(EVENTS.Loaded, {
        HV_App: currentApp,
      });
    }
  }, [self, currentApp]);

  const { isReachAdminEnabled: isAppReachAdminEnabled } = appVariables;

  useEffect(() => {
    if (self) {
      const checkCompanyCerts = async (companyId: number) => {
        const response = await getCompanyCertsForReach(companyId, getKey());
        const companyCerts = response.data;
        if (companyCerts.length > 0) {
          setAdminCertEnabled(true);
          setCertsWithScorms(companyCerts.filter((cert) => cert.hasScorm));
        }
      };

      if (self.companyId && isAppReachAdminEnabled) {
        checkCompanyCerts(self.companyId);
      }
    }
  }, [self, isAppReachAdminEnabled]);

  const getAdminProductTypes = () => {
    const adminProductTypes = [];
    if (soloEnabled) {
      adminProductTypes.push(ProductType.SOLO);
    }
    if (teamEnabled) {
      adminProductTypes.push(ProductType.TEAM);
    }
    if (adminCertEnabled) {
      adminProductTypes.push(ProductType.CERT);
    }
    return adminProductTypes;
  };

  useEffect(() => {
    setAdminProductTypes(getAdminProductTypes());
  }, [soloEnabled, teamEnabled, adminCertEnabled]);

  const clearGlobalActivities = () => {
    setGlobalPractice(undefined);
    setGlobalLesson(null);
    setGlobalBlogPost();
  };

  const getNextSuggestedPractices = (goalOnly: boolean) => {
    const skillsToUse =
      goalOnly && selectedGoal
        ? skills.filter((x) => selectedGoal?.skillIds.includes(x.id))
        : skills;

    return getSuggestedPracticesForUser(
      getSortedSkillsForUser(skillsToUse, userSkillStats, selectedGoal),
      nanoPractices,
      nanoPracticeInstances,
      selectedGoal
    );
  };

  const getNextSuggestedLessons = (goalOnly: boolean) => {
    const skillsToUse =
      goalOnly && selectedGoal
        ? skills.filter((x) => selectedGoal?.skillIds.includes(x.id))
        : skills;

    return getSuggestedLessonsForUser(
      getSuggestedLessons,
      skillsToUse,
      userSkillStats,
      selectedGoal,
      false
    );
  };

  const openNextActivity = (
    trainingType: TrainingType,
    selectedPractice?: NanoPracticeDto,
    selectedLesson?: MicroLessonDto
  ) => {
    clearGlobalActivities();

    let skillId = -1;
    if (trainingType === TrainingType.Practice && selectedPractice) {
      skillId = selectedPractice.skillCompetencies[0].skillId;

      // cloneAndTranslate in case we try to load the same item into an open modal
      setGlobalPractice(cloneAndTranslate(selectedPractice));
    } else if (trainingType === TrainingType.Lesson && selectedLesson) {
      skillId = selectedLesson.skillCompetencies[0].skillId;

      // cloneAndTranslate in case we try to load the same item into an open modal
      setGlobalLesson(cloneAndTranslate(selectedLesson));
    } else {
      return;
    }

    setOpenedActivityFromStartTraining(true);
    setNanoPracticeModalOpen(false);
    setIsLessonPreviewOpen(false);

    track(EVENTS.NextActvitityOpened, {
      HV_SkillId: skillId,
      HV_TrainingType: trainingType,
      HV_ActivityId:
        trainingType === TrainingType.Practice
          ? selectedPractice?.id
          : selectedLesson?.id,
      HV_ActivityTitle:
        trainingType === TrainingType.Practice
          ? selectedPractice?.name_loc?.en
          : selectedLesson?.name_loc?.en,
    });

    if (needsSkillScore(userSkillStats, skillId)) {
      setSelectedSkill(skills.find((x) => x.id === skillId));
      setGlobalHeadScanQuestionModalOpen(true);
    } else if (trainingType === TrainingType.Practice) {
      setNanoPracticeModalOpen(true);
    } else {
      setIsLessonPreviewOpen(true);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        loadAllData,
        pusherUserChannel,
        setPusherUserChannel,
        pusherClient,
        setPusherClient,
        pusherCompanyChannel,
        setPusherCompanyChannel,
        pusherConnectionTime,
        setPusherConnectionTime,
        setSoloEnabled,
        setTeamEnabled,
        setReachEnabled,
        setIsReachAdminEnabled,
        lastFetchedDate,
        setLastFetchedDate,
        getUserCompanyFromServer,
        getRedirectUrlFromServer,
        getInviteCodeCompanyFromServer,
        resourceLock,
        setResourceLock,
        certsWithScorms,
        setCertsWithScorms,
        ...modalsVariables,
        ...appVariables,
        ...pageVariables,
        ...networkingVariables,
        ...tourVariables,
        ...headscanQuestionVariables,
        ...microLessonVariables,
        ...teamGlobalVariables,
        ...favoriteVariables,
        ...reminderVariables,
        ...reachGlobalVariables,
        ...peopleGlobalVariables,
        ...oneSignalGlobalVariables,
        ...liveUpdateGlobalVariables,
        ...adminProductTypeVariables,
        ...skillsVariables,
        ...nanoPracticeVariables,
        ...customFieldVariables,
        ...toolVariables,
        ...contentVariables,
        ...blogPostVariables,
        ...audioLibraryVariables,
        ...featureImplementationsVariables,
        ...userProfileVariables,
        ...notificationVariables,
        ...helpInfoVariables,
        ...helpResourceVariables,
        ...surveyVariables,
        ...userSkillVariables,
        getAppVersionFromServer,
        currentApp,
        setCurrentApp,
        soloEnabled,
        teamEnabled,
        reachEnabled,
        getPrivateCustomQuestionsFromServer,
        certEnabled,
        setCertEnabled,
        ...certGlobalVariables,
        baseDataLoaded,
        setBaseDataLoaded,
        loginWithMsTeamsTokenToServer,
        navigateToLogin,
        clearGlobalActivities,
        openNextActivity,
        openedActivityFromStartTraining,
        setOpenedActivityFromStartTraining,
        getNextSuggestedPractices,
        getNextSuggestedLessons,
        activityCompletionDates,
        showPointsModal,
        setShowPointsModal,
        showStreakModal,
        setShowStreakModal,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
