import {
  CertActivityReportDto,
  CertQualifiedUsers,
  CertRunningCountsReportDto,
  CertSurveyGrowthReportDto,
  CertUserFeedbackReportDto,
  PerCertUserFeedbackReportDto,
  ReportDto,
} from "@headversity/contract";
import { useCallback, useState } from "react";
import { reportCert } from "../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Evening_Pink,
  Respect_Cherry,
  Respect_Maroon,
  Respect_Raspberry,
} from "../../../Styles/HeadversityStyle";
import {
  getPointStartFromDate,
  parseDateFromYearMonth,
} from "../../../Utils/ChartsUtil";
import { getKey } from "../../../Utils/Helpers";
import { InsightsColumnChartDataPoint } from "./InsightsColumnChart";

export interface ICertReportProvider {
  fetchReport: () => Promise<void>;
  certSurveyGrowthData: CertSurveyGrowthData[];
  allTimeCertCountsData: CertCountsData;
  activeCertsBreakdownData: InsightsColumnChartDataPoint[];
  certActivitySeriesData: CertActivitySeriesData[];
  certUserFeedbackReport: CertUserFeedbackReportDto[];
  certActivityReport: ReportDto<CertActivityReportDto[]>;
  certSurveyGrowth: ReportDto<CertSurveyGrowthReportDto[]>;
  certQualifiedUsers: ReportDto<CertQualifiedUsers[]>;
  perCertUserFeedback: ReportDto<PerCertUserFeedbackReportDto[]>;
  lowDataSurveyGrowth: boolean;
  lowDataUserFeedback: boolean;
  lowDataRunningCounts: boolean;
  lowDataActiveCertsBreakdown: boolean;
  isLoading: boolean;
  intervalDays: number;
}

export type CertCountsData = {
  assignedCertifications: number;
  activeCertifications: number;
};

export type CertSurveyGrowthData = {
  question: string;
  beforePercentage: number;
  afterPercentage: number;
  changePercentage: number;
};

export interface CertActivitySeriesData {
  name: string;
  data: number[];
  dates: string[];
  zIndex: number;
  marker: {
    enabled: boolean;
  };
  color: string;
  graphStartDate: number;
}

const mapRunningCountsToCertActivitySeriesData = (
  dto: ReportDto<CertRunningCountsReportDto[]>
): CertActivitySeriesData[] => {
  if (dto.noData) {
    return [];
  }
  const report = dto.report!;
  const rawActiveCertData = report.map((crc) =>
    Number(crc.running_active_cert_count)
  );
  const rawAssignedCertData = report.map((crc) =>
    Number(crc.running_assigned_cert_count)
  );

  const minDate = report[0].date;
  const dateUTC = parseDateFromYearMonth(minDate);
  const graphStartDate = getPointStartFromDate(dateUTC);
  const dates = report.map((crc) => crc.date);

  const certActivitySeries: CertActivitySeriesData[] = [
    {
      name: HVLocalizeStrings.REACH_INSIGHTS_ASSIGNED_CERTIFICATIONS,
      data: rawAssignedCertData,
      dates,
      zIndex: 1,
      marker: {
        enabled: false,
      },
      color: Evening_Pink,
      graphStartDate,
    },
    {
      name: HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATIONS,
      data: rawActiveCertData,
      dates,
      zIndex: 2,
      marker: {
        enabled: false,
      },
      color: Respect_Maroon,
      graphStartDate,
    },
  ];
  return certActivitySeries;
};

const mapSurveyGrowthData = (
  dto: ReportDto<CertSurveyGrowthReportDto[]>
): CertSurveyGrowthData[] => {
  if (dto.noData) {
    return [];
  }
  const report = dto.report!;
  const mappedData = report.map((csg) => {
    return {
      question: csg.question,
      beforePercentage: csg.pre_response_metric,
      afterPercentage: csg.post_response_metric,
      changePercentage: csg.response_growth,
    } as CertSurveyGrowthData;
  });
  return mappedData;
};

const mapCertActivityData = (
  dto: ReportDto<CertActivityReportDto[]>
): InsightsColumnChartDataPoint[] => {
  if (dto.noData) {
    return [];
  }
  const report = dto.report!;
  const colors = [Respect_Maroon, Respect_Cherry, Respect_Raspberry];
  const assignColor = (index: number, colors: string[]) => {
    return colors[index % colors.length];
  };
  const activeCertifications =
    report.filter((cert) => cert.status === "Active") ?? [];
  const assignedCertifications =
    report.filter((cert) => cert.status === "Assigned") ?? [];
  const formattedActiveCertsData: InsightsColumnChartDataPoint[] =
    activeCertifications
      .map((cert, index) => {
        const numAssigned =
          assignedCertifications.find((c) => c.cert === cert.cert)
            ?.cert_count ?? 0;
        const percentage = (cert.cert_count / numAssigned) * 100;
        return {
          name: cert.cert as string,
          y: parseFloat(percentage.toFixed(0)),
          color: assignColor(index, colors),
          sort_index: cert.sort_index,
        };
      })
      .sort((a, b) => a.sort_index - b.sort_index)
      .map(({ sort_index, ...rest }) => rest);
  return formattedActiveCertsData;
};

const mapActivityAllTimeData = (
  dto: ReportDto<CertActivityReportDto[]>
): CertCountsData => {
  if (dto.noData) {
    return {
      assignedCertifications: 0,
      activeCertifications: 0,
    };
  }
  const report = dto.report!;

  const allTimeAssignedCerts =
    report.filter((cert) => cert.status === "Assigned") ?? [];
  const allTimeAssignedCount = allTimeAssignedCerts.reduce(
    (acc, cert) => acc + cert.cert_count,
    0
  );

  const allTimeActiveCerts =
    report.filter((cert) => cert.status === "Active") ?? [];
  const allTimeActiveCount = allTimeActiveCerts.reduce(
    (acc, cert) => acc + cert.cert_count,
    0
  );
  return {
    assignedCertifications: allTimeAssignedCount,
    activeCertifications: allTimeActiveCount,
  };
};

export const useCertReport = (): ICertReportProvider => {
  const [allTimeCertCountsData, setAllTimeCertCountsData] =
    useState<CertCountsData>({
      assignedCertifications: 0,
      activeCertifications: 0,
    });

  const [activeCertsBreakdownData, setActiveCertsBreakdownData] = useState<
    InsightsColumnChartDataPoint[]
  >([]);

  const [certSurveyGrowthData, setCertSurveyGrowthData] = useState<
    CertSurveyGrowthData[]
  >([]);

  const [certActivitySeriesData, setCertActivitySeriesData] = useState<
    CertActivitySeriesData[]
  >([]);

  const [certUserFeedbackData, setCertUserFeedbackData] = useState<
    CertUserFeedbackReportDto[]
  >([]);

  const [lowDataSurveyGrowth, setLowDataSurveyGrowth] = useState(false);
  const [lowDataUserFeedback, setLowDataUserFeedback] = useState(false);
  const [lowDataRunningCounts, setLowDataRunningCounts] = useState(false);
  const [lowDataActiveCertsBreakdown, setLowDataActiveCertsBreakdown] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [certActivityReport, setCertActivityReport] = useState<
    ReportDto<CertActivityReportDto[]>
  >({ noData: true, report: [] });
  const [certSurveyGrowth, setCertSurveyGrowth] = useState<
    ReportDto<CertSurveyGrowthReportDto[]>
  >({ noData: true, report: [] });
  const [certQualifiedUsers, setCertQualifiedUsers] = useState<
    ReportDto<CertQualifiedUsers[]>
  >({ noData: true, report: [] });
  const [perCertUserFeedback, setPerCertUserFeedback] = useState<
    ReportDto<PerCertUserFeedbackReportDto[]>
  >({ noData: true, report: [] });
  const [intervalDays, setIntervalDays] = useState<number>(180);

  const fetchReportHandler = useCallback(async () => {
    setIsLoading(true);
    const reportResp = await reportCert(getKey());
    setCertSurveyGrowthData(mapSurveyGrowthData(reportResp.data.surveyGrowth));
    setCertActivitySeriesData(
      mapRunningCountsToCertActivitySeriesData(reportResp.data.runningCounts)
    );
    const activeCertsBreakdownData = mapCertActivityData(
      reportResp.data.activityAllTime
    );
    setActiveCertsBreakdownData(activeCertsBreakdownData);
    setAllTimeCertCountsData(
      mapActivityAllTimeData(reportResp.data.activityAllTime)
    );

    setCertUserFeedbackData(
      reportResp.data.userFeedback.noData
        ? []
        : reportResp.data.userFeedback.report!
    );
    setLowDataSurveyGrowth(reportResp.data.surveyGrowth.noData);
    setLowDataUserFeedback(reportResp.data.userFeedback.noData);
    setLowDataRunningCounts(reportResp.data.runningCounts.noData);
    setLowDataActiveCertsBreakdown(
      reportResp.data.activityAllTime.noData ||
        activeCertsBreakdownData.length === 0
    );

    setPerCertUserFeedback(reportResp.data.perCertFeedback);
    setCertQualifiedUsers(reportResp.data.certQualifiedUsers);
    setCertActivityReport(reportResp.data.activityAllTime);
    setCertSurveyGrowth(reportResp.data.surveyGrowth);
    setIntervalDays(reportResp.data.intervalDays);
    setIsLoading(false);
  }, []);
  return {
    fetchReport: fetchReportHandler,
    certSurveyGrowthData,
    allTimeCertCountsData,
    activeCertsBreakdownData,
    certActivitySeriesData,
    certUserFeedbackReport: certUserFeedbackData,
    certActivityReport,
    certSurveyGrowth,
    certQualifiedUsers,
    perCertUserFeedback,
    lowDataSurveyGrowth,
    lowDataUserFeedback,
    lowDataRunningCounts,
    lowDataActiveCertsBreakdown,
    isLoading,
    intervalDays,
  };
};
