import { EVENTS, track } from "../../../Utils/Analytics";
import { useContext, useEffect, useState } from "react";
import { appendToUrl, getKey } from "../../../Utils/Helpers";
import { getLessonKey } from "../../../Api/Solo/LessonCourseApi";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  LessonCourseSlideInputDto,
  LocalizationText,
  QuestionAnswerDto,
  QuestionAnswerResponseDto,
} from "@headversity/contract";
import { QuestionType } from "../../../Utils/PollsUtil";
import { cleanWhiteSpaces } from "../../../Utils/StringUtils";
import { postMessageToIframe } from "../../../Utils/LessonsUtil";
import { ShellContext } from "../../../State/ShellContext";
import {
  ILessonCourseProvider,
  LessonCourseContext,
} from "../../../State/Solo/LessonCourseContext";
import { translateLocalizationText } from "../../../Utils/TranslateUtil";
import { AxiosResponse } from "axios";
import { getIsPixel678 } from "../../../Utils/mobileUtils";
import { Rise360LessonViewModel } from "./Rise360LessonViewModel";

export const FRAME_ID = "riseFrame";
export const GO_BACK = "goBack";
export const GO_FORWARD = "goForward";
export const GOTO_SLIDE_INDEX = "gotoSlideIndex";

interface Rise360PlayerProps {
  rise360Lesson: Rise360LessonViewModel;
  lessonTitle: string | null | undefined;
  onQuestionAnswer?: (
    questionAnswerDtos: QuestionAnswerDto[],
    slideIndex: number
  ) => void;
  queryStringParams: { name: string; value: string }[];
  onSlideProgress?: (
    lessonCourseSlideInputDto: LessonCourseSlideInputDto
  ) => void;
  currentSlideIndex?: number;
  onLessonComplete: () => void;
  onLessonListChanged?: (lessonList: LessonListItem[]) => void;
  onShouldShowBackButton: (backButtonClicked: boolean) => void;
  display?: string;
  onReachedPollSlide?: (index: number) => void;
}

export interface LessonListItem {
  title: string;
  stepNumber: number;
}

const Rise360Player = ({
  rise360Lesson,
  lessonTitle,
  onQuestionAnswer,
  onSlideProgress,
  currentSlideIndex,
  queryStringParams,
  onLessonComplete,
  onLessonListChanged,
  onShouldShowBackButton,
  display,
  onReachedPollSlide,
}: Rise360PlayerProps) => {
  const {
    selectedUserLanguage,
    getLessonLastViewedSlideFromServer,
    deviceInfo,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);
  const { saveLessonSlideProgressToServer } =
    useContext<ILessonCourseProvider>(LessonCourseContext);

  const [signedSrc, setSignedSrc] = useState<string | null>(null);

  const updateSignedUrl = (url: string) => {
    getLessonKey(getKey()).then((response) => {
      const valuesToAppend = [
        { name: "pb", value: safeAreaInsets.bottom.toString() },
        { name: "lang", value: selectedUserLanguage.toString() },
        { name: "key", value: response.data ?? "" },
      ];
      if (getIsPixel678(deviceInfo)) {
        valuesToAppend.push({ name: "nomatch", value: "true" });
      }
      const u = appendToUrl(url, valuesToAppend);
      setSignedSrc(u);
    });
  };

  const handleMessage = (e: any) => {
    const message = e.data;

    if (typeof message !== "object" || !("rise360" in message)) return;

    switch (message.rise360) {
      case "lessonList":
        if (onLessonListChanged) {
          onLessonListChanged(message.lessonList);
        }
        break;

      case "questionAnswers":
        if (onQuestionAnswer) {
          const questionAnswerDtos: QuestionAnswerDto[] = [];
          message.questions.forEach((question: any) => {
            const dto: QuestionAnswerDto = {
              question: {
                [selectedUserLanguage]: cleanWhiteSpaces(
                  question?.questionText?.trim()
                ),
              },
              questionAlternatives: question?.answers?.map(
                (alternative: any) => {
                  return {
                    [selectedUserLanguage]: cleanWhiteSpaces(
                      alternative.answerText.trim()
                    ),
                  };
                }
              ),
              type: "",
              questionType: QuestionType.Single,
              responses: [],
            };
            const answers = message?.userAnswers.filter(
              (userAnswer: any) =>
                cleanWhiteSpaces(userAnswer?.questionText?.trim()) ===
                cleanWhiteSpaces(question?.questionText?.trim())
            );
            if (answers?.length > 0) {
              dto.responses = answers.map((answer: any) => {
                return {
                  response: {
                    [selectedUserLanguage]: cleanWhiteSpaces(
                      answer.answerText.trim()
                    ),
                  },
                  isCorrect: answer.isCorrect,
                } as QuestionAnswerResponseDto;
              });
              dto.questionType =
                answers.length > 1
                  ? QuestionType.Multiple
                  : QuestionType.Single;
            }
            if (
              dto.questionType === QuestionType.Single &&
              answers.length === 1 &&
              dto.questionAlternatives.length === 1
            ) {
              dto.questionType = QuestionType.FreeText;
              dto.responseText = (dto.responses[0].response as any)[
                selectedUserLanguage
              ];
              dto.responseTextIsCorrect = dto.responses[0].isCorrect;
              dto.responses = [];
            }
            questionAnswerDtos.push(dto);
          });

          onQuestionAnswer(questionAnswerDtos, message.slideIndex);
        }
        break;

      case "slideProgress":
        /*
        * simulate lesson completion
        *
        message.isLessonDone = true;
        */

        if (rise360Lesson.lessonCourseUserInstanceId) {
          const url = message.slideUrl.split("?")[0];
          const section = message.slideUrl.split("#")[1];
          const slideUrl = `${url}#${section}`;
          let lessonCourseSlideInputDto = {
            title: { [selectedUserLanguage]: message.slideTitle },
            url: { [selectedUserLanguage]: slideUrl },
            progressDetail: message.progressDetails,
            index: message.slideIndex,
            progress: message.progress,
            slideType: message.slideType,
            isMidSlide: message.isMidSlide,
          } as LessonCourseSlideInputDto;

          saveLessonSlideProgressToServer(
            rise360Lesson.lessonCourseUserInstanceId,
            lessonCourseSlideInputDto
          ).then(() => {
            if (onSlideProgress) {
              onSlideProgress(lessonCourseSlideInputDto);
            }
          });
        }

        track(EVENTS.LessonProgressed, {
          HV_Lesson: lessonTitle,
          HV_Slide: message.slideIndex,
          HV_Progress: message.progress,
        });

        if (message.isLessonDone) {
          track(EVENTS.LessonCompleted, { HV_Lesson: lessonTitle });
          if (onLessonComplete) onLessonComplete();
        }
        break;

      case "backButton":
        if (onShouldShowBackButton) {
          onShouldShowBackButton(!!message.showBackButton);
        }
        break;

      case "reachedPollSlide":
        if (onReachedPollSlide) {
          onReachedPollSlide(message.pollIndex);
        }
        break;
    }
  };

  const updateUrl = (localizedUrl: string | LocalizationText) => {
    const url = new URL(
      typeof localizedUrl === "string"
        ? localizedUrl
        : translateLocalizationText(localizedUrl, selectedUserLanguage)
    );
    let urlString = appendToUrl(url, queryStringParams);
    updateSignedUrl(urlString);
  };

  useEffect(() => {
    if (rise360Lesson.lessonCourseUserInstanceId) {
      // If we have a lesson course user instance ID, then get the starting URL from the backend
      getLessonLastViewedSlideFromServer(
        rise360Lesson.lessonCourseUserInstanceId
      ).then((lastSlideResponse: AxiosResponse<LocalizationText>) => {
        updateUrl(lastSlideResponse.data);
      });
    } else if (rise360Lesson.startingUrl) {
      updateUrl(rise360Lesson.startingUrl);
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [rise360Lesson, queryStringParams, selectedUserLanguage]);

  useEffect(() => {
    if (currentSlideIndex === undefined || currentSlideIndex === -1) return;

    postMessageToIframe(FRAME_ID, GOTO_SLIDE_INDEX, {
      slideIndex: currentSlideIndex,
    });
  }, [currentSlideIndex]);

  return (
    <>
      {signedSrc && (
        <iframe
          style={{ width: "100%", height: "100%", display: display }}
          allowFullScreen={true}
          id={FRAME_ID}
          title={FRAME_ID}
          src={signedSrc as string}
        />
      )}
    </>
  );
};

export default Rise360Player;
