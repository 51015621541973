import { LargeModal } from "../../Common/LargeModal";
import { PresentationIntroStep } from "./PresentationIntroStep";
import { useContext, useEffect, useState } from "react";
import { PresentationLobbyStep } from "./PresentationLobbyStep";
import { PresentationPresentStep } from "./PresentationPresentStep";
import { PresentationFeedbackStep } from "./PresentationFeedbackStep";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getKey } from "../../../Utils/Helpers";
import { useSearchParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { ShellContext } from "../../../State/ShellContext";
import { GlobalContext } from "../../../State/GlobalContext";
import { setOpenWrapperForPollModals } from "../../../Utils/TeamUtil";
import { PathTeamLessonDto, TeamLessonDto } from "@headversity/contract";

export interface PresentationStepProps {
  teamLesson?: TeamLessonDto;
  setCurrentStep: (currentStep: string) => void;
}

interface PresentationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pathTeamLesson?: PathTeamLessonDto;
}

export const TEAM_LESSON_INTRO_STEP = "intro";
export const TEAM_LESSON_INITIALIZE_STEP = "initialize";
export const TEAM_LESSON_PROGRESS_STEP = "progress";
export const TEAM_LESSON_END_STEP = "end";

export const PresentationModal = (props: PresentationModalProps) => {
  const { open, setOpen, pathTeamLesson } = props;
  const [currentStep, setCurrentStep] = useState(TEAM_LESSON_INTRO_STEP);
  const [opacity, setOpacity] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const pathId = searchParams.get("pathId");

  const { resetTeamSession } = useContext(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);

  useEffect(() => {
    if (
      pathTeamLesson?.teamLessonUserInstanceId &&
      (currentStep === TEAM_LESSON_INITIALIZE_STEP ||
        currentStep === TEAM_LESSON_PROGRESS_STEP)
    ) {
      window.onunload = (e) => {
        e.preventDefault();
        fetch(
          `${process.env.REACT_APP_API_URL}/api/teamLessonUserInstances/${pathTeamLesson?.teamLessonUserInstanceId}/cancel`,
          {
            keepalive: true,
            method: "POST",
            headers: {
              Authorization: `Bearer ${getKey()}`,
            },
            body: JSON.stringify({}),
          }
        );
      };
    } else {
      window.onunload = () => {};
    }
  }, [currentStep]);

  let size = null;
  let view = <></>;
  let hideCloseButton = false;
  switch (currentStep) {
    case TEAM_LESSON_INTRO_STEP:
      view = (
        <PresentationIntroStep
          closeModal={() => {
            setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
          }}
          setOpacity={setOpacity}
          pathTeamLessonId={pathTeamLesson?.id}
          teamLesson={pathTeamLesson?.teamLesson}
          setCurrentStep={setCurrentStep}
        />
      );
      break;
    case TEAM_LESSON_INITIALIZE_STEP:
      view = (
        <PresentationLobbyStep
          teamLesson={pathTeamLesson?.teamLesson}
          pathTeamLesson={pathTeamLesson}
          setCurrentStep={setCurrentStep}
          setOpen={(value: boolean) => {
            setOpenWrapperForPollModals(value, resetTeamSession, setOpen);
          }}
        />
      );
      hideCloseButton = true;
      break;
    case TEAM_LESSON_PROGRESS_STEP:
      view = (
        <PresentationPresentStep
          pathId={pathTeamLesson?.pathId}
          teamLesson={pathTeamLesson?.teamLesson}
          setCurrentStep={setCurrentStep}
          pathTeamLessonId={pathTeamLesson?.id}
          closeFunction={() => {
            setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
          }}
        ></PresentationPresentStep>
      );
      size = "full";
      hideCloseButton = true;
      break;
    case TEAM_LESSON_END_STEP:
      view = (
        <PresentationFeedbackStep
          teamLesson={pathTeamLesson?.teamLesson}
          setCurrentStep={setCurrentStep}
          closeFunction={() => {
            setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
          }}
          pathId={pathId}
        />
      );
      break;
  }

  return (
    <>
      <LargeModal
        opacity={opacity}
        open={open}
        setOpen={
          ((value: boolean) => {
            setOpenWrapperForPollModals(value, resetTeamSession, setOpen);
          }) as any
        }
        size={size as any}
        hideHeader={hideCloseButton}
        preventClose={hideCloseButton}
      >
        <Box pt={`${safeAreaInsets.top}px`} h={"100%"}>
          {view}
        </Box>
      </LargeModal>
    </>
  );
};
