import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useContext } from "react";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Interval180Days,
  ReachInsightsContext,
} from "../../../State/Reach/ReachInsightsContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  Border_Radius,
  DISABLED_BORDER,
  MODAL_THIRD_TEXT_COLOR,
  Respect_Maroon,
  White,
} from "../../../Styles/HeadversityStyle";
import { setInsightsChartOptions } from "../../../Utils/ChartsUtil";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import InsightsCard from "../../Common/Cards/InsightsCard";
import { InsightsCertSurveyGrowth } from "./InsightsCertSurveyGrowth";
import InsightsColumnChart from "./InsightsColumnChart";
import InsightsLoadingSpinner from "./InsightsLoadingSpinner";
import InsightsNoData from "./InsightsNoData";
import { InsightsNumberDisplay } from "./InsightsNumberDisplay";
import { InsightsProgressTracker } from "./InsightsProgressTracker";

interface InsightsCertPanelProps {
  pdfExport?: boolean;
}

const InsightsCertPanel = (props: InsightsCertPanelProps) => {
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const { certReport, companyCerts } = useContext(ReachInsightsContext);
  const navigate = useNavigate();

  const containerSize =
    props.pdfExport || isMainContainerXL ? "container.xl" : "container.lg";
  let graphDimensionsByBreakpoint = useBreakpointValue({
    base: { width: 300, height: 330 },
    sm: { width: 400, height: 340 },
    md: { width: 460, height: 360 },
    lg: { width: 600, height: 360 },
    xl: { width: 640, height: 360 },
    "2xl": { width: 800, height: 360 },
  }) as { width: number; height: number };
  if (props.pdfExport) {
    graphDimensionsByBreakpoint = { width: 640, height: 360 }; // use xl size for pdf
  }

  const hasCert = companyCerts.length > 0;

  const certPanelBody = () => {
    return (
      <Flex flexDir={"column"} gap={6}>
        <Grid
          gap={6}
          templateRows={{
            base: props.pdfExport ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          templateColumns={{
            base: props.pdfExport ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
          }}
        >
          <GridItem rowSpan={1} colSpan={1} gridRow={1}>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATIONS}
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
              content={
                <InsightsNumberDisplay
                  heading={certReport.allTimeCertCountsData.activeCertifications.toString()}
                  headingColor={Respect_Maroon}
                  height={"120px"}
                  fontSize={"90px"}
                />
              }
              hasTooltip={true}
              tooltipTitle={
                HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_TITLE
              }
              tooltipContent={
                HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_DESCRIPTION
              }
            />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} gridRow={2}>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_ASSIGNED_CERTIFICATIONS}
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
              content={
                <InsightsNumberDisplay
                  heading={certReport.allTimeCertCountsData!.assignedCertifications.toString()}
                  headingColor={Respect_Maroon}
                  height={"120px"}
                  fontSize={"90px"}
                />
              }
            />
          </GridItem>
          <GridItem
            rowSpan={2}
            colSpan={{ base: props.pdfExport ? 2 : 1, md: 2 }}
          >
            <InsightsCard
              title={
                HVLocalizeStrings.REACH_INSIGHTS_CERTIFICATION_ACTIVITY_OVER_TIME
              }
              subtitle={
                certReport.intervalDays > Interval180Days
                  ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                  : HVLocalizeStrings.REACH_INSIGHTS_LAST_180_DAYS
              }
              content={
                certReport.lowDataRunningCounts ||
                !certReport.certActivitySeriesData?.[0] ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={setInsightsChartOptions(
                      "area",
                      certReport.certActivitySeriesData!,
                      certReport.certActivitySeriesData[0].dates,
                      certReport.certActivitySeriesData[0].graphStartDate,
                      graphDimensionsByBreakpoint?.width,
                      graphDimensionsByBreakpoint?.height
                    )}
                  />
                )
              }
            />
          </GridItem>
        </Grid>
        <Grid
          gap={6}
          templateRows={{
            base: props.pdfExport ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
            md: "repeat(1, 1fr)",
          }}
          templateColumns={{
            base: props.pdfExport ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
          }}
        >
          <GridItem rowSpan={1} colSpan={1}>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_USER_FEEDBACK}
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME}
              content={
                <Flex flexDir="column" gap={4}>
                  {certReport.lowDataUserFeedback && (
                    <InsightsNoData
                      description={
                        HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                      }
                    />
                  )}
                  <Flex
                    h={graphDimensionsByBreakpoint?.height}
                    flexDir="column"
                    justifyContent="center"
                  >
                    {certReport.certUserFeedbackReport.map((r) => (
                      <Flex
                        m={2}
                        flexDirection="column"
                        key={r.question as string}
                        px={4}
                      >
                        <Text pl={"5px"} mb={"5px"}>
                          {r.question as string}
                        </Text>
                        <InsightsProgressTracker
                          percentage={parseInt(
                            (r.agree_percentage * 100.0).toFixed(0)
                          )}
                          color={Respect_Maroon}
                          showPercentage={true}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              }
            />
          </GridItem>
          <GridItem
            rowSpan={1}
            colSpan={{ base: props.pdfExport ? 2 : 1, md: 2 }}
          >
            <InsightsCard
              title={
                HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATION_RATES
              }
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
              content={
                certReport.lowDataActiveCertsBreakdown ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <InsightsColumnChart
                    yAxisTitle={`${HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATIONS} %`}
                    data={certReport.activeCertsBreakdownData!}
                    width={graphDimensionsByBreakpoint?.width}
                    height={graphDimensionsByBreakpoint?.height}
                  />
                )
              }
            />
          </GridItem>
        </Grid>
        {certReport.lowDataSurveyGrowth && (
          <Grid
            gap={6}
            templateRows={"repeat(1, 1fr)"}
            templateColumns={{
              base: props.pdfExport ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            }}
          >
            <GridItem
              rowSpan={1}
              colSpan={{ base: props.pdfExport ? 3 : 1, md: 3 }}
            >
              <InsightsCard
                title={HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATIONS}
                subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
                content={
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                }
                hideHeader={true}
              />
            </GridItem>
          </Grid>
        )}

        {!certReport.lowDataSurveyGrowth && (
          <Box
            border={DISABLED_BORDER}
            borderRadius={Border_Radius}
            bg={White}
            boxShadow="lg"
          >
            {certReport.certSurveyGrowthData?.slice(0, 3).map((data, idx) => (
              <Box key={data.question}>
                {idx > 0 && (
                  <Center>
                    <Divider mx={4} />
                  </Center>
                )}
                <InsightsCertSurveyGrowth data={data} />
              </Box>
            ))}
          </Box>
        )}

        {!props.pdfExport && hasCert && (
          <Button
            className="button-link"
            onClick={() => {
              navigate("/reach/insights/certs");
            }}
            fontSize={"lg"}
            fontWeight={"semibold !important"}
            ml="auto"
            mr={isDesktop ? undefined : "auto"}
            mt={6}
            zIndex={1}
            color={MODAL_THIRD_TEXT_COLOR}
            textDecoration={"underline"}
          >
            {HVLocalizeStrings.REACH_INSIGHTS_VIEW_ALL_CERTS}
          </Button>
        )}
      </Flex>
    );
  };
  return (
    <Box
      maxW={containerSize}
      mx={"auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
    >
      <BlockSectionTitle
        title={HVLocalizeStrings.REACH_INSIGHTS_CERT_TITLE}
        description={HVLocalizeStrings.REACH_INSIGHTS_CERT_DESCRIPTION}
        descriptionMarginTop={3}
      ></BlockSectionTitle>
      {certReport.isLoading ? (
        <InsightsLoadingSpinner containerSize={containerSize} />
      ) : (
        certPanelBody()
      )}
    </Box>
  );
};

export default InsightsCertPanel;
