import { useContext, useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Pager, PagerState } from "../../Common/Pager";
import {
  DESTRUCTIVE_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { MdCancel } from "react-icons/md";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { AnimatedButtonColorSet } from "../../Common/AnimatedButton";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { TeamDto, TeamUserDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface TeamListProps {
  teamMembers: TeamUserDto[];
  team: TeamDto;
  contentAbovePager?: JSX.Element;
}

export const TeamList = (props: TeamListProps) => {
  const { teamMembers, team, contentAbovePager } = props;
  const { deleteTeamUserFromServer } =
    useContext<IGlobalProvider>(GlobalContext);
  const [membersPerPage] = useState(5);
  const [pagerState, setPagerState] = useState<PagerState>({
    currentPage: 0,
    totalPage: Math.ceil(
      (teamMembers?.length ?? membersPerPage) / membersPerPage
    ),
    pagePerRow: membersPerPage,
    showPageInput: true,
  });
  const indexOfLast = pagerState.currentPage * pagerState.pagePerRow;
  const indexOfFirst = indexOfLast + pagerState.pagePerRow;
  const currentMembers = teamMembers?.slice(indexOfLast, indexOfFirst);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);

  const handleDeleteTeamUser = (member: TeamUserDto) => {
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_DELETE_USER,
      `${HVLocalizeStrings.TEAM_USER_CONFIRM_DELETE.replace(
        "{0}",
        member?.user?.fullName
      ).replace("{1}", team?.name)}`,
      ConfirmationButtons.YesNo,
      () => {
        track(TEAM_EVENTS.DeleteTeamUser, {
          HV_Team_Id: team?.id,
          HV_Team_Name: team?.name,
        });
        deleteTeamUserFromServer(team?.id, member?.userId).then(() => {
          showToast(
            true,
            "team-user-delete-success",
            HVLocalizeStrings.TEAM_USER_DELETE_SUCCESS_TITLE
          );
        });
      }
    );
  };

  useEffect(() => {
    if (teamMembers) {
      setPagerState({
        currentPage: 0,
        totalPage: Math.ceil(
          (teamMembers?.length ?? membersPerPage) / membersPerPage
        ),
        pagePerRow: membersPerPage,
        showPageInput: true,
      });
    }
  }, [teamMembers]);

  return (
    <>
      <TableContainer w={"100%"}>
        <Table color={PRIMARY_TEXT_COLOR}>
          <Thead>
            <Tr>
              <Th
                p={"0"}
                fontWeight={"normal"}
                fontSize={"sm"}
                w={isMobile ? "50%" : "33%"}
              >
                {HVLocalizeStrings.NAME}
              </Th>
              {!isMobile && (
                <Th p={"0"} fontWeight={"normal"} fontSize={"sm"} w={"33%"}>
                  {HVLocalizeStrings.EMAIL}
                </Th>
              )}
              <Th
                p={"0"}
                fontWeight={"normal"}
                fontSize={"sm"}
                w={isMobile ? "50%" : "33%"}
              ></Th>
            </Tr>
          </Thead>
          <Tbody data-testid={HVTestId.TeamList.memberTable}>
            {currentMembers?.map((member) => (
              <Tr key={member.id}>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                  <Text
                    fontWeight={"semibold"}
                    data-testid={HVTestId.TeamList.name}
                  >
                    {member?.user?.fullName}
                  </Text>
                </Td>
                {!isMobile ? (
                  <Td p={"0"}>
                    <Text data-testid={HVTestId.TeamList.email}>
                      {member?.user?.email}
                    </Text>
                  </Td>
                ) : null}

                <Td textAlign={"right"} p={"0"}>
                  <IconButton
                    aria-label="Delete team-member"
                    bg={"none"}
                    sx={{ _hover: {} }}
                    icon={<MdCancel />}
                    color={DESTRUCTIVE_TEXT_COLOR}
                    onClick={() => handleDeleteTeamUser(member)}
                    data-testid={HVTestId.TeamList.deleteButton}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {contentAbovePager}

      {teamMembers?.length < membersPerPage ? null : (
        <Flex alignSelf={"center"}>
          <Pager
            pagerState={pagerState}
            setPagerState={setPagerState as any}
            dontScroll={true}
            withArrows
            pageTextColor={PRIMARY_TEXT_COLOR}
            buttonColorSet={AnimatedButtonColorSet.Primary}
          />
        </Flex>
      )}
    </>
  );
};
