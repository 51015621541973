import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  Image as ChakraImage,
  Link,
} from "@chakra-ui/react";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { FadeAfterDelay } from "./FadeAfterDelay";
import TopRightButtons from "./TopRightButtons";
import {
  BG_BASE,
  CONTRAST_TEXT_COLOR,
  HeaderImages,
  HEADER_ACHIEVEMENT_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { HeaderContext, IHeaderProvider } from "../../State/HeaderContext";
import { HVTestId } from "../../Testing/dataTestIds";
import { isWebPlatform } from "../../Utils/mobileUtils";
import { CrossFadeImage } from "./CrossFadeImage";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { AppType, GlobalContext } from "../../State/GlobalContext";
import { AppGridMenuMobileApp } from "../Shared/Navigation/AppGridMenuMobileApp";
import { getEnabledAppsCount } from "../../Utils/Helpers";

const MIN_HEIGHT = 70;
const MIN_CLIP = 0.9;
const START_CLIP_AT = 160;
const END_CLIP_AT = 185;
const SHADOW_HEIGHT = 10;

export const Header = () => {
  const { headerState } = useContext<IHeaderProvider>(HeaderContext);

  const INITIAL_MAX_HEIGHT =
    // bigger on desktop or wide app (iPad)
    (isWebPlatform() || window.screen.width >= 568) && window.innerWidth >= 568
      ? 275
      : 225;

  const [maxHeight, setMaxHeight] = useState<number>(INITIAL_MAX_HEIGHT);

  const getPathString = (x: any) =>
    `M 0 0 L 1 0 L 1 1 C 1 1 0.75 ${x} 0.5 ${x} C 0.25 ${x} 0 1 0 1 z`;
  const getContainerClipPath = (x: number, y: number) =>
    `polygon(0px 0px, ${x}px 0px, ${x}px ${y}px, 0px ${y}px)`;

  const isDesktop = useBreakpointValue({ base: false, md: true });
  const {
    theme,
    mainContainerWidth,
    isMainContainerXL,
    setGetHelpOpen,
    safeAreaInsets,
    isSideBarOpen,
  } = useContext<IShellProvider>(ShellContext);

  const {
    selectedUserLanguage,
    currentApp,
    soloEnabled,
    teamEnabled,
    reachEnabled,
    certEnabled,
    resourceLock,
  } = useContext(GlobalContext);

  const [showTitle, setShowTitle] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [shouldClip, setShouldClip] = useState(window.innerWidth >= 568);
  const [clipPath, setClipPath] = useState<string>(getPathString(MIN_CLIP));
  const [containerClipPath, setContainerClipPath] = useState<string>(
    getContainerClipPath(window.innerWidth, MIN_HEIGHT + SHADOW_HEIGHT)
  );
  const [localBackgroundImage, setLocalBackgroundImage] = useState("");

  const [containerScrollPosition, setContainerScrollPosition] =
    useState<number>(0);

  const [redrawTimeout, setRedrawTimeout] = useState<any>();

  const [baseHeader, setBaseHeader] = useState("");

  const headerRef = useRef<any>();

  const [skipToMainContentFocused, setSkipToMainContentFocused] =
    useState(false);

  useEffect(() => {
    const handleScroll = (e: any) => {
      const pos = e.currentTarget.scrollingElement.scrollTop;

      setContainerScrollPosition(pos);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 568) {
      setShouldClip(false);
      setClipPath(getPathString(MIN_CLIP));
      return;
    }

    setShowTitle(containerScrollPosition < 20);
    setShowDescription(containerScrollPosition < 60);
    setShouldClip(true);

    const startAt = START_CLIP_AT;
    const endAt = END_CLIP_AT;

    if (containerScrollPosition >= endAt) {
      setClipPath(getPathString(1));
      setShouldClip(false);
    } else if (containerScrollPosition < startAt) {
      setClipPath(getPathString(MIN_CLIP));
    } else {
      const remaining = (containerScrollPosition - startAt) / (endAt - startAt);

      setClipPath(getPathString(MIN_CLIP + remaining / 10));
    }
  }, [containerScrollPosition, window.innerWidth]);

  useEffect(() => {
    setContainerClipPath(
      getContainerClipPath(mainContainerWidth, MIN_HEIGHT + SHADOW_HEIGHT)
    );
  }, [mainContainerWidth]);

  const drawImage = (header: any) => {
    if (!header || !header.backgroundImage) return;

    const outputImage = document.createElement("canvas");
    const height = maxHeight;
    const baseImage = new Image();
    const inputImage = new Image();

    baseImage.onload = () => {
      inputImage.onload = () => {
        // create a double-sized canvas that will present the output image
        outputImage.width = window.innerWidth * 2;
        outputImage.height = height * 2;

        const ctx = outputImage.getContext("2d");
        if (!ctx) return;

        // draw the base
        ctx.drawImage(
          baseImage,
          0,
          0,
          baseImage.width,
          baseImage.height,
          0,
          0,
          outputImage.width,
          outputImage.height
        );

        // draw the right part on top
        const scale = height / inputImage.height;

        let newX = Math.max(
          0,
          outputImage.width -
            inputImage.width * scale * 2 -
            (window.innerWidth >= 992 && isSideBarOpen ? (240 - 60) * 2 : 0) // 240px when open, 60px when closed; doubled for double-sized canvas
        );

        if (window.innerWidth <= 460) {
          newX += 25;
        }
        if (window.innerWidth >= 992) {
          newX -= 120;
        }

        // for now, we have one image that already has the opactiy baked in :(
        const opacityFactor =
          header.backgroundImage === HEADER_ACHIEVEMENT_BACKGROUND ? 0.8 : 0.5;

        // with a gradual fade based on the distance from the right edge
        const v = window.innerWidth - inputImage.width - 100;
        ctx.globalAlpha =
          (v < 0 ? 0.4 : v > 600 ? 1 : 0.5 + v / 1000) * opacityFactor;

        ctx.drawImage(
          inputImage,
          0,
          0,
          inputImage.width,
          inputImage.height,
          newX,
          0,
          inputImage.width * scale * 2,
          inputImage.height * scale * 2
        );

        setLocalBackgroundImage(outputImage.toDataURL());
      };

      inputImage.crossOrigin = "anonymous";

      if (
        header.backgroundImage.startsWith("http") ||
        header.backgroundImage.startsWith("/")
      ) {
        inputImage.src = header.backgroundImage;
      } else {
        inputImage.src = HeaderImages[header.backgroundImage];
      }
    };

    // putting "https://cdn.headversity.com" in the theme for these causes theming issues... so prepending here instead
    baseImage.crossOrigin = "anonymous";
    baseImage.src =
      "https://cdn.headversity.com" +
      theme.themeInstance.colors[header.backgroundImageBase ?? BG_BASE];
  };

  useEffect(() => {
    // update immediately on header or theme change
    if (!headerState || !theme) return;

    setBaseHeader(
      "https://cdn.headversity.com" +
        theme.themeInstance.colors[headerState.backgroundImageBase ?? BG_BASE]
    );

    drawImage(headerState);
  }, [headerState, theme, isSideBarOpen]);

  useEffect(() => {
    // debounce on width change
    if (redrawTimeout) {
      clearTimeout(redrawTimeout);
    }

    const timeout = setTimeout(() => {
      drawImage(headerState);
    }, 200);

    setRedrawTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [window.innerWidth]);

  useEffect(() => {
    const height =
      window.innerWidth >= 568
        ? INITIAL_MAX_HEIGHT
        : safeAreaInsets.top + (isWebPlatform() ? 59 : 48);

    setMaxHeight(height);
  }, [headerState, window.innerWidth]);

  const appLogo = `https://cdn.headversity.com/app/resources/other/logos/${currentApp?.toLowerCase()}-logo-white-${selectedUserLanguage}.png`;

  const appsEnabledCount = getEnabledAppsCount(
    soloEnabled,
    teamEnabled,
    reachEnabled,
    certEnabled
  );

  return (
    <>
      <Box // holds the position of the header
        height={`${maxHeight}px`}
        pointerEvents={"none"}
      />

      <Box
        width={"100%"}
        height={`${maxHeight}px`}
        transition="0.3s height ease-in-out"
        zIndex={100}
        pos={window.innerWidth < 568 ? "fixed" : "sticky"}
        top={window.innerWidth < 568 ? "0px" : "-215px"}
        mt={window.innerWidth < 568 ? 0 : `${-maxHeight}px`}
        filter={"drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.3));"}
        data-testid={HVTestId.Header.container}
      >
        <header>
          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path d={clipPath}></path>
              </clipPath>
            </defs>
          </svg>
        </header>
        <Box
          width={"100%"}
          overflow={"hidden"}
          pos={"relative"}
          clipPath={shouldClip ? "url(#myClip)" : undefined}
        >
          {isDesktop && localBackgroundImage?.length > 0 && (
            <CrossFadeImage
              containerPos={"relative"}
              baseImage={baseHeader}
              height={`${maxHeight}px`}
              src={localBackgroundImage}
            />
          )}
          {!isDesktop && localBackgroundImage?.length > 0 && (
            <ChakraImage
              width={"100%"}
              height={`${maxHeight}px`}
              transition="0.3s height ease-in-out"
              src={localBackgroundImage}
              objectFit={"cover"}
            />
          )}
          <Flex
            width={"100%"}
            pos={"absolute"}
            top={
              isWebPlatform()
                ? window.screen.width >= 568
                  ? "105px"
                  : "90px"
                : headerState && headerState.showLogo
                ? "117px"
                : "57px"
            }
            transition="0.2s top ease-in-out"
            left={"0px"}
            justify={"center"}
            align={"flex-start"}
          >
            <Stack
              pos={"relative"}
              w={isMainContainerXL ? "container.xl" : "container.lg"}
              maxW={"100%"}
              px="20px"
            >
              {isWebPlatform() && headerState && (
                <FadeAfterDelay>
                  <Box
                    filter={"drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))"}
                    transition="0.1s opacity ease-in-out"
                  >
                    <Heading
                      color={CONTRAST_TEXT_COLOR}
                      fontSize={
                        isDesktop
                          ? "4xl"
                          : headerState && headerState.showLogo
                          ? "22px"
                          : "20px"
                      }
                      lineHeight={isDesktop ? undefined : "1"}
                      transition="0.2s opacity ease-in-out"
                      data-testid={HVTestId.Header.title}
                      as="h1"
                      tabIndex={-1}
                      display={"inline-block"}
                      ref={headerRef}
                      mt={isWebPlatform() ? 1 : "10px"}
                      opacity={
                        !showTitle
                          ? 0
                          : safeAreaInsets.top > 0
                          ? containerScrollPosition / 20
                          : 1
                      }
                    >
                      {headerState.title}
                    </Heading>
                    <Text
                      display={window.innerWidth < 568 ? "none" : "block"}
                      maxW={"container.md"}
                      color={CONTRAST_TEXT_COLOR}
                      fontSize={isDesktop ? "22px" : "md"}
                      mr={
                        isWebPlatform() || (headerState && headerState.showLogo)
                          ? undefined
                          : "30px"
                      } // avoid the get help button on non-home pages
                      lineHeight={"1.2"}
                      opacity={showDescription ? "1.0" : "0"}
                      transition={"none"}
                      dangerouslySetInnerHTML={{
                        __html: headerState.description,
                      }}
                      as="h2"
                    />
                  </Box>
                </FadeAfterDelay>
              )}
            </Stack>
          </Flex>
        </Box>
      </Box>

      <Box // holds the position of the content after the header; putting the logo/icons in this box allows the scrollbar to push them over in Chrome (appears related to filter?)
        width={"100%"}
        height={`${maxHeight}px`}
        pos={"sticky"}
        top={"0px"}
        mt={`${-maxHeight}px`}
        zIndex={101}
        clipPath={isWebPlatform() ? containerClipPath : ""} // this prevents the header from hanging over the content area and blocking clicks
      >
        <ChakraImage
          transition="0.2s opacity ease-in-out"
          pos={window.innerWidth < 568 ? "fixed" : "absolute"}
          left={
            window.innerWidth < 568
              ? isWebPlatform()
                ? "50px"
                : "15px"
              : `calc(50% - ${isDesktop ? 90 : 82}px)`
          }
          top={
            window.innerWidth < 568
              ? isWebPlatform()
                ? "10px"
                : currentApp === AppType.REACH
                ? `${safeAreaInsets.top - 1}px`
                : `${safeAreaInsets.top - 5}px`
              : "17px"
          }
          src={
            window.innerWidth < 568
              ? appLogo
              : "https://cdn.headversity.com/app/resources/other/logo.svg"
          }
          width={window.innerWidth < 568 ? "80px" : "180px"}
          filter={"drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))"}
          overflow={"visible"}
          alt={HVLocalizeStrings.HEADVERSITY}
        />
        {!resourceLock && !isWebPlatform() && appsEnabledCount > 1 && (
          <AppGridMenuMobileApp setIsOpen={() => {}} />
        )}
        {isWebPlatform() && <TopRightButtons setOpen={setGetHelpOpen} />}
      </Box>

      {!isWebPlatform() && (
        <>
          <Box
            pos="sticky"
            zIndex={
              // 1400 = under the chakra overlay, over the safe area box
              1400
            }
          >
            <TopRightButtons setOpen={setGetHelpOpen} />
          </Box>
        </>
      )}

      <Link
        color={CONTRAST_TEXT_COLOR}
        textDecoration={"underline"}
        position={"absolute"}
        zIndex={skipToMainContentFocused ? 1000 : -1}
        top={isWebPlatform() ? "60px" : `${safeAreaInsets.top + 3}px`}
        left={`calc(50% - 84px)`}
        tabIndex={1}
        onFocus={() => {
          setSkipToMainContentFocused(true);
        }}
        onBlur={() => {
          setSkipToMainContentFocused(false);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            headerRef.current.focus();
          }
        }}
      >
        {HVLocalizeStrings.SKIP_TO_MAIN_CONTENT}
      </Link>
    </>
  );
};
