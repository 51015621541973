import { GetHelpViewsReportDto, ReportDto } from "@headversity/contract";
import { useCallback, useState } from "react";
import { reportGetHelp } from "../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Mid_Blue } from "../../../Styles/HeadversityStyle";
import {
  getPointStartFromDate,
  parseDateFromYearMonth,
} from "../../../Utils/ChartsUtil";
import { getKey } from "../../../Utils/Helpers";
import { Interval180Days } from "../../../State/Reach/ReachInsightsContext";

export interface IHelpResourceReportProvider {
  fetchReport: () => Promise<void>;
  helpResourcesViewsData: HelpResourcesSeriesData[];
  intervalDays: number;
  lowData: boolean;
}

export interface HelpResourcesSeriesData {
  name: string;
  data: number[];
  dates: string[];
  zIndex: number;
  marker: {
    enabled: boolean;
  };
  color: string;
  graphStartDate: number;
}

const mapHelpResourceViewsData = (
  dto: ReportDto<GetHelpViewsReportDto[]>
): HelpResourcesSeriesData[] => {
  if (dto.noData) {
    return [];
  }
  const report = dto.report!;

  const dataPoints = report.map((views) => views.gh_interactions);
  const dates = report.map((views) => views.combineddate);

  const firstDateStr = report[0].combineddate;
  const dateUTC = parseDateFromYearMonth(firstDateStr);

  return [
    {
      name: HVLocalizeStrings.REACH_INSIGHTS_UNIQUE_HELP_RESOURCE_CLICKS,
      data: dataPoints,
      dates,
      color: Mid_Blue,
      zIndex: 1,
      marker: {
        enabled: false,
      },
      graphStartDate: getPointStartFromDate(dateUTC),
    },
  ];
};

export const useHelpResourceReport = (): IHelpResourceReportProvider => {
  const [helpResourceViewsData, setHelpResourceViewsData] = useState<
    HelpResourcesSeriesData[]
  >([]);
  const [lowData, setLowData] = useState<boolean>(false);
  const [intervalDays, setIntervalDays] = useState<number>(Interval180Days);
  const fetchReportHandler = useCallback(async () => {
    const reportResp = await reportGetHelp(getKey());
    setHelpResourceViewsData(mapHelpResourceViewsData(reportResp.data.views));
    setIntervalDays(reportResp.data.intervalDays);

    setLowData(reportResp.data.views.noData);
  }, []);
  return {
    fetchReport: fetchReportHandler,
    helpResourcesViewsData: helpResourceViewsData,
    lowData,
    intervalDays,
  };
};
