import { useContext, useEffect, useState } from "react";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import { HVOrangeGradient } from "../../../Styles/HeadversityStyle";

import { ContentBlock20 } from "../Shared/ContentBlock20";

import { EVENTS, track } from "../../../Utils/Analytics";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import { CompletionState, NanoPracticeDto } from "@headversity/contract";
import {
  isFave,
  isNanoPracticeDoneTodayOrByStartDate,
} from "./NanoPracticeUtils";
import { needsSkillScore } from "../../../Utils/SkillsUtil";
import { cloneAndTranslate } from "../../../Utils/ObjectUtil";

interface NanoPracticeBlockProps {
  practice: NanoPracticeDto;
  fullDescription?: boolean;
  width?: string;
  height?: string;
  startDate?: string;
  noHover?: boolean;
  isSmall?: boolean;
  from: string;
  isPortrait?: boolean;
  showPoints?: boolean;
  hideDone?: boolean;
  descriptionColor?: string;
  onClickOverride?: () => void;
}

export const NanoPracticeBlock = ({
  practice,
  fullDescription,
  width,
  height,
  startDate,
  noHover,
  isSmall,
  from,
  isPortrait,
  showPoints,
  hideDone,
  descriptionColor,
  onClickOverride,
}: NanoPracticeBlockProps) => {
  const {
    skills,
    setNanoPracticeModalOpen,
    setGlobalPractice,
    setGlobalHeadScanQuestionModalOpen,
    setSelectedSkill,
    favorites,
    nanoPracticeInstances,
    userSkillStats,
    currentApp,
    clearGlobalActivities,
    setOpenedActivityFromStartTraining,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    if (currentApp !== AppType.CERT) {
      const isFavorite = isFave(favorites, practice);
      setIsFavorite(isFavorite);
    }
  }, [favorites, currentApp, practice]);

  const getSkillToUse = () => {
    return skills.filter(
      (s) => s.id === practice.skillCompetencies[0]?.skillId
    )[0];
  };
  const skillToUse = currentApp !== AppType.CERT ? getSkillToUse() : undefined;

  return (
    <>
      <ContentBlock20
        imageUrl={
          isPortrait
            ? practice.imageUrl
                .toString()
                .replace("nano-practices", "nano-practices-portrait-thumb")
            : practice.imageUrl.toString()
        }
        typeTitle={HVLocalizeStrings.NANO_PRACTICE}
        hoverText={
          !fullDescription && !noHover
            ? practice.description.toString()
            : undefined
        }
        title={practice.name.toString()}
        subtitle={skillToUse ? (skillToUse.name as string) : undefined}
        subtitleImageUrl={
          skillToUse ? (skillToUse.imageUrl as string) : undefined
        }
        titleBg={HVOrangeGradient}
        description={
          fullDescription ? practice.description.toString() : undefined
        }
        descriptionColor={descriptionColor}
        points={showPoints ? 2 : undefined}
        pointsBgColor={"#C4460033"}
        showFavorite={isFavorite}
        truncateDescriptionAtSentence={true}
        width={width}
        height={height}
        isPortrait={isPortrait}
        onClick={
          onClickOverride
            ? onClickOverride
            : () => {
                const event = {
                  HV_PracticeId: practice?.id,
                  HV_PracticeTitle: practice?.name_loc?.en,
                  HV_From: from,
                  HV_PracticeType: practice?.nanoPracticeType,
                } as any;

                event.HV_SkillId = skillToUse?.id;
                event.HV_SkillName = skillToUse?.slug;

                track(EVENTS.NanoPracticeOpened, event);

                clearGlobalActivities();

                // let the clear happen before setting the new global state so modal can rest (in case user picks same lesson)
                setTimeout(() => {
                  setOpenedActivityFromStartTraining(from === "StartTraining");

                  // cloneAndTranslate in case we try to load the same item into an open modal
                  setGlobalPractice(cloneAndTranslate(practice));

                  setNanoPracticeModalOpen(false);

                  // if we're in SOLO and we haven't scored for this skill, open headscan and then load item
                  if (
                    currentApp !== AppType.CERT &&
                    needsSkillScore(userSkillStats, skillToUse?.id)
                  ) {
                    setSelectedSkill(skillToUse);
                    setGlobalHeadScanQuestionModalOpen(true);
                  } else {
                    setNanoPracticeModalOpen(true);
                  }
                }, 1);
              }
        }
        isSmall={isSmall}
        hasBlueFilter={true}
        doneState={
          hideDone
            ? CompletionState.notStarted
            : isNanoPracticeDoneTodayOrByStartDate(
                practice.id,
                nanoPracticeInstances,
                startDate
              )
            ? CompletionState.done
            : CompletionState.notStarted
        }
        dataTestId={`${HVTestId.NanoPracticeBlock.nanoPracticeBlock}_${practice.id}`}
      />
    </>
  );
};
