import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  TeamLessonUserInstanceDto,
  TeamLessonUserInstanceParticipantDto,
  TeamLessonSlideProgressDto,
} from "@headversity/contract";

export const getTeamLessonUserInstanceParticipants = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceParticipantDto[]>> => {
  return AxiosClient.get(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/participants`,
    getConfig(token)
  );
};

export const submitTeamLessonUserInstanceRating = (
  teamLessonUserInstanceId: number,
  motivatingRating: number,
  relevantRating: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceParticipantDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/rating`,
    {
      motivatingRating: motivatingRating,
      relevantRating: relevantRating,
    },
    getConfig(token)
  );
};

export const setTeamLessonUserInstanceInitialize = (
  pathTeamLessonId: number,
  teamId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/team/${teamId}/pathTeamLesson/${pathTeamLessonId}/teamLessonUserInstances/initialize`,
    null,
    getConfig(token)
  );
};

export const setTeamLessonUserInstanceInProgress = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/inProgress`,
    null,
    getConfig(token)
  );
};

export const setTeamLessonInProgressStep = (
  teamLessonUserInstanceId: number,
  step: string,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/inProgressStep`,
    { step: step },
    getConfig(token)
  );
};

export const setTeamLessonUserInstanceEnd = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/end`,
    null,
    getConfig(token)
  );
};

export const setTeamLessonUserInstanceForceEnd = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/forceEnd`,
    null,
    getConfig(token)
  );
};

export const setTeamLessonUserInstanceCancel = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/cancel`,
    null,
    getConfig(token)
  );
};

export const setParticipateForTeamLessonUserInstance = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceParticipantDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/participate`,
    null,
    getConfig(token)
  );
};

export const removeSelfForTeamLessonParticipation = (
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<TeamLessonUserInstanceParticipantDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/leaveParticipation`,
    null,
    getConfig(token)
  );
};

export const notifySlideProgress = (
  teamLessonUserInstanceId: number,
  teamLessonSlideProgressDto: TeamLessonSlideProgressDto,
  token: string
): Promise<AxiosResponse<TeamLessonSlideProgressDto>> => {
  return AxiosClient.post(
    `api/teamLessonUserInstances/${teamLessonUserInstanceId}/notifySlideProgress`,
    teamLessonSlideProgressDto,
    getConfig(token)
  );
};
