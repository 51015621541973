import { useContext, useEffect } from "react";
import { onPusherEvent } from "../../Utils/PusherChannelNotifications";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { TeamLessonSlideProgressDto } from "@headversity/contract";

export const useTeamPusherSubs = () => {
  const {
    pusherClient,
    pusherTeamChannels,
    reloadPathsForAllTeamsFromServer,
    getPollInstancesFromServer,
    currentTeamLessonUserInstanceId,
    setCurrentSlideIndex,
    getTeamLessonUserInstanceParticipantsFromServer,
    getPollInstanceResultFromServer,
    participantModalOpen,
  } = useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "path-refresh",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
          timeoutId = setTimeout(() => {
            reloadPathsForAllTeamsFromServer();
            timeoutId = null;
          }, 1000);
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "NewPollRequest",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (
            data.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
          ) {
            if (timeoutId) {
              clearTimeout(timeoutId);
              timeoutId = null;
            }
            timeoutId = setTimeout(() => {
              getPollInstancesFromServer();
              timeoutId = null;
            }, 1000);
          }
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "participant-joined",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (
            data.TeamLessonUserInstanceId === currentTeamLessonUserInstanceId
          ) {
            if (timeoutId) {
              clearTimeout(timeoutId);
              timeoutId = null;
            }
            timeoutId = setTimeout(() => {
              getTeamLessonUserInstanceParticipantsFromServer(
                currentTeamLessonUserInstanceId
              );
              timeoutId = null;
            }, 1000);
          }
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutIds: any = {};
      return onPusherEvent(
        pusherClient,
        "PollRequestAnswer",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          const pollInstanceId = data.pollQuestionUserInstanceId;
          if (timeoutIds[pollInstanceId]) {
            clearTimeout(timeoutIds[pollInstanceId]);
            timeoutIds[pollInstanceId] = null;
          }
          timeoutIds[pollInstanceId] = setTimeout(() => {
            getPollInstancesFromServer().then((result) => {
              const pollInstance = result.find((x) => x.id === pollInstanceId);
              if (pollInstance) {
                getPollInstanceResultFromServer(pollInstanceId);
              }
            });
            timeoutIds[pollInstanceId] = null;
          }, 1000);
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (
      pusherTeamChannels &&
      pusherClient &&
      participantModalOpen &&
      currentTeamLessonUserInstanceId
    ) {
      return onPusherEvent(
        pusherClient,
        "team-lesson-slide-progress",
        pusherTeamChannels,
        (data: TeamLessonSlideProgressDto, channelName: string) => {
          if (
            data.teamLessonUserInstanceId !== currentTeamLessonUserInstanceId
          ) {
            return;
          }

          setCurrentSlideIndex(data.index);
        },
        1
      );
    }
  }, [
    pusherClient,
    pusherTeamChannels,
    participantModalOpen,
    currentTeamLessonUserInstanceId,
  ]);

  return null;
};
