import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Black, Equity_Black } from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface UserModalViewTabPanelProps {
  fullName: string;
  email: string;
  role: string;
  language: string;
  activationEmail: string;
  inviteCode: string;
  customInfo?: { [p: string]: any };
  isEmailSent: boolean;
}

const UserModalViewTabPanel = (props: UserModalViewTabPanelProps) => {
  const {
    fullName,
    email,
    role,
    language,
    activationEmail,
    inviteCode,
    customInfo,
    isEmailSent,
  } = props;

  // get custom fields, if any
  const customInfoElements: JSX.Element[] = [];
  if (customInfo) {
    for (let key in customInfo) {
      customInfoElements.push(
        <UserViewField key={key} label={key} value={customInfo[key]} />
      );
    }
  }

  return (
    <>
      <UserViewField
        label={HVLocalizeStrings.FULL_NAME}
        value={fullName}
        dataTestId={HVTestId.UserModalViewTabPanel.fullName}
      />
      <UserViewField
        label={HVLocalizeStrings.EMAIL_ADDRESS}
        value={email}
        dataTestId={HVTestId.UserModalViewTabPanel.email}
      />
      <UserViewField
        label={HVLocalizeStrings.REACH_USER_ROLE}
        value={role}
        dataTestId={HVTestId.UserModalViewTabPanel.role}
      />
      <UserViewField
        label={HVLocalizeStrings.LANGUAGE_TITLE}
        value={language}
        dataTestId={HVTestId.UserModalViewTabPanel.language}
      />
      <UserViewField
        label={
          isEmailSent
            ? HVLocalizeStrings.REACH_USERS_EMAIL_SENT_DATE
            : HVLocalizeStrings.REACH_ACTIVATION_EMAIL_SEND_DATE
        }
        value={activationEmail}
        dataTestId={HVTestId.UserModalViewTabPanel.emailDate}
      />
      <UserViewField
        label={HVLocalizeStrings.REACH_INVITE_CODE}
        value={inviteCode}
        dataTestId={HVTestId.UserModalViewTabPanel.inviteCode}
      />
      {customInfoElements}
    </>
  );
};

interface UserViewFieldProps {
  label: string;
  value: string;
  dataTestId?: string;
}

const UserViewField = (props: UserViewFieldProps) => {
  const { label, value, dataTestId } = props;
  return (
    <Flex gap={6}>
      <Text fontWeight={"semibold"} fontSize="sm" color={Black} width={"150px"}>
        {label}
      </Text>
      <Text fontSize="sm" color={Equity_Black} data-testid={dataTestId}>
        {value}
      </Text>
    </Flex>
  );
};

export default UserModalViewTabPanel;
