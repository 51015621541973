import React, { useContext } from "react";
import { Box, Icon, Menu, MenuButton } from "@chakra-ui/react";
import {
  CONTRAST_TEXT_COLOR,
  SIDEBAR_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { BiCaretDown } from "react-icons/bi";
import { AppGridMenuList } from "./AppGridMenuList";
import { getEnabledAppsCount } from "../../../Utils/Helpers";
import { BrandedLogo } from "../../Common/HeaderWelcomeText";

interface AppGridMenuProps {
  setIsOpen: (open: boolean) => void;
}
export const AppGridMenu = ({ setIsOpen }: AppGridMenuProps) => {
  const { soloEnabled, teamEnabled, reachEnabled, certEnabled } =
    useContext<IGlobalProvider>(GlobalContext);

  const appsEnabledCount = getEnabledAppsCount(
    soloEnabled,
    teamEnabled,
    reachEnabled,
    certEnabled
  );

  return (
    <Box ml={"10px"} mt="2px">
      {appsEnabledCount <= 1 ? (
        <BrandedLogo reducedSize={true} />
      ) : (
        <Menu>
          <MenuButton>
            <BrandedLogo reducedSize={true} />

            <Icon
              h="18px"
              mt="-5px"
              minW={"28px"}
              bg={SIDEBAR_BACKGROUND_COLOR}
              color={CONTRAST_TEXT_COLOR}
              _hover={{ bg: SIDEBAR_BACKGROUND_COLOR }}
              _active={{ bg: SIDEBAR_BACKGROUND_COLOR }}
              as={BiCaretDown}
              aria-label={""}
            />
          </MenuButton>

          <AppGridMenuList setIsOpen={setIsOpen} />
        </Menu>
      )}
    </Box>
  );
};
