import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HeaderSetter } from "../../Common/HeaderSetter";
import {
  BG_TEAM_BASE,
  Border_Radius,
  HEADER_TEAM_HOME_BACKGROUND,
  TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR,
} from "../../../Styles/HeadversityStyle";
import {
  Box,
  Flex,
  Image,
  Spinner,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { BlockSection } from "../../Common/BlockSection";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { useNavigate } from "react-router-dom";
import { PresentationBlock } from "../Presentation/PresentationBlock";
import { TeamCommandModals } from "../TeamManagement/TeamCommandModals";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { MdInfo } from "react-icons/md";
import IconWithToolTip from "../../Common/IconWithToolTip";
import {
  getNextUpPathTeamLessons,
  getPathForTeamLesson,
  getScrubbedTeamLessonDescription,
} from "../../../Utils/TeamUtil";
import { AboutTeamModal } from "../AboutTeam/AboutTeamModal";
import { TeamTrainingsScrollPanel } from "../../Solo/Shared/TeamTrainingsScrollPanel";
import TeamCommandCard from "../TeamCommandCard";
import { truncate } from "../../../Utils/Helpers";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import { PathDto, PathTeamLessonDto } from "@headversity/contract";
import { HeaderWelcomeText } from "../../Common/HeaderWelcomeText";

export const TeamHomePage = () => {
  const { paths, currentTeam, teams, teamInitializing, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);

  const [catchUpPathTeamLessons, setCatchUpPathTeamLessons] = useState<
    PathTeamLessonDto[] | null
  >(null);

  const { onClose, onOpen, isOpen } = useDisclosure();
  const [commandMode, setCommandMode] = useState<
    "add" | "select" | "edit" | "join" | null
  >(null);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [nextUpPathTeamLesson, setNextUpPathTeamLesson] =
    useState<PathTeamLessonDto>();
  const [allNextUpPathTeamLessons, setAllNextUpPathTeamLessons] = useState<
    PathTeamLessonDto[]
  >([]);

  const HomePageToolTip = () => {
    return (
      <IconWithToolTip
        text={HVLocalizeStrings.TEAM_CATCHUP_HOMEPAGE_TOOLTIP_TEXT}
        placement={"bottom"}
        icon={MdInfo}
        color={TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR}
      />
    );
  };

  useEffect(() => {
    if (!paths) {
      return;
    }
    const teamLessonsToCatchUp: any[] = [];
    const keys = Object.keys(paths);
    keys.forEach((key: any) => {
      if (key !== "0") {
        const teamPaths = paths[key];
        teamPaths?.forEach((teamPath: any) => {
          teamPath?.pathTeamLessons?.forEach(
            (pathTeamLesson: PathTeamLessonDto) => {
              if (
                pathTeamLesson.teamLessonInstanceStatus ===
                TeamLessonUserInstanceStatus.Catchup
              ) {
                const team = getTeam(key);
                if (team && typeof team !== "string") {
                  pathTeamLesson.team = team;
                }
                teamLessonsToCatchUp.push(pathTeamLesson);
              }
            }
          );
        });
      }
    });
    setCatchUpPathTeamLessons(teamLessonsToCatchUp);
  }, [paths]);

  useEffect(() => {
    if (
      (!sessionStorage || !sessionStorage.getItem("teamAboutShown")) &&
      !currentTeam
    ) {
      if (sessionStorage) {
        sessionStorage.setItem("teamAboutShown", "true");
      }
      setIsAboutOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!paths || !currentTeam?.id || !(currentTeam.id in paths)) return;

    const nextUps = getNextUpPathTeamLessons(paths, currentTeam);

    // prefer teamLesson from path that is in-progress or never started
    const nextUp =
      nextUps.find((p) => p.isStarted && !p.isCompleted) || // in-progress
      nextUps.find((p) => !p.isCompleted) || // never started
      nextUps[0];

    setNextUpPathTeamLesson(nextUp);
    setAllNextUpPathTeamLessons(nextUps.filter((x) => x !== nextUp));
  }, [paths, currentTeam]);

  const getTeam = (teamId: number) => {
    const filteredTeams = teams.filter((item) => item.id == teamId);
    if (filteredTeams.length > 0) {
      return filteredTeams[0];
    }
    return "";
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={<HeaderWelcomeText onInfoClick={() => setIsAboutOpen(true)} />}
        description={HVLocalizeStrings.TEAM_HOME_DESCRIPTION}
        backgroundImage={HEADER_TEAM_HOME_BACKGROUND}
        backgroundImageBase={BG_TEAM_BASE}
        showLogo={true}
      />
      {teamInitializing ? (
        <Flex justify={"center"}>
          <Spinner />
        </Flex>
      ) : (
        <BlockSection fadeIn={true} title={""} columns={1}>
          {window.innerWidth < 568 && (
            <HeaderWelcomeText blockSection={true} blockSectionMb={2} />
          )}
          <Flex w={"100%"} flexDir={"column"} gap={"8"} my={"3"}>
            <>
              <Box
                bgImage={
                  "url(https://cdn.headversity.com/app/resources/bg-headers/bg-aqua.jpg)"
                }
                bgSize={"100% 100%"}
                borderRadius={Border_Radius}
                boxShadow={"lg"}
              >
                {currentTeam &&
                  teams &&
                  allNextUpPathTeamLessons.length > 0 &&
                  nextUpPathTeamLesson && (
                    <>
                      <FeaturedPathBlock
                        pathTeamLesson={nextUpPathTeamLesson}
                      />

                      <TeamCommandCard noTopBorderRadius={true} />
                    </>
                  )}

                {!currentTeam && (
                  <Flex w={"100%"} padding={10}>
                    <Flex w={"100%"} h={"100%"}>
                      <Flex flexDir={"column"}>
                        <BlockSectionTitle
                          primaryTextColor={"white"}
                          title={HVLocalizeStrings.TEAM_GET_STARTED}
                        />
                        <Flex flexDir={"row"}>
                          <Flex flexDir={"column"} gap={"8"} h={"100%"}>
                            <Text
                              color={"white"}
                              my={"2"}
                              dangerouslySetInnerHTML={{
                                __html: `${HVLocalizeStrings.TEAM_GET_STARTED_DESCRIPTION}`,
                              }}
                            ></Text>
                            <Flex
                              flexDir={["column", "column", "row"]}
                              gap={"3"}
                              alignItems={"center"}
                            >
                              <AnimatedButton
                                text={HVLocalizeStrings.JOIN_EXISTING_TEAM}
                                onClick={() => {
                                  setCommandMode("join");
                                  onOpen();
                                }}
                                w={200}
                              ></AnimatedButton>
                              <AnimatedButton
                                colorSet={AnimatedButtonColorSet.Third}
                                text={HVLocalizeStrings.TEAM_CREATE_A_TEAM}
                                onClick={() => {
                                  setCommandMode("add");
                                  onOpen();
                                }}
                                w={200}
                              ></AnimatedButton>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Box>

              {currentTeam && teams && (
                <Box mt={"20px"}>
                  <BlockSectionTitle
                    title={HVLocalizeStrings.TEAM_MORE_TO_EXPLORE}
                    description={
                      HVLocalizeStrings.TEAM_MORE_TO_EXPLORE_DESCRIPTION
                    }
                  />
                  <TeamTrainingsScrollPanel
                    isDesktop={isDesktop}
                    pathTeamLessons={allNextUpPathTeamLessons}
                    showPath={true}
                    from={"TEAM Home"}
                  />
                </Box>
              )}

              {currentTeam && teams && (
                <Flex flexDir={"column"}>
                  <BlockSectionTitle
                    title={HVLocalizeStrings.TEAM_HOME_PAGE_NEXT_UP_FOR_YOU}
                    description={
                      catchUpPathTeamLessons &&
                      catchUpPathTeamLessons?.length > 0 ? (
                        <>
                          {
                            HVLocalizeStrings.TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_1
                          }{" "}
                          <HomePageToolTip />{" "}
                          {
                            HVLocalizeStrings.TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_2
                          }
                        </>
                      ) : (
                        <>
                          {
                            HVLocalizeStrings.TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_1
                          }{" "}
                          <HomePageToolTip />{" "}
                          {
                            HVLocalizeStrings.TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_2
                          }
                        </>
                      )
                    }
                  />

                  {catchUpPathTeamLessons && catchUpPathTeamLessons.length > 0 && (
                    <ScrollPanel
                      scrollOnMobileOnly={false}
                      spaceBetween={16}
                      showChevronContainer={true}
                      slidesPerGroup={isDesktop ? 3 : 1}
                      isPaginationOnContainer={true}
                    >
                      {catchUpPathTeamLessons.map((item) => {
                        return ScrollPanelBlock(
                          <PresentationBlock
                            pathTeamLesson={item}
                            customTitle={item?.team?.name}
                            team={item?.team}
                            margin={"10px 0px"}
                          />,
                          "290px",
                          item.id
                        );
                      })}
                    </ScrollPanel>
                  )}
                </Flex>
              )}
            </>
          </Flex>
        </BlockSection>
      )}
      <TeamCommandModals open={isOpen} close={onClose} type={commandMode} />
      <AboutTeamModal open={isAboutOpen} setOpen={setIsAboutOpen} />
    </>
  );
};

interface FeaturedPathBlockProps {
  pathTeamLesson: PathTeamLessonDto;
}

const FeaturedPathBlock = ({ pathTeamLesson }: FeaturedPathBlockProps) => {
  const isMd = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const { selectedUserLanguage, paths, currentTeam } =
    useContext<IGlobalProvider>(GlobalContext);
  const navigate = useNavigate();

  const [selectedPath, setSelectedPath] = useState<PathDto | undefined>();

  useEffect(() => {
    // set the selected path
    if (pathTeamLesson) {
      setSelectedPath(
        getPathForTeamLesson(pathTeamLesson.teamLesson, paths, currentTeam)
      );
    }
  }, [pathTeamLesson]);

  return (
    <Box p={isDesktop ? "25px 30px" : "20px"}>
      <BlockSectionTitle
        primaryTextColor={"white"}
        title={`${HVLocalizeStrings.TEAM_HOME_NEXT_UP} ${currentTeam?.name}`}
      />
      <Box mt={"20px"}>
        <Flex
          h={isDesktop ? "200px" : undefined}
          flexDir={isDesktop ? "row" : "column"}
          gap={isDesktop ? "25px" : 0}
          textAlign="left"
        >
          <Image
            borderRadius={Border_Radius}
            src={pathTeamLesson?.teamLesson?.imageUrl as string}
            cursor="pointer" // since we have the CTA button, this is ok
            onClick={() => {
              track(TEAM_EVENTS.ContinuePath, {
                HV_Team_Id: currentTeam?.id,
                HV_Team_Name: currentTeam?.name,
                HV_Path_Id: selectedPath?.id,
                HV_Path_Name: selectedPath?.name,
              });
              navigate(
                `/team/paths?pathId=${selectedPath?.id}&teamLessonId=${pathTeamLesson.teamLessonId}`
              );
            }}
            alt=""
          />

          <Box m={!isDesktop ? 1 : undefined} mt={isDesktop ? 1 : 4}>
            <Text
              color="white"
              fontSize="lg"
              lineHeight={"1.4rem"}
              fontWeight={"600"}
            >
              <b>
                {selectedPath?.name !== undefined
                  ? (selectedPath?.name as string)
                  : ""}
              </b>
              {": "}
              {pathTeamLesson?.teamLesson?.name as string}
            </Text>
            <Text
              color="white"
              mt={"2"}
              mb={8}
              fontSize={"14px"}
              lineHeight={"1.2rem"}
              mr={isDesktop ? "40px" : undefined}
              dangerouslySetInnerHTML={{
                __html: truncate(
                  getScrubbedTeamLessonDescription(
                    selectedUserLanguage,
                    pathTeamLesson.teamLesson
                  ),
                  isMd ? 100 : 250
                ),
              }}
            />
            <Box textAlign={!isDesktop ? "center" : undefined}>
              <AnimatedButton
                w={220}
                text={
                  !pathTeamLesson.isStarted
                    ? HVLocalizeStrings.TEAM_HOME_PATH_START_PATH
                    : HVLocalizeStrings.TEAM_HOME_PATH_CONTINUE
                }
                onClick={() => {
                  track(TEAM_EVENTS.ContinuePath, {
                    HV_Path_Id: selectedPath?.id,
                    HV_Path_Name: selectedPath?.name,
                    HV_Presentation_Id: pathTeamLesson.id,
                  });
                  navigate(
                    `/team/paths?pathId=${selectedPath?.id}&teamLessonId=${pathTeamLesson.teamLessonId}`
                  );
                }}
              ></AnimatedButton>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
