import { QuestionLayout } from "../../Common/QuestionLayout";
import { useContext, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useBreakpointValue } from "@chakra-ui/react";
import { AlternativeButton } from "../../Common/AlternativeButton";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { HvTextInput } from "../../Common/HvTextInput";
import { QuestionType } from "../../../Utils/PollsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
} from "@headversity/contract";

interface PollQuestionProps {
  pollQuestion: PollQuestionDto | undefined;
  pollInstanceId: number;
  isPreview?: boolean;
}

export const PollQuestion = (props: PollQuestionProps) => {
  const { pollQuestion, pollInstanceId, isPreview } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const { setPollInstanceResultToServer, currentTeam } =
    useContext<IGlobalProvider>(GlobalContext);
  const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState<
    number | null
  >(null);
  const [currentTextAnswer, setCurrentTextAnswer] = useState<
    string | undefined
  >(undefined);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const submitPollResponse = () => {
    track(TEAM_EVENTS.SubmitPollResponse, {
      HV_Team_Id: currentTeam?.id,
      HV_Team_Name: currentTeam?.name,
      HV_Poll_Session_Id: pollInstanceId,
      HV_Poll_Id: pollQuestion?.id,
      HV_Poll_InternalTitle: pollQuestion?.question.questionText,
    });
    setDisableSubmit(true);
    const pollSessionQuestionInputDto: PollQuestionSessionInputDto = {
      questionAlternativeId: 0,
      freeText: "",
    };
    if (pollQuestion?.question?.questionType === QuestionType.Single) {
      pollSessionQuestionInputDto.questionAlternativeId =
        currentSelectedAnswer as number;
      setPollInstanceResultToServer(
        pollInstanceId,
        pollSessionQuestionInputDto
      );
    } else if (pollQuestion?.question?.questionType === QuestionType.FreeText) {
      pollSessionQuestionInputDto.freeText = currentTextAnswer as string;
      setPollInstanceResultToServer(
        pollInstanceId,
        pollSessionQuestionInputDto
      );
    } else if (pollQuestion?.question?.questionType === QuestionType.Headzone) {
      /* FUTURE: re-implement headzone as generic "group nano-practice" */
    }
  };

  let content: JSX.Element[] | undefined = [<></>];
  if (
    pollQuestion?.question?.questionType === QuestionType.Single &&
    pollQuestion?.question?.questionAlternatives
  ) {
    content = pollQuestion?.question?.questionAlternatives?.map(
      (questionAlternative) => (
        <AlternativeButton
          questionId={pollQuestion.id}
          alternativeId={questionAlternative.id}
          disabled={false}
          selected={currentSelectedAnswer == questionAlternative.id}
          alternativeName={questionAlternative.alternativeText}
          setSelectedResponse={() => {
            if (!isPreview) {
              setCurrentSelectedAnswer(questionAlternative.id);
              setDisableSubmit(false);
            }
          }}
          dataTestId={`${HVTestId.PollQuestion.alternativeButton}${questionAlternative.id}`}
        />
      )
    );
  } else if (pollQuestion?.question?.questionType === QuestionType.FreeText) {
    content = [
      <>
        <HvTextInput
          isReadOnly={isPreview}
          w={isMobile ? "300px" : "md"}
          value={currentTextAnswer}
          placeholder={HVLocalizeStrings.ENTER_YOUR_RESPONSE}
          onChange={(e) => {
            setCurrentTextAnswer(e.currentTarget.value);
            setDisableSubmit(e.currentTarget.value?.trim().length === 0);
          }}
          onEnterKeyDown={() => {
            if (currentTextAnswer) {
              submitPollResponse();
            }
          }}
          dataTestId={HVTestId.PollQuestion.freeText}
        />
      </>,
    ];
  } else if (pollQuestion?.question?.questionType === QuestionType.Headzone) {
    content = [
      <>
        {/* FUTURE: re-implement headzone as generic "group nano-practice" */}
      </>,
    ];
  }
  return (
    <>
      {pollQuestion && (
        <QuestionLayout
          title={""}
          spacing={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          submitAreaContentMt={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          answerAreaContentSpacing={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          answerAreaContent={<>{content}</>}
          submitAreaContent={
            <>
              {!props.isPreview && (
                <AnimatedButton
                  disabled={disableSubmit}
                  w={150}
                  text={HVLocalizeStrings.SAVE}
                  onClick={submitPollResponse}
                  dataTestId={HVTestId.PollQuestion.submitButton}
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};
