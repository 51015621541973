import { useCallback, useState } from "react";
import {
  getPolls,
  setPollInstance,
  getPollInstanceResult,
  getPollInstances,
  setPollInstanceResult,
} from "../../Api/Team/PollsApi";
import { getKey } from "../../Utils/Helpers";
import { retryWithDelay } from "../../Api/Utils";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { Dictionary } from "highcharts";
import { AxiosResponse } from "axios";

export interface IPollProvider {
  pollQuestions: PollQuestionDto[];
  pollInstances: PollQuestionUserInstanceDto[];
  pollInstanceResults: Dictionary<QuestionUserResponseDto[] | undefined>;
  pollDataLoading: boolean;
  getPollsFromServer: (
    teamLessonId: number
  ) => Promise<AxiosResponse<PollQuestionDto[]>>;
  getPollInstanceResultFromServer: (
    pollInstanceId: number
  ) => Promise<AxiosResponse<QuestionUserResponseDto[]>>;
  getPollInstancesFromServer: () => Promise<PollQuestionUserInstanceDto[]>;
  setPollInstanceToServer: (
    pollQuestionId: number,
    teamLessonUserInstanceId: number
  ) => Promise<AxiosResponse<PollQuestionUserInstanceDto>>;
  setPollInstanceResultToServer: (
    pollInstanceId: number,
    pollQuestionSessionInputDto: PollQuestionSessionInputDto
  ) => Promise<AxiosResponse<void>>;
  clearCurrentPollsAndSessions: () => void;
  setPollDataLoading: (pollDataLoading: boolean) => void;
}

export const usePoll = (): IPollProvider => {
  const [pollQuestions, setPollQuestions] = useState<PollQuestionDto[]>([]);
  const [pollInstanceResults, setPollInstanceResults] = useState<
    Dictionary<QuestionUserResponseDto[] | undefined>
  >({});

  const [pollInstances, setPollInstances] = useState<
    PollQuestionUserInstanceDto[]
  >([]);
  const [pollDataLoading, setPollDataLoading] = useState<boolean>(false);

  const getPollsFromServer = useCallback(async (teamLessonId: number) => {
    setPollDataLoading(true);
    return getPolls(teamLessonId, getKey()).then((result) => {
      setPollDataLoading(false);
      setPollQuestions(result.data);
      return Promise.resolve(result);
    });
  }, []);

  const getPollInstanceResultFromServer = useCallback(
    async (pollInstanceId: number) => {
      setPollDataLoading(true);
      return getPollInstanceResult(pollInstanceId, getKey()).then((result) => {
        setPollDataLoading(false);
        pollInstanceResults[pollInstanceId] = result.data;
        setPollInstanceResults({ ...pollInstanceResults });
        return Promise.resolve(result);
      });
    },
    [pollInstanceResults]
  );

  const setPollInstanceToServer = useCallback(
    async (pollQuestionId: number, teamLessonUserInstanceId: number) => {
      setPollDataLoading(true);
      return setPollInstance(
        pollQuestionId,
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        pollInstanceResults[result.data.id] = result.data.questionUserResponses;
        setPollInstanceResults({ ...pollInstanceResults });
        return Promise.resolve(result);
      });
    },
    []
  );

  const getPollInstancesFromServer = useCallback(async (): Promise<
    PollQuestionUserInstanceDto[]
  > => {
    setPollDataLoading(true);
    return retryWithDelay(
      () => {
        return getPollInstances(getKey()).then((result) => {
          setPollDataLoading(false);
          setPollInstances(result.data);
          return Promise.resolve(result.data);
        });
      },
      20,
      500,
      (errorMessage: string | null) => {
        return errorMessage === "Network Error";
      }
    );
  }, []);

  const setPollInstanceResultToServer = useCallback(
    async (
      pollInstanceId: number,
      pollQuestionSessionInputDto: PollQuestionSessionInputDto
    ) => {
      setPollDataLoading(true);
      return setPollInstanceResult(
        pollInstanceId,
        pollQuestionSessionInputDto,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        getPollInstancesFromServer();
        return Promise.resolve(result);
      });
    },
    []
  );

  const clearCurrentPollsAndSessions = useCallback(() => {
    setPollQuestions([]);
    setPollInstanceResults({});
  }, []);

  return {
    pollQuestions,
    pollInstances,
    pollInstanceResults,
    getPollsFromServer,
    getPollInstanceResultFromServer,
    getPollInstancesFromServer,
    setPollInstanceToServer,
    setPollInstanceResultToServer,
    clearCurrentPollsAndSessions,
    pollDataLoading,
    setPollDataLoading,
  };
};
