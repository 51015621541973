import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CertDto, PerCertUserFeedbackReportDto } from "@headversity/contract";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  ChangeEvent,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ReachInsightsContext } from "../../../State/Reach/ReachInsightsContext";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import {
  BG_REACH_BASE,
  Border_Radius,
  DISABLED_BORDER,
  Equity_Blue,
  Evening_Purple,
  HEADER_REACH_HOME_BACKGROUND,
  REACH_USERS_FILTER_BORDER_COLOR,
  Respect_Maroon,
  White,
} from "../../../Styles/HeadversityStyle";
import { pdfExport } from "../../../Utils/PDFDownloadUtil";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HvSelect } from "../../Common/HvSelect";
import { HvSpinner } from "../../Common/HvSpinner";
import { PDFContainerCertInsightsPage } from "../PdfContainerCertInsightsPage";
import InsightsCard from "../../Common/Cards/InsightsCard";
import { InsightsCertSurveyGrowth } from "./InsightsCertSurveyGrowth";
import InsightsNoData from "./InsightsNoData";
import { InsightsNumberDisplay } from "./InsightsNumberDisplay";
import { InsightsProgressTracker } from "./InsightsProgressTracker";
import { CertSurveyGrowthData } from "./useCertReport";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import PDFFooter from "../../Shared/PDFDownload/PDFFooter";

interface CertSurveyGrowthDataWithIdx extends CertSurveyGrowthData {
  id: number;
}

const qualifiedStatusCode = 2;

const CertInsightsPage = () => {
  const pdfRef = useRef<PDFExport | null>(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL, theme } = useContext<IShellProvider>(ShellContext);
  const { getPageTitle } = useContext<IGlobalProvider>(GlobalContext);
  const { company, fetchData, companyCerts, certReport } =
    useContext(ReachInsightsContext);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [selectedCert, setSelectedCert] = useState<CertDto | undefined>(
    undefined
  );
  const [certifiedUsersCount, setCertifiedUsersCount] = useState<number>(0);
  const [inProgressUsersCount, setInProgressUsersCount] = useState<number>(0);
  const [qualifiedUsersCount, setQualifiedUsersCount] = useState<number>(0);
  const [surveyGrowthData, setSurveyGrowthData] = useState<
    CertSurveyGrowthDataWithIdx[]
  >([]);
  const [userFeedbackData, setUserFeedbackData] = useState<
    PerCertUserFeedbackReportDto[]
  >([]);
  const [lowDataSurveyGrowth, setLowDataSurveyGrowth] = useState(false);
  const [lowDataUserFeedback, setLowDataUserFeedback] = useState(false);

  useEffect(() => {
    fetchData().then(() => {
      setIsPageLoading(false);
    });
  }, [fetchData]);

  useEffect(() => {
    // set a value for cert if it's not set
    if (!selectedCert && companyCerts.length > 0) {
      setSelectedCert(companyCerts[0]);
    }
  }, [companyCerts]);

  useEffect(() => {
    // Get the data when the selectedCert changes
    if (selectedCert) {
      // per cert user feedback
      if (!certReport.perCertUserFeedback.noData) {
        const feedbacksForCert = certReport.perCertUserFeedback.report!.filter(
          (cert) => cert.cert_id === selectedCert.id
        );
        setLowDataUserFeedback(feedbacksForCert.length < 3);
        setUserFeedbackData(feedbacksForCert);
      } else {
        setLowDataUserFeedback(true);
        setUserFeedbackData([]);
      }

      // qualified users
      if (!certReport.certQualifiedUsers.noData) {
        const qualifiedCounts = certReport.certQualifiedUsers!.report!.filter(
          (cert) =>
            cert.cert_id === selectedCert.id &&
            cert.status === qualifiedStatusCode
        );
        const totalQualifiedForCert = qualifiedCounts.reduce(
          (acc, cert) => acc + cert.user_count,
          0
        );

        setQualifiedUsersCount(totalQualifiedForCert);
      } else {
        setQualifiedUsersCount(0);
      }

      // cert activity
      if (!certReport.certActivityReport.noData) {
        setCertifiedUsersCount(
          certReport.certActivityReport.report!.find(
            (cert) =>
              cert.status === "Active" && cert.cert_id === selectedCert.id
          )?.cert_count || 0
        );
        setInProgressUsersCount(
          certReport.certActivityReport.report!.find(
            (cert) =>
              cert.status === "In Progress" && cert.cert_id === selectedCert.id
          )?.cert_count || 0
        );
      } else {
        setCertifiedUsersCount(0);
        setInProgressUsersCount(0);
      }

      // survey growth
      if (!certReport.certSurveyGrowth.noData) {
        const mappedData: CertSurveyGrowthDataWithIdx[] = [];
        for (let i = 0; i < certReport.certSurveyGrowth.report!.length; i++) {
          const csg = certReport.certSurveyGrowth.report![i];
          if (csg.cert_id !== selectedCert.id) {
            continue;
          }
          mappedData.push({
            id: i + 1,
            question: csg.question as string,
            beforePercentage: csg.pre_response_metric,
            afterPercentage: csg.post_response_metric,
            changePercentage: csg.response_growth,
          });
        }
        setLowDataSurveyGrowth(mappedData.length <= 0);
        setSurveyGrowthData(mappedData);
      } else {
        setLowDataSurveyGrowth(true);
        setSurveyGrowthData([]);
      }
    }
  }, [
    selectedCert,
    certReport.certSurveyGrowth,
    certReport.certActivityReport,
    certReport.certQualifiedUsers,
    certReport.perCertUserFeedback,
  ]);

  const handleChangeCert = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCert(
      companyCerts.find((cert) => cert.id === parseInt(e.target.value))
    );
  };

  const handlePdfDownload = async () => {
    pdfExport(pdfRef, { forcePageBreak: ".page-break" });
  };

  const counterHeight = "144px";

  const certSection = (pdfExport: boolean = false) => {
    return (
      <Box
        maxW={isMainContainerXL ? "container.xl" : "container.lg"}
        mx={"auto"}
        mt={isDesktop ? "40px" : "20px"}
        mb={"20px"}
      >
        {pdfExport && (
          <Text
            fontSize="lg"
            color={theme.name === NOSTALGIC_THEME_NAME ? White : ""}
          >
            {selectedCert?.name as string}
          </Text>
        )}
        <BlockSectionTitle
          title={HVLocalizeStrings.REACH_INSIGHTS_BREAKDOWN_BY_CERT}
        ></BlockSectionTitle>
        <SimpleGrid mt={4} gap={6} columns={{ base: pdfExport ? 3 : 1, md: 3 }}>
          <InsightsCard
            title={HVLocalizeStrings.REACH_INSIGHTS_USERS_IN_PROGRESS}
            subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
            hasTooltip={true}
            tooltipTitle={
              HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_TITLE
            }
            tooltipContent={
              HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_DESCRIPTION
            }
            content={
              <InsightsNumberDisplay
                heading={inProgressUsersCount.toString()}
                headingColor={Evening_Purple}
                height={counterHeight}
                fontSize={inProgressUsersCount > 9999 ? "90px" : "105px"}
              />
            }
          />
          <InsightsCard
            title={HVLocalizeStrings.REACH_INSIGHTS_CERTIFIED_USERS}
            subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
            hasTooltip={true}
            tooltipTitle={
              HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_TITLE
            }
            tooltipContent={
              HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_DESCRIPTION
            }
            content={
              <InsightsNumberDisplay
                heading={certifiedUsersCount.toString()}
                headingColor={Evening_Purple}
                height={counterHeight}
                fontSize={certifiedUsersCount > 9999 ? "90px" : "105px"}
              />
            }
          />
          {selectedCert && !selectedCert.disableQual && (
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_QUALIFIED_USERS}
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_TO_DATE}
              hasTooltip={true}
              tooltipTitle={
                HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_TITLE
              }
              tooltipContent={
                HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_DESCRIPTION
              }
              content={
                <InsightsNumberDisplay
                  heading={qualifiedUsersCount.toString()}
                  headingColor={Evening_Purple}
                  height={counterHeight}
                  fontSize={qualifiedUsersCount > 9999 ? "90px" : "105px"}
                />
              }
            />
          )}
        </SimpleGrid>
      </Box>
    );
  };

  const userFeedbackSection = () => {
    return (
      <Box
        maxW={isMainContainerXL ? "container.xl" : "container.lg"}
        mx={"auto"}
        mt={isDesktop ? "40px" : "20px"}
        mb={"20px"}
      >
        <BlockSectionTitle
          title={HVLocalizeStrings.REACH_INSIGHTS_USER_FEEDBACK}
        ></BlockSectionTitle>
        {lowDataUserFeedback && (
          <Box mt={4}>
            <InsightsCard
              title={""}
              hideHeader={true}
              content={
                <InsightsNoData
                  description={
                    HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                  }
                />
              }
            />
          </Box>
        )}
        {!lowDataUserFeedback && (
          <Box
            border={DISABLED_BORDER}
            borderRadius={Border_Radius}
            bg={White}
            boxShadow="lg"
            mt={4}
          >
            {userFeedbackData.map((r, idx) => (
              <Box key={r.question as string} my={2}>
                {idx > 0 && (
                  <Center>
                    <Divider mx={4} />
                  </Center>
                )}
                <Flex
                  flexDir={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  py={4}
                  px={8}
                >
                  <Box w={isMobile ? "95%" : "50%"}>
                    <Text fontSize={"lg"}>{r.question as string}</Text>
                  </Box>
                  <Box w={isMobile ? "95%" : "30%"} mt={isMobile ? 4 : 0}>
                    <InsightsProgressTracker
                      percentage={parseInt(
                        (r.agree_percentage * 100.0).toFixed(0)
                      )}
                      color={Respect_Maroon}
                      showPercentage={true}
                    />
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };
  const beforeAfterSection = (
    sectionGrowthData: CertSurveyGrowthDataWithIdx[],
    hideTitle?: boolean
  ) => {
    return (
      <Box
        maxW={isMainContainerXL ? "container.xl" : "container.lg"}
        mx={"auto"}
        mt={isDesktop ? "40px" : "20px"}
        mb={"20px"}
      >
        {!hideTitle && (
          <BlockSectionTitle
            title={HVLocalizeStrings.REACH_INSIGHTS_BEFORE_AND_AFTER_TITLE}
          ></BlockSectionTitle>
        )}
        {lowDataSurveyGrowth && (
          <Box mt={4}>
            <InsightsCard
              title={""}
              hideHeader={true}
              content={
                <InsightsNoData
                  description={
                    HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                  }
                />
              }
            />
          </Box>
        )}
        {!lowDataSurveyGrowth && (
          <Box
            border={DISABLED_BORDER}
            borderRadius={Border_Radius}
            bg={White}
            boxShadow="lg"
            mt={4}
          >
            {sectionGrowthData.map((data, idx) => (
              <Box key={data.id}>
                {idx > 0 && (
                  <Center>
                    <Divider mx={4} />
                  </Center>
                )}
                <InsightsCertSurveyGrowth data={data} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  /*
    If we have more than 8 items to show in the Before and After section, we need a page break within the section.
    The PDF container expects an array of ReachNode where each node will be displayed on a separate page.
    We need to calculate the number of pages needed and slice the data accordingly.
   */

  // Calculate number of pages needed for survey growth data
  const numPages = Math.ceil((surveyGrowthData.length - 8) / 11) + 1;

  // Helper function to slice data for each page
  const sliceDataForPage = (pageIndex: number) => {
    if (pageIndex === 0) {
      // First page fits 8 items
      return surveyGrowthData.slice(0, 8);
    } else {
      // Each subsequent page fits 11 items
      const startIndex = 8 + (pageIndex - 1) * 11;
      const endIndex = Math.min(startIndex + 11, surveyGrowthData.length);
      return surveyGrowthData.slice(startIndex, endIndex);
    }
  };

  // Generate PDF content
  const pdfContent: ReactNode[] = [];
  let userFeedbackAdded = false;
  const addUserFeedbackSection = () => {
    userFeedbackAdded = true;
    return userFeedbackSection();
  };
  for (let i = 0; i < numPages; i++) {
    const beforeAfterData = sliceDataForPage(i);
    const shouldAddFeedbackSection =
      ((i === 0 && surveyGrowthData.length <= 7) ||
        (i !== 0 && beforeAfterData.length <= 6)) &&
      !userFeedbackAdded;
    const pageContent = (
      <Fragment key={`page_${i}`}>
        {i === 0 && certSection(true)}
        {beforeAfterSection(beforeAfterData, i > 0)}
        {shouldAddFeedbackSection && addUserFeedbackSection()}
        <PDFFooter />
      </Fragment>
    );
    pdfContent.push(pageContent);
  }
  if (!userFeedbackAdded) {
    pdfContent.push(
      <Fragment key="user_feedback">{userFeedbackSection()}</Fragment>
    );
  }

  const pdfStuff = (
    <PDFContainerCertInsightsPage pdfRef={pdfRef} company={company}>
      {pdfContent}
    </PDFContainerCertInsightsPage>
  );
  return (
    <>
      {pdfStuff}
      <Helmet>
        <title>{getPageTitle(`${HVLocalizeStrings.REACH_INSIGHTS}`)}</title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_INSIGHTS}
        description={HVLocalizeStrings.REACH_INSIGHTS_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>

      {isPageLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <FadeAfterDelay>
          <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
            <Flex flexDir="row" flexWrap="wrap" gap={2}>
              <Box w={isDesktop ? "400px" : "325px"} mr={"auto"}>
                <HvSelect
                  bg={"white"}
                  color={Equity_Blue}
                  borderColor={REACH_USERS_FILTER_BORDER_COLOR}
                  value={selectedCert?.id}
                  onChange={(e) => handleChangeCert(e)}
                >
                  {companyCerts.map((cert) => (
                    <option key={cert.id} value={cert.id}>
                      {cert.name + (cert.isTrial ? HVLocalizeStrings.REACH_TRIAL : "")}
                    </option>
                  ))}
                </HvSelect>
              </Box>
              {isWebPlatform() && (
                <AnimatedButton
                  colorSet={AnimatedButtonColorSet.Third}
                  text={HVLocalizeStrings.REACH_DOWNLOAD_REPORT}
                  onClick={handlePdfDownload}
                  w={"auto"}
                />
              )}
            </Flex>

            {/* Cert Section */}
            {certSection()}

            {/* Before and After Section */}
            {beforeAfterSection(surveyGrowthData)}

            {/* User Feedback Section */}
            {userFeedbackSection()}
          </Container>
        </FadeAfterDelay>
      )}
    </>
  );
};

export default CertInsightsPage;
