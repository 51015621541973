import { Button, Flex } from "@chakra-ui/react";
import {
  Border_Radius,
  BUTTON_PRIMARY_BACKGROUND_COLOR,
  BUTTON_PRIMARY_TEXT_COLOR,
  BUTTON_THIRD_HOVER_COLOR_ALT,
} from "../../Styles/HeadversityStyle";
import { LocalizationText } from "@headversity/contract";

interface AlternativeButtonProps {
  questionId: number;
  alternativeId: number;
  selected: boolean;
  disabled: boolean;
  alternativeName: string | LocalizationText;
  setSelectedResponse: (selectedResponse: {
    questionId: number;
    alternativeId: number;
  }) => void;
  dataTestId?: string;
  minH?: string;
  index?: number;
  defaultBorderColor?: string;
  selectedColor?: string;
}

export const AlternativeButton = (props: AlternativeButtonProps) => {
  const {
    questionId,
    alternativeId,
    selected,
    setSelectedResponse,
    alternativeName,
    disabled,
    dataTestId,
    minH,
    index,
    defaultBorderColor,
    selectedColor,
  } = props;

  return (
    <>
      <Button
        disabled={disabled}
        w="100%"
        maxW={"550px"}
        minH={minH ?? undefined}
        height={"auto"}
        whiteSpace={"pre-wrap"}
        bg={
          index === undefined && selected
            ? BUTTON_PRIMARY_BACKGROUND_COLOR
            : "white"
        }
        color={
          index === undefined && selected
            ? BUTTON_PRIMARY_TEXT_COLOR
            : undefined
        }
        border={index !== undefined ? "1px" : undefined}
        borderColor={selected ? selectedColor : defaultBorderColor}
        boxShadow={"lg"}
        borderRadius={Border_Radius}
        _hover={
          selected || disabled
            ? {}
            : {
                bgColor:
                  index === undefined ? BUTTON_THIRD_HOVER_COLOR_ALT : "#eee",
                color:
                  index === undefined ? BUTTON_PRIMARY_TEXT_COLOR : undefined,
              }
        }
        onClick={() => setSelectedResponse({ questionId, alternativeId })}
        key={alternativeId}
        fontWeight={"400"}
        data-testid={dataTestId}
        justifyContent={index !== undefined ? "flex-start" : undefined}
        textAlign={index !== undefined ? "left" : undefined}
        aria-selected={selected}
      >
        <>
          {index !== undefined && (
            <Flex
              h={"100%"}
              bg={selected ? selectedColor : undefined}
              color={selected ? "white" : undefined}
              align={"center"}
              justify={"center"}
              position={"absolute"}
              left={0}
              fontWeight={"600"}
              fontSize={"xl"}
              w={"65px"}
            >
              {String.fromCharCode(index + 65).toUpperCase()}
            </Flex>
          )}
          <Flex
            h={"100%"}
            textAlign={index !== undefined ? "left" : undefined}
            w={index !== undefined ? "100%" : undefined}
            flexDir={"row"}
            pl={index !== undefined ? "63px" : undefined}
            py={"20px"}
          >
            {(alternativeName as string)
              .replace(index !== undefined ? /^\((\w)\)/ : ("" as string), "")
              .trim()}
          </Flex>
        </>
      </Button>
    </>
  );
};
