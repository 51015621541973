export const toInnerText = (html: string): string => {
  const element = document.createElement("div");
  element.innerHTML = html;
  return element.textContent as string;
};

export const cleanWhiteSpaces = (text: string): string => {
  const pattern = "\\r\\n+|[\\s\\n\\t\\r\\u00a0]+";
  const replacement = " ";
  return text.replace(new RegExp(pattern, "g"), replacement).trim();
};

export const getListingCommaString = (
  items: string[],
  andString: string,
  max?: number,
  andMoreString?: string
): string => {
  if (items.length === 1) {
    return items[0];
  } else {
    const useMax = max && items.length > max;
    const take = useMax ? max : items.length;

    const text =
      items.slice(0, take - 1).join(", ") +
      (take > 2 ? "," : "") +
      " " +
      (useMax ? "" : andString + " ") +
      items[take - 1];

    return take < items.length ? `${text}, ${andMoreString}` : text;
  }
};

export const isNullOrWhitespace = (str: string | undefined | null): boolean => {
  return (
    str === null ||
    str === undefined ||
    str === "" ||
    str.match(/^ *$/) !== null
  );
};

export const stringOrEmpty = (str: string | undefined | null): string => {
  return str || "";
};

export const uuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
