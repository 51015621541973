import styled from "styled-components";
import { useContext, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Box, Flex, IconButton, Image, Link, Text } from "@chakra-ui/react";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { QRCode } from "@progress/kendo-react-barcodes";
import { translate } from "../../../Utils/TranslateUtil";
import { AiOutlineCopy } from "react-icons/ai";
import { writeToClipboard } from "../../../Utils/mobileUtils";
import { TeamLessonDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface JoinTeamQRCardProps {
  teamCode?: string;
  teamLesson?: TeamLessonDto;
  permanentMode?: string;
  showCopyOption?: boolean;
}

const QRCodeDiv = styled.div`
  .k-qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const JoinTeamQRCard = (props: JoinTeamQRCardProps) => {
  const { teamCode, teamLesson, permanentMode, showCopyOption } = props;
  const [mode, setMode] = useState(
    permanentMode ?? (teamLesson ? "Info" : "QR")
  );
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const originUrl = process.env.REACT_APP_ORIGIN as string;
  return (
    <>
      <Flex
        flexDir={"column"}
        boxShadow={"lg"}
        w={"300px"}
        h={"310px"}
        textAlign={"center"}
        p={mode === "QR" ? "3" : undefined}
        pb={mode !== "QR" ? "2" : undefined}
        gap={"3"}
        bg={"white"}
        borderRadius={"10px"}
        color={PRIMARY_TEXT_COLOR}
        overflow="auto"
      >
        {mode === "QR" && (
          <>
            <Text fontSize={"lg"} fontWeight={"semibold"} pt={"2"}>
              {HVLocalizeStrings.TEAM_JOIN_THIS_TEAM}
            </Text>
            <Flex flexDir={"column"} gap={"1"}>
              <QRCodeDiv>
                <QRCode
                  size={100}
                  value={`${originUrl}/join/${teamCode}`}
                ></QRCode>
              </QRCodeDiv>
              <Text fontSize={"md"} fontWeight={"semibold"} mb="2px">
                {HVLocalizeStrings.OR}
              </Text>
              <Flex flexDir={"row"} justify={"Center"} gap={"1"}>
                <Text
                  maxW={"250px"}
                  fontSize={originUrl?.length < 20 ? "sm" : "11px"}
                  data-testid={HVTestId.JoinTEAMQRCard.teamJoinLink}
                >{`${originUrl}/join/${teamCode}`}</Text>
                {showCopyOption && (
                  <IconButton
                    as={AiOutlineCopy}
                    cursor="pointer"
                    aria-label={"Copy URL"}
                    backgroundColor="white"
                    onClick={async () => {
                      const url = `${originUrl}/join/${teamCode}`;
                      await writeToClipboard(url);
                    }}
                    size={"xxs"}
                  />
                )}
              </Flex>
            </Flex>
            <Flex flexDir={"column"} mt={permanentMode ? "2" : undefined}>
              <Text fontSize={"lg"}>{HVLocalizeStrings.TEAM_YOUR_ID}</Text>
              <Flex
                flexDir={"row"}
                gap={"1"}
                justify={"center"}
                align={"center"}
              >
                <Text
                  textAlign={"center"}
                  fontWeight={"semibold"}
                  data-testid={HVTestId.JoinTEAMQRCard.teamID}
                >
                  {teamCode}
                </Text>
                {showCopyOption && (
                  <IconButton
                    as={AiOutlineCopy}
                    cursor="pointer"
                    aria-label={"Copy ID"}
                    backgroundColor="white"
                    onClick={async () => {
                      await writeToClipboard(teamCode);
                    }}
                    size={"xxs"}
                  />
                )}
              </Flex>
            </Flex>
          </>
        )}

        {teamLesson && mode === "Info" && (
          <>
            <Image
              src={teamLesson?.imageUrl as string}
              borderRadius={"10px"}
              alt=""
            />
            <Flex flexDir={"column"} px={"3"} my={"1"}>
              <Box>
                <Text
                  mb="2"
                  fontWeight={"normal"}
                  textAlign={"left"}
                  fontSize={"sm"}
                  lineHeight={"1.3"}
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      teamLesson,
                      selectedUserLanguage,
                      "shortDescription"
                    ),
                  }}
                />
              </Box>
            </Flex>
          </>
        )}
      </Flex>

      {teamLesson && mode === "QR" && !permanentMode && (
        <Link
          color={MODAL_PRIMARY_TEXT_COLOR}
          textDecoration={"underline"}
          onClick={() => {
            setMode("Info");
          }}
        >
          {HVLocalizeStrings.TEAM_TEAM_LESSON_INFO}
        </Link>
      )}

      {teamLesson && mode === "Info" && !permanentMode && (
        <Link
          color={MODAL_PRIMARY_TEXT_COLOR}
          textDecoration="underline"
          onClick={() => {
            setMode("QR");
          }}
        >
          {HVLocalizeStrings.TEAM_TEAM_LESSON_QR}
        </Link>
      )}
    </>
  );
};
