import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Progress,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { IconType } from "react-icons/lib";
import {
  Black,
  BLOCK_SECTION_SECONDARY_TEXT_COLOR,
  Border_Radius,
  BUTTON_DISABLE_BACKGROUND_COLOR,
  Equity_Blue,
  Invert_Filter,
  LINEAR_PROGRESS_BAR,
  Respect_Raspberry,
  White,
} from "../../../Styles/HeadversityStyle";
import { truncate } from "../../../Utils/Helpers";
import { HVTestId } from "../../../Testing/dataTestIds";
import { CompletionState, LocalizationText } from "@headversity/contract";
import { ContentBlockPointOverlay } from "./ContentBlockPointOverlay";
import { AiFillHeart } from "react-icons/ai";
import { MdInfo } from "react-icons/md";

interface ContentBlock20Props {
  iconType?: IconType;
  imageUrl: string;
  containImage?: boolean;
  typeTitle?: string;
  hoverText?: string;
  title?: string;
  subtitle?: string;
  subtitleImageUrl?: string;
  width?: string;
  height?: string;
  showFavorite?: boolean;
  doneState: CompletionState;
  onClick?: any;
  isSmall?: boolean;
  description?: string | LocalizationText;
  truncateDescriptionAtSentence?: boolean;
  titleBg?: string;
  hasBlueFilter?: boolean;
  titleColor?: string;
  points?: number;
  subtitleColor?: string;
  opaqueTitle?: boolean;
  showTypeTitle?: boolean;
  dataTestId?: string;
  isPortrait?: boolean;
  dontInvertSubtitleImage?: boolean;
  titleAreaBg?: string;
  progressValue?: number;
  icon?: any;
  progressIcon?: string;
  borderTopRadius?: string;
  showTitleAreaBackground?: boolean;
  pointsBgColor?: string;
  descriptionColor?: string;
  bannerText?: string;
  bannerBgColor?: string;
}

export const ContentBlock20 = ({
  iconType,
  imageUrl,
  containImage,
  typeTitle,
  hoverText,
  title,
  subtitle,
  subtitleImageUrl,
  width,
  height,
  doneState,
  onClick,
  isSmall,
  points,
  description,
  truncateDescriptionAtSentence,
  titleBg,
  hasBlueFilter,
  titleColor,
  subtitleColor,
  showFavorite,
  opaqueTitle,
  showTypeTitle,
  dataTestId,
  isPortrait,
  dontInvertSubtitleImage,
  titleAreaBg,
  progressValue,
  icon,
  progressIcon,
  borderTopRadius,
  showTitleAreaBackground = true,
  pointsBgColor,
  descriptionColor,
  bannerText,
  bannerBgColor,
}: ContentBlock20Props) => {
  const [isOver, setIsOver] = useState(false);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const widthToUse = width
    ? width
    : isDesktop
    ? "372px"
    : `${window.innerWidth / 2 - 28}px`;
  const heightToUse = height ? height : isDesktop ? "225px" : "160px";

  const getIconName = () => {
    if (progressIcon) {
      return progressIcon;
    }
    return doneState === 1 ? "lessonDone" : "lessonInProgress";
  };

  return (
    <Button
      w={`${widthToUse} !important`}
      className={`button-block no-shadow`}
      onClick={onClick}
      onMouseEnter={() => {
        if (isMobile || !hoverText) return;
        setIsOver(true);
      }}
      onMouseLeave={() => {
        setIsOver(false);
      }}
      data-testid={dataTestId}
    >
      {iconType && (
        <Icon
          w={"16px"}
          h={"16px"}
          as={iconType}
          position={"absolute"}
          right={"8px"}
          top={"8px"}
          zIndex={2}
          color="white"
          opacity={isOver ? 0 : 0.65}
          transition="0.2s all ease-in-out"
        />
      )}
      {bannerText && (
        <Flex
          position={"absolute"}
          justifyContent={"center"}
          alignItems={"center"}
          top={0}
          left={0}
          width={"100%"}
          height={"20%"}
          borderRadius={Border_Radius}
          borderBottomRadius={"none"}
          color={White}
          backgroundColor={bannerBgColor}
          fontSize={"14px"}
          fontWeight={"600"}
          zIndex={3}
        >
          <Icon as={MdInfo} color={White} boxSize={4} mr={"4px"} mb={"-2px"} />
          <Text
            maxW={isMobile ? "100%" : "none"}
            flexWrap={"nowrap"}
            whiteSpace={"nowrap"}
          >
            {bannerText}
          </Text>
        </Flex>
      )}
      {showFavorite && !isOver && (
        <Icon
          w={"28px"}
          h={"28px"}
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderColor: Black,
            padding: "4px",
            borderRadius: "50%",
          }}
          as={AiFillHeart}
          position={"absolute"}
          left={"8px"}
          top={"8px"}
          zIndex={2}
          color={Respect_Raspberry}
          opacity={0.8}
          transition="0.2s all ease-in-out"
          data-testid={HVTestId.ContentBlock20.favorite}
        />
      )}
      <Box
        pos="relative"
        bgColor={Equity_Blue}
        color="white"
        boxShadow="3px 3px 10px 0px #33333355"
        bgImage={imageUrl}
        bgSize={containImage ? "contain" : "cover"}
        bgRepeat="no-repeat"
        w={widthToUse}
        h={isPortrait ? "200px" : heightToUse}
        borderRadius={Border_Radius}
        borderTopRadius={borderTopRadius}
        textAlign="left"
        outline="solid 1px #ffffff22"
        outlineOffset="0px"
      >
        {icon && (
          <Flex
            position={"absolute"}
            borderRadius={Border_Radius}
            bottom={"50%"}
            left={"43%"}
            w={"60px"}
            h={"60px"}
            opacity={1}
            color="white"
            fontSize={"60px"}
          >
            {icon}
          </Flex>
        )}

        {hasBlueFilter && (
          <Box
            bgColor={Equity_Blue}
            borderRadius={Border_Radius}
            opacity={0.7}
            pos="absolute"
            height={"100%"}
            width={"100%"}
          />
        )}

        {typeTitle && hoverText && (
          <Box
            color="white"
            textAlign="left"
            display={isOver ? "inline" : "none"}
            pos="absolute"
            zIndex={2}
            p={4}
            pt={"10px"}
            userSelect={"none"}
            pointerEvents={"none"}
          >
            {showTypeTitle && (
              <>
                <Text fontSize={"13px"} fontWeight="600">
                  {typeTitle}
                </Text>
                <Divider mb={2} />
              </>
            )}
            <Text
              mr={!showTypeTitle ? 4 : undefined}
              fontSize={!isSmall ? "14px" : "13px"}
              lineHeight="1rem"
            >
              {truncate(
                hoverText,
                !isSmall ? 150 : 110,
                truncateDescriptionAtSentence
              )}
            </Text>
          </Box>
        )}

        {title && (
          <Box
            pos="absolute"
            bottom={0}
            left={0}
            textAlign="left"
            w="100%"
            overflow={"hidden"}
          >
            <TitleArea
              title={title}
              subtitle={subtitle}
              subtitleImageUrl={subtitleImageUrl}
              isDone={doneState === CompletionState.done}
              isSmall={isSmall}
              titleBg={titleBg}
              titleColor={titleColor}
              subtitleColor={subtitleColor}
              opaqueTitle={opaqueTitle}
              isPortrait={isPortrait}
              dontInvertSubtitleImage={dontInvertSubtitleImage}
              titleAreaBg={titleAreaBg}
              progressValue={progressValue}
              showTitleAreaBackground={showTitleAreaBackground}
              noMarginRight={
                !points && doneState === CompletionState.notStarted
              }
            />
            <Box>
              {points && doneState === CompletionState.notStarted && (
                <ContentBlockPointOverlay
                  points={points}
                  opacity={0.95}
                  bgColor={pointsBgColor}
                  color={subtitleColor}
                  isSmall={isPortrait}
                />
              )}
            </Box>
          </Box>
        )}

        {doneState >= CompletionState.inProgress && (
          <Box pos="absolute" bottom={"15px"} right={"12px"} zIndex={2}>
            <Image
              src={`https://cdn.headversity.com/app/resources/other/${getIconName()}.svg`}
              w={isDesktop ? "18px" : "16px"}
              filter={
                doneState === CompletionState.inProgress && !subtitleColor
                  ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%)"
                  : undefined
              }
              data-testid={
                doneState === 1
                  ? HVTestId.ContentBlock20.ProgressDoneIndicator
                  : HVTestId.ContentBlock20.inProgressIndicator
              }
              alt=""
            />
          </Box>
        )}
      </Box>
      {((typeTitle && !isDesktop && !hoverText) || description) && (
        <Box
          px={1}
          textAlign={"left"}
          color={
            descriptionColor
              ? descriptionColor
              : BLOCK_SECTION_SECONDARY_TEXT_COLOR
          }
        >
          {showTypeTitle && (
            <Text mt={"10px"} fontSize={"13px"} fontWeight="500">
              {iconType && (
                <Icon h={"12px"} mb="3px" mr={"3px"} as={iconType} />
              )}
              {typeTitle}
            </Text>
          )}

          {description && (
            <>
              {showTypeTitle && <Divider mt={1} />}
              <Text
                mt={"10px"}
                fontSize={isSmall ? "13px" : "14px"}
                lineHeight={"1.3"}
                h={isPortrait ? "80px" : undefined}
              >
                {truncate(description, 105, truncateDescriptionAtSentence)}
              </Text>
            </>
          )}
        </Box>
      )}
    </Button>
  );
};

interface TitleAreaProps {
  title: string;
  subtitle?: string;
  subtitleImageUrl?: string;
  isDone?: boolean | undefined;
  isSmall?: boolean;
  titleBg?: string;
  titleColor?: string;
  subtitleColor?: string;
  opaqueTitle?: boolean;
  isPortrait?: boolean;
  dontInvertSubtitleImage?: boolean;
  titleAreaBg?: string;
  progressValue?: number;
  showTitleAreaBackground?: boolean;
  noMarginRight?: boolean;
}

const TitleArea = ({
  title,
  subtitle,
  subtitleImageUrl,
  isDone,
  isSmall,
  titleBg,
  titleColor,
  subtitleColor,
  opaqueTitle,
  isPortrait,
  dontInvertSubtitleImage,
  titleAreaBg,
  progressValue,
  showTitleAreaBackground = true,
  noMarginRight,
}: TitleAreaProps) => {
  return (
    <>
      {progressValue !== undefined && (
        <Progress
          h={"10px"}
          w={"100%"}
          bgColor={BUTTON_DISABLE_BACKGROUND_COLOR}
          sx={{
            "& > div": {
              background: LINEAR_PROGRESS_BAR,
              transition: "all 0.5s ease-in",
            },
          }}
          value={progressValue}
        />
      )}
      <Box h={"60px"} borderBottomRadius={Border_Radius}>
        {(!isDone || titleAreaBg) && showTitleAreaBackground && (
          <Box
            pos={"absolute"}
            w={"100%"}
            h={"100%"}
            background={titleAreaBg ?? Equity_Blue}
            opacity={titleAreaBg ? undefined : isPortrait ? 0.75 : 0.6}
            borderBottomRadius={Border_Radius}
          />
        )}

        <Box
          pos={"absolute"}
          w={"100%"}
          h={"100%"}
          background={titleBg}
          opacity={opaqueTitle ? 1 : isDone ? 0 : isPortrait ? 0.5 : 0.3}
          borderBottomRadius={Border_Radius}
        />

        <Flex
          pt={progressValue !== undefined ? 3 : undefined}
          pos={"absolute"}
          bottom={"0"}
          h={"105%"}
          w={"100%"}
          justify={"center"}
          align={"flex-start"}
          flexDir={"column"}
          borderBottomRadius={Border_Radius}
          px={isSmall ? "10px" : "12px"}
        >
          <Text
            color={titleColor}
            fontWeight={"600"}
            fontSize={
              isSmall
                ? title.length >= 20
                  ? "14px"
                  : "15px"
                : title.length >= 30
                ? "15px"
                : title.length >= 20
                ? "16px"
                : "17px"
            }
            lineHeight={isSmall ? "1.1" : "0.95rem"}
            mr={noMarginRight ? undefined : isPortrait ? "40px" : "55px"}
            mt="2px"
            data-testid={HVTestId.ContentBlock20.title}
          >
            {truncate(title, 70, true)}
          </Text>

          {subtitle && (
            <Text
              color={subtitleColor ?? "white"}
              fontSize={isPortrait ? "11px" : isSmall ? "12px" : "13px"}
              lineHeight="1.2"
              mt={"3px"}
              data-testid={HVTestId.ContentBlock20.skill}
              mr="20px"
            >
              {subtitleImageUrl && (
                <Image
                  display="inline"
                  mt="-2px"
                  mr={1}
                  h={isPortrait ? "9px" : "10px"}
                  filter={!dontInvertSubtitleImage ? Invert_Filter : undefined}
                  src={subtitleImageUrl}
                  alt=""
                />
              )}
              {subtitle}
            </Text>
          )}
        </Flex>
      </Box>
    </>
  );
};
