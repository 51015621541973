import { useContext, useState } from "react";

import { Flex, Spinner, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { LargeModal } from "../../Common/LargeModal";
import { JoinTeamQRCard } from "../Shared/JoinTeamQRCard";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { HvTextInput } from "../../Common/HvTextInput";
import { TeamUserDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";
import { ShellContext } from "../../../State/ShellContext";
import { debounce } from "lodash";

interface AddTeammateModalProps {
  open: boolean;
  close: () => void;
  teamId: number;
  teamCode: string;
  teamName: string;
  showQRCode: boolean;
  memberList: TeamUserDto[];
}

export const AddTeammateModal = ({
  open,
  close,
  teamId,
  teamCode,
  teamName,
  showQRCode,
  memberList,
}: AddTeammateModalProps) => {
  const { saveTeamUserToServer, setTeamDataLoading } =
    useContext<IGlobalProvider>(GlobalContext);
  const { showToast } = useContext(ShellContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>();
  const [emailValid, setEmailValid] = useState<boolean>();

  function checkIfUserOnTeam(email: string | undefined): boolean {
    const trimmedEmail = email?.trim();
    return memberList.some((teamUser) => teamUser.user.email === trimmedEmail);
  }

  const handleOnSave = () => {
    setIsLoading(true);
    if (userEmail) {
      track(TEAM_EVENTS.AddTeamMember, {
        HV_Team_Id: teamId,
        HV_Team_Name: teamName,
      });
      if (checkIfUserOnTeam(userEmail)) {
        setIsLoading(false);
        showToast(
          true,
          "team-user-already-exists",
          HVLocalizeStrings.TEAM_ADD_MEMBER_ALREADY_EXISTS_DESCRIPTION
        );
      }
      saveTeamUserToServer(userEmail, teamId)
        .then(() => {
          setIsLoading(false);
          setUserEmail("");
          showToast(
            true,
            "team-user-add-success",
            HVLocalizeStrings.TEAM_ADD_MEMBER_SUCCESS
          );
          close();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const debouncedHandleOnSave = debounce(handleOnSave, 300, {
    leading: false,
    trailing: true,
  });

  return (
    <LargeModal
      open={open}
      setOpen={(isOpen) => {
        close();
        setUserEmail("");
        return {};
      }}
      size={showQRCode ? "sizeOnContent" : null}
    >
      <Flex
        flexDir={"column"}
        gap={10}
        align={showQRCode ? "center" : undefined}
      >
        <Text
          textDecoration={"underline solid orange"}
          textUnderlineOffset={8}
          fontSize={["lg", "xl"]}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          w={"100%"}
          textAlign={"center"}
          data-testid={HVTestId.AddTeammateModal.teamName}
        >
          {teamName}
        </Text>
        {!showQRCode && (
          <Text alignSelf={"center"} color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.TEAM_ADD_MEMBER_INSTRUCTION}
          </Text>
        )}
        {/* CARD */}
        {showQRCode ? (
          <JoinTeamQRCard teamCode={teamCode} showCopyOption></JoinTeamQRCard>
        ) : (
          <Flex flexDir={"column"} gap={4} alignItems={"center"} p={10}>
            <HvTextInput
              type="email"
              placeholder={HVLocalizeStrings.EMAIL_ADDRESS}
              value={userEmail}
              onChange={(event: any) => {
                if (
                  event.target.value.length > 0 &&
                  event.target.validity.valid
                ) {
                  setEmailValid(true);
                } else {
                  setEmailValid(false);
                }
                setUserEmail(event.target.value);
              }}
              onEnterKeyDown={() => {
                if (userEmail) {
                  debouncedHandleOnSave();
                }
              }}
            />
            {isLoading ? (
              <Spinner />
            ) : (
              <AnimatedButton
                colorSet={AnimatedButtonColorSet.Secondary}
                w={210}
                text={HVLocalizeStrings.INVITE}
                disabled={isLoading || !emailValid}
                onClick={handleOnSave}
                dataTestId={HVTestId.AddTeammateModal.submit}
              />
            )}
          </Flex>
        )}
      </Flex>
    </LargeModal>
  );
};
