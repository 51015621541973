import { Box, Flex, MenuItem, MenuList } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  CONTRAST_TEXT_COLOR,
  Glow_Shadow_Filter,
  NAV_BUTTON_SELECTED_GRADIENT,
  SIDEBAR_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppType, GlobalContext } from "../../../State/GlobalContext";

interface AppGridMenuListProps {
  setIsOpen: (open: boolean) => void;
}

export const AppGridMenuList = ({ setIsOpen }: AppGridMenuListProps) => {
  const { soloEnabled, teamEnabled, reachEnabled, certEnabled, currentApp } =
    useContext(GlobalContext);

  return (
    <MenuList
      bg={SIDEBAR_BACKGROUND_COLOR}
      color={CONTRAST_TEXT_COLOR}
      as={Flex}
      mr={2}
      width={"305px"}
      flexDir={"column"}
      gap={"2"}
      border={0}
      borderColor={"white"}
      pos={"relative"}
      filter={Glow_Shadow_Filter}
      boxShadow="lg"
    >
      {certEnabled ? (
        <AppGridMenuItem
          to={"/cert"}
          text={"CERT"}
          selected={currentApp === AppType.CERT}
          setIsOpen={setIsOpen}
        />
      ) : (
        <></>
      )}
      {soloEnabled ? (
        <AppGridMenuItem
          to={"/"}
          text={HVLocalizeStrings.APP_GRID_SOLO}
          selected={currentApp === AppType.SOLO}
          setIsOpen={setIsOpen}
        />
      ) : (
        <></>
      )}
      {teamEnabled ? (
        <AppGridMenuItem
          to={"/team"}
          text={HVLocalizeStrings.APP_GRID_TEAM}
          selected={currentApp === AppType.TEAM}
          setIsOpen={setIsOpen}
        />
      ) : (
        <></>
      )}
      {reachEnabled ? (
        <AppGridMenuItem
          to={"/reach"}
          text={HVLocalizeStrings.APP_GRID_REACH}
          selected={currentApp === AppType.REACH}
          setIsOpen={setIsOpen}
        />
      ) : (
        <></>
      )}
    </MenuList>
  );
};

interface AppGridMenuItemProps {
  to: string;
  text: string;
  selected: boolean;
  setIsOpen: (open: boolean) => void;
}

const AppGridMenuItem = (props: AppGridMenuItemProps) => {
  const { to, text, selected, setIsOpen } = props;
  return (
    <MenuItem
      bg={selected ? NAV_BUTTON_SELECTED_GRADIENT : SIDEBAR_BACKGROUND_COLOR}
      _hover={{ bg: selected ? NAV_BUTTON_SELECTED_GRADIENT : "transparent" }}
      _focus={{ bg: selected ? NAV_BUTTON_SELECTED_GRADIENT : "transparent" }}
      _active={{ bg: selected ? NAV_BUTTON_SELECTED_GRADIENT : "transparent" }}
      as={Box}
      textAlign={"center"}
      fontSize={"lg"}
      fontWeight={"bold"}
    >
      <Link
        to={to}
        style={{ width: "100%", textAlign: "center" }}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {text}
      </Link>
    </MenuItem>
  );
};
