const Opacity10Hex = "1A";
const Opacity66Hex = "A9";
const Opacity70Hex = "B3";
const Opacity80Hex = "CC";
const Opacity90Hex = "E6";
const Opacity40Hex = "66";
const Opacity30Hex = "4D";
const Opacity20Hex = "33";

// Classic colors (using https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4 to help for HEX transparency)
export const White = "#FFFFFF";
const White70 = `${White}${Opacity70Hex}`;
const White90 = `${White}${Opacity90Hex}`;
const White40 = `${White}${Opacity40Hex}`;

export const Grey = "#8A8A8B";
const Red = "#FF0000";
const Yellow = "#FAC20A";
export const Black = "#000000";
export const Black30 = `${Black}33`;
const Black70 = `${Black}${Opacity70Hex}`;

// Custom colors
const Hover_Blue = "#535D89";
const Hover_Orange = "#FEB94C";
const Hover_Red = "#FF6666";
export const Equity_Blue = "#0A1B57";
export const Equity_Blue_20 = `${Equity_Blue}${Opacity20Hex}`;
export const Equity_Blue_30 = `${Equity_Blue}${Opacity30Hex}`;
export const Equity_Blue_80 = `${Equity_Blue}${Opacity80Hex}`;
export const Equity_Blue_66 = `${Equity_Blue}${Opacity66Hex}`;
export const Equity_Orange = "#FF9900";
export const Equity_Orange_40 = `${Equity_Orange}${Opacity40Hex}`;
export const Equity_Orange_80 = `${Equity_Orange}${Opacity80Hex}`;
export const Equity_Black = "#000000";
export const Equity_Black_10 = `${Equity_Black}${Opacity10Hex}`;
export const Equity_Light_Gray = "#F4F4F4";
export const Respect_Cherry = "#AB274F";
export const Respect_Raspberry = "#CD2F5E";
const Equity_Lighter_Gray = "#FAFAFA";
const Morning_Blue = "#88CCEE";
const Morning_Blue_80 = `${Morning_Blue}${Opacity80Hex}`;
export const Azure = "#E5F5F6";
export const Ice_Blue = "#D9F1FE";
export const Moonstone = "#00A0A9";
export const Light_Lavender = "#E8EFFD";
export const Light_Blue = "#41BBFB";
export const Light_Blue_20 = `${Light_Blue}${Opacity20Hex}`;
export const Light_Blue_80 = `${Light_Blue}${Opacity80Hex}`;
export const Light_Blue_10 = `${Light_Blue}${Opacity10Hex}`;
export const Mid_Blue = "#1E64F0";
export const Mid_Blue_20 = `${Mid_Blue}${Opacity20Hex}`;
export const Mid_Blue_80 = `${Mid_Blue}${Opacity80Hex}`;
export const Mid_Blue_10 = `${Mid_Blue}${Opacity10Hex}`;
const Evening_Blue = "#0A3296";
const Evening_Blue_80 = `${Evening_Blue}${Opacity80Hex}`;
const Morning_Yellow = "#FFDC4A";
const Evening_Orange = "#E36300";
export const Evening_Pink = "#A430AD";
export const Respect_Maroon = "#660B35";
export const Respect_Maroon_20 = `${Respect_Maroon}${Opacity80Hex}`;
export const Respect_Maroon_Light = "#A7396D";
export const LinkedIn_Blue = "#296090";
const Orange = "orange";
export const Evening_Purple = "#520C84";
export const Burnt_Orange = "#C44600";
export const Disabled_Color = "#8A8A8B";
export const Disabled_Bg = "#CDCDCD";
export const Error = "#D32F2F";
export const Success = "#457d48";
const Gray = "#979797";
export const darkBluishGray = "#37435b";
export const darkBluishGraySlightlyLighter = "#525e87";
export const completeGreen = "#01C663";
const Auth0_Gray = "#F1F1F1";
export const Error_Red = "#d1534f";
const Input_Text_Color = "#5C5C5C";
const Auth0_Show_Password_Icon_Color = "#20B4FF";
const Auth0_Hidden_Password_Icon_Color = "#CCCCCC";
export const Equity_Purple = "#8114B4";
export const Equity_Purple_80 = `${Equity_Purple}${Opacity80Hex}`;
export const Border_Radius = "5px";
export const Glow_Shadow_Filter = "drop-shadow(0px 0px 1px #ffffff);";
export const SlideFade_Offset = "250px";
export const Invert_Filter =
  "invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)";
export const White_Stroke_Filter =
  "drop-shadow(1px 1px 0 #ffffff44) drop-shadow(-1px 1px 0 #ffffff44) drop-shadow(1px -1px 0 #ffffff44) drop-shadow(-1px -1px 0 #ffffff44);";
const chakraSelectBorderColor = "#E2E8F0";
export const chakraSelectBorderColorFocused = "#3C88CF";
const chakraSelectValueLightBackground = "#EDF2F7";
export const Psycho_Strength_Light_Blue = "#00B1FF";
export const Psycho_Strength_Dark_Blue = "#B3E8FF";
export const Psycho_Neutral_Light_Purple = "#C5BDCC";
export const Psycho_Neutral_Dark_Purple = "#E7DFEE";
export const Psycho_Risk_Light_Red = "#EE414B";
export const Psycho_Risk_Dark_Red = "#E8A3A7";
export const Teal_Light = "#CED1DD";

// Modal backgrounds
const ModalBg =
  "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light.jpg)";
const ModalBgDark =
  "url(https://cdn.headversity.com/app/resources/modal/modal-bg-dark.jpg)";
const TeamModalBg =
  "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light-team.jpg)";
const CertModalBg =
  "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light-cert.jpg)";
const BadgeModalBg =
  "url(https://cdn.headversity.com/app/resources/shield/swoop-regular.png)";
const ReachBg =
  "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light-reach.jpg)";

// Background images
const BaseBg = "/app/resources/bg-headers/bg-orange.jpg";
const BaseBgDark = "/app/resources/bg-headers/bg-blue.jpg";
const BaseBgPurple = "/app/resources/bg-headers/bg-purple.jpg";
const BaseBgRed = "/app/resources/bg-headers/bg-red.jpg";
const BaseBgAqua = "/app/resources/bg-headers/bg-aqua.jpg";

// Gradients
const Skill_Border_Gradient = `linear-gradient(89.95deg, ${Equity_Blue} 1.03%, ${Evening_Blue} 26.79%, ${Mid_Blue} 51.52%, ${Light_Blue} 75.74%, ${Morning_Blue} 99.96%)`;

const Block_Title_Gradient = `linear-gradient(91.29deg, ${Equity_Blue_80} 2.14%, ${Evening_Blue_80} 50.98%, ${Mid_Blue_80} 97.87%, ${Light_Blue_80} 143.78%, ${Morning_Blue_80} 189.68%)`;

const Media_Player_Gradient = `linear-gradient(0deg, ${White90}, ${White90}), ${Equity_Blue}`;
const Nav_Button_Selected_Gradient = `linear-gradient(0deg, ${White40}, ${White40}), ${Equity_Blue}`;
export const HVBlueGradient = `linear-gradient(89.95deg, ${Evening_Blue} 1.03%, ${Evening_Blue} 26.79%, ${Mid_Blue} 51.52%, ${Light_Blue} 75.74%, ${Morning_Blue} 99.96%)`;

export const HVPurpleGradient = `linear-gradient(91.29deg, #520c84CC 2.14%, #8114B4CC 50.98%, #A430ADCC 97.87%, #C84EA6CC 143.78%, #D371B8CC 189.68%)`;

export const HVOrangeGradient = `linear-gradient(89.95deg, ${Burnt_Orange} 1.03%, ${Evening_Orange} 26.79%, ${Equity_Orange} 51.52%, ${Yellow} 75.74%, ${Morning_Yellow} 99.96%)`;
// Variables
export const BG_BASE = "BG_BASE";
export const BG_TEAM_BASE = "BG_TEAM_BASE";
export const BG_REACH_BASE = "BG_REACH_BASE";
export const BG_CERT_BASE = "BG_CERT_BASE";
export const CALENDAR_BACKGROUND_COLOR = "CALENDAR_BACKGROUND_COLOR";
export const PAGE_BACKGROUND_COLOR = "PAGE_BACKGROUND_COLOR";
export const MOBILE_HEADER_BACKGROUND_COLOR = "MOBILE_HEADER_BACKGROUND_COLOR";
export const CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR =
  "CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR";
export const CARD_BACKGROUND_COLOR = "CARD_BACKGROUND_COLOR";
export const MODAL_BACKGROUND_COLOR = "MODAL_BACKGROUND_COLOR";
export const FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR =
  "FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR";

export const PRIMARY_TEXT_COLOR = "PRIMARY_TEXT_COLOR";
export const SECONDARY_TEXT_COLOR = "SECONDARY_TEXT_COLOR";
export const THIRD_TEXT_COLOR = "THIRD_TEXT_COLOR";
export const FOURTH_TEXT_COLOR = "FOURTH_TEXT_COLOR";
export const BOOST_TEXT_COLOR = "BOOST_TEXT_COLOR";
export const DESTRUCTIVE_TEXT_COLOR = "DESTRUCTIVE_TEXT_COLOR";
export const DESTRUCTIVE_SECONDARY_TEXT_COLOR =
  "DESTRUCTIVE_SECONDARY_TEXT_COLOR";

export const BLOCK_PRIMARY_TEXT_COLOR = "BLOCK_PRIMARY_TEXT_COLOR";

export const NAV_BUTTON_SELECTED_COLOR = "NAV_BUTTON_SELECTED_COLOR";

export const BUTTON_PRIMARY_TEXT_COLOR = "BUTTON_PRIMARY_TEXT_COLOR";
export const BUTTON_SECONDARY_TEXT_COLOR = "BUTTON_SECONDARY_TEXT_COLOR";
export const BUTTON_THIRD_TEXT_COLOR = "BUTTON_THIRD_TEXT_COLOR";
export const BUTTON_DISABLE_TEXT_COLOR = "BUTTON_DISABLE_TEXT_COLOR";

export const BUTTON_PRIMARY_BACKGROUND_COLOR =
  "BUTTON_PRIMARY_BACKGROUND_COLOR";
export const BUTTON_SECONDARY_BACKGROUND_COLOR =
  "BUTTON_SECONDARY_BACKGROUND_COLOR";
export const BUTTON_THIRD_BACKGROUND_COLOR = "BUTTON_THIRD_BACKGROUND_COLOR";
export const THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR =
  "BUTTON_FOURTH_BACKGROUND_COLOR";
export const THINKING_TOOL_CHOICE_2_BACKGROUND_COLOR =
  "BUTTON_FIFTH_BACKGROUND_COLOR";
export const BUTTON_DISABLE_BACKGROUND_COLOR =
  "BUTTON_DISABLE_BACKGROUND_COLOR";

export const BUTTON_PRIMARY_HOVER_COLOR = "BUTTON_PRIMARY_HOVER_COLOR";
export const BUTTON_SECONDARY_HOVER_COLOR = "BUTTON_SECONDARY_HOVER_COLOR";
export const BUTTON_THIRD_HOVER_COLOR = "BUTTON_THIRD_HOVER_COLOR";
export const BUTTON_THIRD_HOVER_COLOR_ALT = "BUTTON_THIRD_HOVER_COLOR_ALT";
export const BUTTON_FOUR_BACKGROUND_COLOR = "BUTTON_FOUR_BACKGROUND_COLOR";
export const BUTTON_FOUR_TEXT_COLOR = "BUTTON_FOUR_TEXT_COLOR";
export const BUTTON_FOUR_HOVER_COLOR = "BUTTON_FOUR_HOVER_COLOR";
export const BUTTON_FIVE_BACKGROUND_COLOR = "BUTTON_FIVE_BACKGROUND_COLOR";
export const BUTTON_FIVE_HOVER_COLOR = "BUTTON_FIVE_HOVER_COLOR";
export const BUTTON_FIVE_TEXT_COLOR = "BUTTON_FIVE_TEXT_COLOR";

export const HEADER_HOME_BACKGROUND = "HEADER_HOME_BACKGROUND";
export const HEADER_ACHIEVEMENT_BACKGROUND = "HEADER_ACHIEVEMENT_BACKGROUND";
export const HEADER_SKILLS_BACKGROUND = "HEADER_SKILLS_BACKGROUND";
export const HEADER_EXPLORE_BACKGROUND = "HEADER_EXPLORE_BACKGROUND";
export const HEADER_NANOPRACTICE_BACKGROUND = "HEADER_NANOPRACTICE_BACKGROUND";

export const HEADER_TOOL_HEADZONE_BACKGROUND =
  "HEADER_TOOL_HEADZONE_BACKGROUND";
export const HEADER_TOOL_CALMING_BACKGROUND = "HEADER_TOOL_CALMING_BACKGROUND";
export const HEADER_TOOL_FOCUS_BACKGROUND = "HEADER_TOOL_FOCUS_BACKGROUND";
export const HEADER_TOOL_OPTIMISM_BACKGROUND =
  "HEADER_TOOL_OPTIMISM_BACKGROUND";
export const HEADER_TOOL_THINKING_BACKGROUND =
  "HEADER_TOOL_THINKING_BACKGROUND";
export const HEADER_TOOL_MOVE_BACKGROUND = "HEADER_TOOL_MOVE_BACKGROUND";
export const HEADER_TOOL_MINDFULNESS_BACKGROUND =
  "HEADER_TOOL_MINDFULNESS_BACKGROUND";
export const HEADER_REACH_HOME_BACKGROUND = "HEADER_REACH_HOME_BACKGROUND";
export const HEADER_REACH_CALENDAR_BACKGROUND =
  "HEADER_REACH_CALENDAR_BACKGROUND";
export const HEADER_REACH_PROMO_BACKGROUND = "HEADER_REACH_PROMO_BACKGROUND";

export const HEADER_LEARN_BACKGROUND = "HEADER_LEARN_BACKGROUND";
export const HEADER_PRACTICE_BACKGROUND = "HEADER_PRACTICE_BACKGROUND";
export const HEADER_LIVE_SESSIONS_BACKGROUND =
  "HEADER_LIVE_SESSIONS_BACKGROUND";
export const HEADER_RESILIENCE_FEED_BACKGROUND =
  "HEADER_RESILIENCE_FEED_BACKGROUND";
export const HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND =
  "HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND";
export const HEADER_TEAM_HOME_BACKGROUND = "HEADER_TEAM_HOME_BACKGROUND";
export const HEADER_TEAM_PATHS_BACKGROUND = "HEADER_TEAM_PATHS_BACKGROUND";
export const HEADER_TEAM_INSIGHTS_BACKGROUND =
  "HEADER_TEAM_INSIGHTS_BACKGROUND";
export const HEADER_TEAM_MANAGEMENT_BACKGROUND =
  "HEADER_TEAM_MANAGEMENT_BACKGROUND";
export const MEDIA_PLAYER_BACKGROUND_COLOR = "MEDIA_PLAYER_BACKGROUND_COLOR";
export const SKILL_BORDER_GRADIENT = "SKILL_BORDER_GRADIENT";
export const BLOCK_TITLE_GRADIENT = "BLOCK_TITLE_GRADIENT";

export const BLOCK_SECTION_TITLE_BORDER_BOTTOM =
  "BLOCK_SECTION_TITLE_BORDER_BOTTOM";
export const BLOCK_SECTION_DIVIDER_BORDER = "BLOCK_SECTION_DIVIDER_BORDER";
export const BUTTON_THIRD_BORDER = "BUTTON_THIRD_BORDER";
export const AVATAR_BORDER = "AVATAR_BORDER";
export const LENGTH_BUTTON_BORDER = "LENGTH_BUTTON_BORDER";
export const CLOSE_BUTTON_BORDER = "CLOSE_BUTTON_BORDER";
export const SKILL_CIRCULAR_PROGRESS_TRACKING_COLOR =
  "SKILL_CIRCULAR_PROGRESS_TRACKING_COLOR";
export const OVERLAY_BACKGROUND_COLOR = "OVERLAY_BACKGROUND_COLOR";
export const SKILL_PANEL_BACKGROUND_COLOR = "SKILL_PANEL_BACKGROUND_COLOR";
export const SKILL20_PANEL_BACKGROUND_COLOR = "SKILL20_PANEL_BACKGROUND_COLOR";
export const NOTIFICATION_BACKGROUND_COLOR = "NOTIFICATION_BACKGROUND_COLOR";
export const NAV_BUTTON_SELECTED_GRADIENT = "NAV_BUTTON_SELECTED_GRADIENT";
export const NAV_SELECTED_BORDER = "NAV_SELECTED_BORDER";
export const INTRO_SLIDE_PAGINATION_COLOR = "INTRO_SLIDE_PAGINATION_COLOR";
export const INTRO_SLIDE_DEFAULT_BG_COLOR = "INTRO_SLIDE_DEFAULT_BG_COLOR";
export const MODAL_CLOSE_BUTTON = "MODAL_CLOSE_BUTTON";
export const MODAL_PRIMARY_TEXT_COLOR = "MODAL_PRIMARY_TEXT_COLOR";
export const MODAL_SECONDARY_TEXT_COLOR = "MODAL_SECONDARY_TEXT_COLOR";
export const MODAL_THIRD_TEXT_COLOR = "MODAL_THIRD_TEXT_COLOR";
export const MODAL_SECONDARY_BACKGROUND_COLOR =
  "MODAL_SECONDARY_BACKGROUND_COLOR";
export const MODAL_PROGRESS_TEXT_COLOR = "MODAL_PROGRESS_TEXT_COLOR";
export const MODAL_BORDER = "MODAL_BORDER";
export const MODAL_TEXT_HIGHTLIGHT_BACKGROUND_COLOR =
  "MODAL_TEXT_HIGHTLIGHT_BACKGROUND_COLOR";
export const MODAL_TEXT_HIGHTLIGHT_PADDING = "MODAL_TEXT_HIGHTLIGHT_PADDING";
export const CONTRAST_TEXT_COLOR = "CONTRAST_TEXT_COLOR";
export const SWITCH_SECONDARY_COLOR = "SWITCH_SECONDARY_COLOR";
export const BACK_BUTTON_COLOR = "BACK_BUTTON_COLOR";
export const BLOCK_SECTION_PRIMARY_TEXT_COLOR =
  "BLOCK_SECTION_PRIMARY_TEXT_COLOR";
export const BLOCK_SECTION_SECONDARY_TEXT_COLOR =
  "BLOCK_SECTION_SECONDARY_TEXT_COLOR";
export const LINEAR_PROGRESS_BAR = "LINEAR_PROGRESS_BAR";
export const SIDEBAR_BACKGROUND_COLOR = "SIDEBAR_BACKGROUND_COLOR";
export const MINDFULNESS_TH_COLOR = "MINDFULNESS_TH_COLOR";
export const MINDFULNESS_AUDIO_LIST_BORDER_COLOR =
  "MINDFULNESS_AUDIO_LIST_BORDER_COLOR";
export const STAT_CARD_SECONDARY_COLOR = "STAT_CARD_SECONDARY_COLOR";
export const TOOL_START_BUTTON_BACKGROUND = "TOOL_START_BUTTON_BACKGROUND";
export const TOOL_START_BUTTON_TEXT = "TOOL_START_BUTTON_TEXT";
export const LESSON_COMPLETED_TEXT = "LESSON_COMPLETED_TEXT";

export const INPUT_PRIMARY_TEXT_COLOR = "INPUT_PRIMARY_TEXT_COLOR";
export const INPUT_BACKGROUND_COLOR = "INPUT_BACKGROUND_COLOR";
export const CHART_AXIS_COLOR = "CHART_AXIS_COLOR";
export const CHART_LINE_COLOR = "CHART_LINE_COLOR";
export const CARD_SECONDARY_BACKGROUND = "CARD_SECONDARY_BACKGROUND";
export const TEAM_LESSON_TODO_GRADIENT = "TEAM_LESSON_TODO_GRADIENT";
export const TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR =
  "TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR";
export const TEAM_LESSON_TODO_TEXT_COLOR = "TEAM_LESSON_TODO_TEXT_COLOR";
export const DELETE_BUTTON_BG_COLOR = "DELETE_BUTTON_BG_COLOR";
export const HV_SPINNER_COLOR = "HV_SPINNER_COLOR";
export const IMAGE_FILTER = "IMAGE_FILTER";
export const MODAL_BACKGROUND = "MODAL_BACKGROUND";
export const CERT_MODAL_BACKGROUND = "CERT_MODAL_BACKGROUND";
export const TEAM_MODAL_BACKGROUND = "TEAM_MODAL_BACKGROUND";
export const BADGE_MODAL_BACKGROUND = "BADGE_MODAL_BACKGROUND";
export const REACH_MODAL_BACKGROUND = "REACH_MODAL_BACKGROUND";
export const TEAM_SELECTEDTEAM_BORDER = "TEAM_SELECTEDTEAM_BORDER";

export const LOADING_FIRST_BACKGROUND_COLOR = "LOADING_FIRST_BACKGROUND_COLOR";
export const LOADING_SECOND_BACKGROUND_COLOR =
  "LOADING_SECOND_BACKGROUND_COLOR";
export const LOADING_THIRD_BACKGROUND_COLOR = "LOADING_THIRD_BACKGROUND_COLOR";
export const UPNEXT_ICON_COLOR = "UPNEXT_ICON_COLOR";

export const AUTH0_SHOW_PASSWORD_ICON_COLOR = "AUTH0_SHOW_PASSWORD_ICON_COLOR";
export const AUTH0_HIDDEN_PASSWORD_ICON_COLOR =
  "AUTH0_HIDDEN_PASSWORD_ICON_COLOR";
export const FORM_HEADER_BACKGROUND_COLOR = "FORM_HEADER_BACKGROUND_COLOR";
export const INPUT_TEXT_COLOR = "INPUT_TEXT_COLOR";
export const ERROR_TEXT_COLOR = "ERROR_TEXT_COLOR";
export const SHIELD_LEVEL_BORDER = "SHIELD_LEVEL_BORDER";
export const SHIELD_MODAL_PRIMARY_TEXT_COLOR =
  "SHIELD_MODAL_PRIMARY_TEXT_COLOR";
export const SHIELD_MODAL_SECONDARY_TEXT_COLOR =
  "SHIELD_MODAL_SECONDARY_TEXT_COLOR";
export const STATS_NUMBER_COLOR = "STATS_NUMBER_COLOR";

export const CERT_HOME_BACKGROUND = "CERT_HOME_BACKGROUND";
export const SELECT_BORDER = "SELECT_BORDER";
export const SELECT_BORDER_FOCUSED = "SELECT_BORDER_FOCUSED";
export const SELECT_VALUE_BACKGROUND = "SELECT_VALUE_BACKGROUND";

export const DISABLED_BORDER = "DISABLED_BORDER";
export const REACH_USERS_FILTER_BORDER = "REACH_USERS_FILTER_BORDER";
export const REACH_INSIGHTS_TEXT_COLOR = "REACH_INSIGHTS_TEXT_COLOR";
export const REACH_USERS_FILTER_BORDER_COLOR =
  "REACH_USERS_FILTER_BORDER_COLOR";

export const MORE_FOR_YOU_BACKGROUND_COLOR = "MORE_FOR_YOU_BACKGROUND_COLOR";

export const CHIP_BACKGROUND_COLOR_ON = "CHIP_BACKGROUND_COLOR_ON";
export const CHIP_FOREGROUND_COLOR_ON = "CHIP_FOREGROUND_COLOR_ON";
export const CHIP_BACKGROUND_COLOR_OFF = "CHIP_BACKGROUND_COLOR_OFF";
export const CHIP_FOREGROUND_COLOR_OFF = "CHIP_FOREGROUND_COLOR_OFF";

// headers
export const HEADER_BASE_BACKGROUND_PATH =
  "https://cdn.headversity.com/app/bg-headers";
interface HeaderImage {
  [key: string]: string;
}
export const HeaderImages: HeaderImage = {};

HeaderImages[
  HEADER_HOME_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/home.png`;
HeaderImages[
  HEADER_LEARN_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/lessons.png`;
HeaderImages[
  HEADER_PRACTICE_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/tools.png`;
HeaderImages[
  HEADER_ACHIEVEMENT_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/achievements.png`;
HeaderImages[
  HEADER_LIVE_SESSIONS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/live-sessions.png`;
HeaderImages[
  HEADER_RESILIENCE_FEED_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/resilience-feed.png`;
HeaderImages[
  HEADER_SKILLS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/skills.png`;
HeaderImages[
  HEADER_EXPLORE_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/explore.png`;
HeaderImages[
  HEADER_NANOPRACTICE_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/solo/nano-practices.png`;

HeaderImages[
  HEADER_TOOL_HEADZONE_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/headzone.png`;
HeaderImages[
  HEADER_TOOL_CALMING_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/calming.png`;
HeaderImages[
  HEADER_TOOL_FOCUS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/focus.png`;
HeaderImages[
  HEADER_TOOL_OPTIMISM_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/optimism.png`;
HeaderImages[
  HEADER_TOOL_THINKING_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/thinking.png`;
HeaderImages[
  HEADER_TOOL_MOVE_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/move.png`;
HeaderImages[
  HEADER_TOOL_MINDFULNESS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/tools/mindfulness.png`;

HeaderImages[
  HEADER_TEAM_HOME_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/team/home.png`;
HeaderImages[
  HEADER_TEAM_PATHS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/team/paths.png`;
HeaderImages[
  HEADER_TEAM_INSIGHTS_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/team/insights.png`;
HeaderImages[
  HEADER_TEAM_MANAGEMENT_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/team/teams.png`;

HeaderImages[
  HEADER_REACH_HOME_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/reach/home.png`;
HeaderImages[
  HEADER_REACH_CALENDAR_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/reach/calendar.png`;
HeaderImages[
  HEADER_REACH_PROMO_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/reach/all-assets.png`;

HeaderImages[
  CERT_HOME_BACKGROUND
] = `${HEADER_BASE_BACKGROUND_PATH}/reach/home.png`; // TODO CERT

// theme
export const HVDefaultTheme = {
  fonts: {
    heading: "Epilogue",
    body: "Barlow",
  },
  colors: {
    // Buttons, Switches, Checkboxes Etc...
    [BUTTON_PRIMARY_BACKGROUND_COLOR]: Equity_Blue,
    [BUTTON_PRIMARY_TEXT_COLOR]: White,
    [BUTTON_PRIMARY_HOVER_COLOR]: Hover_Blue,
    [BUTTON_SECONDARY_BACKGROUND_COLOR]: Equity_Orange,
    [BUTTON_SECONDARY_TEXT_COLOR]: Equity_Blue,
    [BUTTON_SECONDARY_HOVER_COLOR]: Hover_Orange,
    [BUTTON_THIRD_BACKGROUND_COLOR]: White,
    [BUTTON_THIRD_TEXT_COLOR]: Equity_Blue,
    [BUTTON_THIRD_HOVER_COLOR]: Equity_Blue_30,
    [BUTTON_THIRD_HOVER_COLOR_ALT]: Hover_Blue,

    [BUTTON_FOUR_BACKGROUND_COLOR]: Equity_Blue,
    [BUTTON_FOUR_TEXT_COLOR]: White,
    [BUTTON_FOUR_HOVER_COLOR]: Hover_Blue,
    [BUTTON_FIVE_BACKGROUND_COLOR]: Red,
    [BUTTON_FIVE_HOVER_COLOR]: Hover_Red,
    [BUTTON_FIVE_TEXT_COLOR]: White,
    [TOOL_START_BUTTON_BACKGROUND]: Equity_Blue,
    [TOOL_START_BUTTON_TEXT]: White,

    [BUTTON_DISABLE_TEXT_COLOR]: Disabled_Color,
    [BUTTON_DISABLE_BACKGROUND_COLOR]: Disabled_Bg,
    [NAV_BUTTON_SELECTED_COLOR]: Equity_Orange,
    [NAV_BUTTON_SELECTED_GRADIENT]: Nav_Button_Selected_Gradient,
    [SWITCH_SECONDARY_COLOR]: Evening_Orange,
    [BACK_BUTTON_COLOR]: Equity_Black,
    [SELECT_VALUE_BACKGROUND]: chakraSelectValueLightBackground,

    // Modals, Cards Etc...
    [MODAL_PRIMARY_TEXT_COLOR]: Equity_Blue,
    [MODAL_SECONDARY_TEXT_COLOR]: Equity_Black,
    [MODAL_THIRD_TEXT_COLOR]: Mid_Blue,
    [MODAL_BACKGROUND_COLOR]: White,
    [MODAL_SECONDARY_BACKGROUND_COLOR]: White,
    [MODAL_PROGRESS_TEXT_COLOR]: Equity_Black,
    [MODAL_CLOSE_BUTTON]: Equity_Black,
    [MODAL_TEXT_HIGHTLIGHT_PADDING]: "16px",
    [MODAL_TEXT_HIGHTLIGHT_BACKGROUND_COLOR]: White40,
    [FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR]: Burnt_Orange,
    [CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR]: Equity_Light_Gray,
    [CARD_BACKGROUND_COLOR]: White,
    [CARD_SECONDARY_BACKGROUND]: White,
    [STAT_CARD_SECONDARY_COLOR]: Equity_Black,
    [INPUT_PRIMARY_TEXT_COLOR]: Equity_Blue,
    [INPUT_BACKGROUND_COLOR]: White,
    [CHART_AXIS_COLOR]: "#666666",
    [CHART_LINE_COLOR]: Equity_Blue,
    [SHIELD_MODAL_PRIMARY_TEXT_COLOR]: Equity_Blue,
    [SHIELD_MODAL_SECONDARY_TEXT_COLOR]: Equity_Blue,
    [STATS_NUMBER_COLOR]: Equity_Blue,

    // Tools
    [THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR]: Mid_Blue,
    [THINKING_TOOL_CHOICE_2_BACKGROUND_COLOR]: Light_Blue,

    // MISC
    [BG_BASE]: BaseBg,
    [BG_TEAM_BASE]: BaseBgAqua,
    [BG_REACH_BASE]: BaseBgPurple,
    [BG_CERT_BASE]: BaseBgRed,
    [BLOCK_SECTION_SECONDARY_TEXT_COLOR]: Equity_Black,
    [BLOCK_SECTION_PRIMARY_TEXT_COLOR]: Equity_Blue,
    [BOOST_TEXT_COLOR]: Success,
    [BLOCK_PRIMARY_TEXT_COLOR]: Equity_Orange,
    [PAGE_BACKGROUND_COLOR]: Equity_Lighter_Gray,
    [MOBILE_HEADER_BACKGROUND_COLOR]: Equity_Light_Gray,
    [PRIMARY_TEXT_COLOR]: Equity_Blue,
    [SECONDARY_TEXT_COLOR]: Equity_Black,
    [THIRD_TEXT_COLOR]: Mid_Blue,
    [FOURTH_TEXT_COLOR]: Orange,
    [DESTRUCTIVE_TEXT_COLOR]: Error,
    [DESTRUCTIVE_SECONDARY_TEXT_COLOR]: Error,
    [HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND]: White70,
    [MODAL_BACKGROUND]: ModalBg,
    [TEAM_MODAL_BACKGROUND]: TeamModalBg,
    [CERT_MODAL_BACKGROUND]: CertModalBg,
    [BADGE_MODAL_BACKGROUND]: BadgeModalBg,
    [REACH_MODAL_BACKGROUND]: ReachBg,
    [TEAM_SELECTEDTEAM_BORDER]: `solid 1px ${Equity_Orange}`,
    [SKILL_BORDER_GRADIENT]: Skill_Border_Gradient,
    [BLOCK_TITLE_GRADIENT]: Block_Title_Gradient,
    [SKILL_CIRCULAR_PROGRESS_TRACKING_COLOR]: Equity_Blue_30,
    [MEDIA_PLAYER_BACKGROUND_COLOR]: Media_Player_Gradient,
    [OVERLAY_BACKGROUND_COLOR]: Black70,
    [SKILL_PANEL_BACKGROUND_COLOR]: White70,
    [SKILL20_PANEL_BACKGROUND_COLOR]: `${Equity_Blue}0A`,
    [NOTIFICATION_BACKGROUND_COLOR]: Red,
    [INTRO_SLIDE_PAGINATION_COLOR]: Gray,
    [INTRO_SLIDE_DEFAULT_BG_COLOR]: Equity_Blue,
    [LINEAR_PROGRESS_BAR]: HVBlueGradient,
    [CONTRAST_TEXT_COLOR]: White,
    [SIDEBAR_BACKGROUND_COLOR]: darkBluishGraySlightlyLighter,
    [MINDFULNESS_TH_COLOR]: Grey,
    [LESSON_COMPLETED_TEXT]: "green",
    [TEAM_LESSON_TODO_GRADIENT]: HVPurpleGradient,
    [TEAM_LESSON_TODO_TEXT_COLOR]: Mid_Blue,
    [TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR]: Mid_Blue,
    [DELETE_BUTTON_BG_COLOR]: Error,
    [HV_SPINNER_COLOR]: Evening_Blue,
    [IMAGE_FILTER]: "none",
    [LOADING_FIRST_BACKGROUND_COLOR]: Evening_Blue,
    [LOADING_SECOND_BACKGROUND_COLOR]: Mid_Blue,
    [LOADING_THIRD_BACKGROUND_COLOR]: Light_Blue,
    [UPNEXT_ICON_COLOR]: Mid_Blue,
    [SHIELD_LEVEL_BORDER]: `2px solid #FAC20A`,
    [REACH_USERS_FILTER_BORDER_COLOR]: Black,
    [REACH_INSIGHTS_TEXT_COLOR]: Black,
    [CHIP_BACKGROUND_COLOR_ON]: darkBluishGraySlightlyLighter,
    [CHIP_FOREGROUND_COLOR_ON]: "#fff",
    [CHIP_BACKGROUND_COLOR_OFF]: "#ddd",
    [CHIP_FOREGROUND_COLOR_OFF]: darkBluishGraySlightlyLighter,

    // LOGIN
    [AUTH0_SHOW_PASSWORD_ICON_COLOR]: Auth0_Show_Password_Icon_Color,
    [AUTH0_HIDDEN_PASSWORD_ICON_COLOR]: Auth0_Hidden_Password_Icon_Color,
    [ERROR_TEXT_COLOR]: Error_Red,
    [INPUT_TEXT_COLOR]: Input_Text_Color,
    [FORM_HEADER_BACKGROUND_COLOR]: Auth0_Gray,

    [MORE_FOR_YOU_BACKGROUND_COLOR]: "#ffffff66",
  },
  borders: {
    [NAV_SELECTED_BORDER]: `solid 6px ${Equity_Orange}`,
    [BLOCK_SECTION_TITLE_BORDER_BOTTOM]: `solid 1px ${Equity_Orange}`,
    [BLOCK_SECTION_DIVIDER_BORDER]: `solid 1px ${Equity_Orange}`,
    [BUTTON_THIRD_BORDER]: `solid 1px ${Equity_Blue}`,
    [AVATAR_BORDER]: `solid 1px ${White}`,
    [CLOSE_BUTTON_BORDER]: `solid ${Black} 2px`,
    [LENGTH_BUTTON_BORDER]: `solid ${Black} 1px`,
    [MINDFULNESS_AUDIO_LIST_BORDER_COLOR]: Grey,
    [MODAL_BORDER]: `solid ${Light_Blue} 1.16px`,
    [SELECT_BORDER]: `solid ${chakraSelectBorderColor} 1px`,
    [SELECT_BORDER_FOCUSED]: `solid ${chakraSelectBorderColorFocused} 1px`,
    [DISABLED_BORDER]: `1px solid ${Disabled_Bg}`,
    [REACH_USERS_FILTER_BORDER]: `1px solid ${Black}`,
  },
};

export const HVNostalgicTheme = {
  fonts: {
    heading: "Epilogue",
    body: "Barlow",
  },
  colors: {
    // Buttons, Switches, Checkboxes Etc...
    [BUTTON_PRIMARY_BACKGROUND_COLOR]: Equity_Blue,
    [BUTTON_PRIMARY_TEXT_COLOR]: White,
    [BUTTON_PRIMARY_HOVER_COLOR]: Hover_Blue,
    [BUTTON_SECONDARY_BACKGROUND_COLOR]: Equity_Orange,
    [BUTTON_SECONDARY_TEXT_COLOR]: Equity_Blue,
    [BUTTON_SECONDARY_HOVER_COLOR]: Hover_Orange,
    [BUTTON_THIRD_BACKGROUND_COLOR]: White,
    [BUTTON_THIRD_TEXT_COLOR]: Equity_Blue,
    [BUTTON_THIRD_HOVER_COLOR]: Equity_Blue_30,
    [BUTTON_THIRD_HOVER_COLOR_ALT]: Hover_Blue,

    [BUTTON_FOUR_BACKGROUND_COLOR]: Equity_Orange,
    [BUTTON_FOUR_TEXT_COLOR]: Equity_Blue,
    [BUTTON_FOUR_HOVER_COLOR]: Hover_Orange,
    [BUTTON_FIVE_BACKGROUND_COLOR]: Red,
    [BUTTON_FIVE_HOVER_COLOR]: Hover_Red,
    [BUTTON_FIVE_TEXT_COLOR]: White,
    [TOOL_START_BUTTON_BACKGROUND]: Equity_Orange,
    [TOOL_START_BUTTON_TEXT]: Equity_Blue,

    [BUTTON_DISABLE_TEXT_COLOR]: Disabled_Color,
    [BUTTON_DISABLE_BACKGROUND_COLOR]: Disabled_Bg,
    [NAV_BUTTON_SELECTED_COLOR]: Equity_Orange,
    [NAV_BUTTON_SELECTED_GRADIENT]: Nav_Button_Selected_Gradient,
    [SWITCH_SECONDARY_COLOR]: Evening_Orange,
    [BACK_BUTTON_COLOR]: White,
    [SELECT_VALUE_BACKGROUND]: Input_Text_Color,

    // Modals, Cards Etc...
    [MODAL_PRIMARY_TEXT_COLOR]: White,
    [MODAL_SECONDARY_TEXT_COLOR]: White,
    [MODAL_THIRD_TEXT_COLOR]: Equity_Orange,
    [MODAL_BACKGROUND_COLOR]: Equity_Blue,
    [MODAL_SECONDARY_BACKGROUND_COLOR]: White,
    [MODAL_PROGRESS_TEXT_COLOR]: White,
    [MODAL_CLOSE_BUTTON]: White,
    [MODAL_TEXT_HIGHTLIGHT_PADDING]: 0,
    [MODAL_TEXT_HIGHTLIGHT_BACKGROUND_COLOR]: undefined,
    [FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR]: Equity_Blue,
    [CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR]: Equity_Light_Gray,
    [CARD_BACKGROUND_COLOR]: White,
    [CARD_SECONDARY_BACKGROUND]: darkBluishGray,
    [SHIELD_MODAL_PRIMARY_TEXT_COLOR]: Equity_Blue,
    [SHIELD_MODAL_SECONDARY_TEXT_COLOR]: Equity_Blue,
    [STATS_NUMBER_COLOR]: Equity_Blue,

    [STAT_CARD_SECONDARY_COLOR]: White,
    [INPUT_PRIMARY_TEXT_COLOR]: Equity_Orange,
    [INPUT_BACKGROUND_COLOR]: darkBluishGray,
    [CHART_AXIS_COLOR]: White,
    [CHART_LINE_COLOR]: Equity_Orange_80,

    // Tools
    [THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR]: Mid_Blue,
    [THINKING_TOOL_CHOICE_2_BACKGROUND_COLOR]: Light_Blue,

    // MISC
    [BG_BASE]: BaseBgDark,
    [BG_TEAM_BASE]: BaseBgDark,
    [BG_REACH_BASE]: BaseBgDark,
    [BG_CERT_BASE]: BaseBgDark,
    [PAGE_BACKGROUND_COLOR]: Equity_Blue,
    [MOBILE_HEADER_BACKGROUND_COLOR]: Equity_Light_Gray,
    [PRIMARY_TEXT_COLOR]: Equity_Blue,
    [SECONDARY_TEXT_COLOR]: Equity_Black,
    [THIRD_TEXT_COLOR]: Mid_Blue,
    [FOURTH_TEXT_COLOR]: Orange,
    [BOOST_TEXT_COLOR]: Success,
    [DESTRUCTIVE_TEXT_COLOR]: Error,
    [DESTRUCTIVE_SECONDARY_TEXT_COLOR]: "#FDE2E2",
    [BLOCK_PRIMARY_TEXT_COLOR]: Equity_Orange,
    [BLOCK_SECTION_PRIMARY_TEXT_COLOR]: White,
    [BLOCK_SECTION_SECONDARY_TEXT_COLOR]: White,
    [HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND]: White,
    [MODAL_BACKGROUND]: ModalBgDark,
    [TEAM_MODAL_BACKGROUND]: ModalBgDark,
    [CERT_MODAL_BACKGROUND]: ModalBgDark,
    [BADGE_MODAL_BACKGROUND]: BadgeModalBg,
    [REACH_MODAL_BACKGROUND]: ModalBgDark,
    [TEAM_SELECTEDTEAM_BORDER]: `solid 3px ${Burnt_Orange}`,
    [SKILL_BORDER_GRADIENT]: Skill_Border_Gradient,
    [BLOCK_TITLE_GRADIENT]: Block_Title_Gradient,
    [SKILL_CIRCULAR_PROGRESS_TRACKING_COLOR]: Equity_Blue_30,
    [MEDIA_PLAYER_BACKGROUND_COLOR]: Media_Player_Gradient,
    [OVERLAY_BACKGROUND_COLOR]: Black70,
    [SKILL_PANEL_BACKGROUND_COLOR]: White70,
    [SKILL20_PANEL_BACKGROUND_COLOR]: `${White}33`,
    [NOTIFICATION_BACKGROUND_COLOR]: Red,
    [INTRO_SLIDE_PAGINATION_COLOR]: Gray,
    [INTRO_SLIDE_DEFAULT_BG_COLOR]: Equity_Blue,
    [LINEAR_PROGRESS_BAR]: HVBlueGradient,
    [CONTRAST_TEXT_COLOR]: White,
    [SIDEBAR_BACKGROUND_COLOR]: darkBluishGray,
    [MINDFULNESS_TH_COLOR]: Grey,
    [LESSON_COMPLETED_TEXT]: completeGreen,
    [TEAM_LESSON_TODO_GRADIENT]: HVPurpleGradient,
    [TEAM_LESSON_TODO_TEXT_COLOR]: Mid_Blue,
    [TEAM_LESSON_TODO_TEXT_CONTRAST_COLOR]: Equity_Orange,
    [DELETE_BUTTON_BG_COLOR]: Error,
    [HV_SPINNER_COLOR]: Equity_Orange,
    [IMAGE_FILTER]: Invert_Filter,
    [LOADING_FIRST_BACKGROUND_COLOR]: Yellow,
    [LOADING_SECOND_BACKGROUND_COLOR]: Equity_Orange,
    [LOADING_THIRD_BACKGROUND_COLOR]: Evening_Orange,
    [UPNEXT_ICON_COLOR]: White,
    [SHIELD_LEVEL_BORDER]: Yellow,
    [REACH_USERS_FILTER_BORDER_COLOR]: White,
    [REACH_INSIGHTS_TEXT_COLOR]: White,
    [CHIP_BACKGROUND_COLOR_ON]: "#ddd",
    [CHIP_FOREGROUND_COLOR_ON]: darkBluishGray,
    [CHIP_BACKGROUND_COLOR_OFF]: darkBluishGray,
    [CHIP_FOREGROUND_COLOR_OFF]: "#fff",

    // LOGIN
    [AUTH0_SHOW_PASSWORD_ICON_COLOR]: Auth0_Show_Password_Icon_Color,
    [AUTH0_HIDDEN_PASSWORD_ICON_COLOR]: Auth0_Hidden_Password_Icon_Color,
    [ERROR_TEXT_COLOR]: Error_Red,
    [INPUT_TEXT_COLOR]: Input_Text_Color,
    [FORM_HEADER_BACKGROUND_COLOR]: Auth0_Gray,

    [MORE_FOR_YOU_BACKGROUND_COLOR]: "#1d3470",
  },
  borders: {
    [NAV_SELECTED_BORDER]: `solid 6px ${Equity_Orange}`,
    [BLOCK_SECTION_TITLE_BORDER_BOTTOM]: `solid 1px ${Equity_Orange}`,
    [BLOCK_SECTION_DIVIDER_BORDER]: `solid 1px ${Equity_Orange}`,
    [BUTTON_THIRD_BORDER]: `solid 1px ${Equity_Blue}`,
    [AVATAR_BORDER]: `solid 1px ${White}`,
    [CLOSE_BUTTON_BORDER]: `solid ${Black} 2px`,
    [LENGTH_BUTTON_BORDER]: `solid ${Black} 1px`,
    [MINDFULNESS_AUDIO_LIST_BORDER_COLOR]: White,
    [MODAL_BORDER]: `solid ${Light_Blue} 1.16px`,
    [SELECT_BORDER]: `solid ${chakraSelectBorderColor} 1px`,
    [SELECT_BORDER_FOCUSED]: `solid ${chakraSelectBorderColorFocused} 1px`,
    [DISABLED_BORDER]: `1px solid ${Disabled_Bg}`,
    [REACH_USERS_FILTER_BORDER]: `1px solid ${White}`,
  },
};
