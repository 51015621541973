import {
  Heading,
  Icon,
  IconButton,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { MdInfo } from "react-icons/md";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../State/GlobalContext";
import { ShellContext } from "../../State/ShellContext";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  CONTRAST_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { getFirstNameFromSelf } from "../../Utils/LoginUtil";
import { isWebPlatform } from "../../Utils/mobileUtils";

interface BrandedLogoProps {
  reducedSize?: boolean;
}

export const BrandedLogo = ({ reducedSize }: BrandedLogoProps) => {
  const { currentApp } = useContext(GlobalContext);

  const isMd = useBreakpointValue({ base: false, md: true });

  return (
    <Image
      display={"inline"}
      src={`https://cdn.headversity.com/app/logos/white/${currentApp?.toLowerCase()}.png`}
      h={`${isMd ? 26.5 * (reducedSize ? 0.85 : 1) : 16.5}px`}
      ml={isMd ? "8px" : "6px"}
      mt={isMd ? "-5px" : "-3px"}
      mr={isMd ? "2px" : "1px"}
      alt=""
      transition={"none"}
    />
  );
};

interface HeaderWelcomeTextProps {
  onInfoClick?: () => void;
  blockSection?: boolean;
  blockSectionMb?: number;
}
export const HeaderWelcomeText = ({
  onInfoClick,
  blockSection,
  blockSectionMb,
}: HeaderWelcomeTextProps) => {
  const { self } = useContext(GlobalContext);
  const { isSideBarOpen } = useContext(ShellContext);
  const isMd = useBreakpointValue({ base: false, md: true });

  return blockSection ? (
    <Heading
      fontSize="xl"
      color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
      mb={blockSectionMb}
      fontWeight="600"
    >
      {HVLocalizeStrings.HEADER_WELCOME}
      {getFirstNameFromSelf(self)}
      {HVLocalizeStrings.EXCLAMATION}
    </Heading>
  ) : (
    <>
      {isWebPlatform() && !isSideBarOpen ? (
        <>
          {HVLocalizeStrings.WELCOME_TO}
          <BrandedLogo />
          {onInfoClick && (
            <IconButton
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
              minW={"auto"}
              mt={isMd ? "-20px" : "-11px"}
              icon={<Icon as={MdInfo} />}
              fontSize={isMd ? "12px" : "11px"}
              color={CONTRAST_TEXT_COLOR}
              onClick={onInfoClick}
              aria-label={"Info"}
            />
          )}
        </>
      ) : (
        <>{HVLocalizeStrings.HEADER_WELCOME}</>
      )}

      {getFirstNameFromSelf(self)}
      {HVLocalizeStrings.EXCLAMATION}
    </>
  );
};
