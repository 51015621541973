import { Select, SelectProps, forwardRef } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  CSSObjectWithLabel,
  Select as ChakraMultiSelect,
} from "chakra-react-select";
import {
  chakraSelectBorderColorFocused,
  darkBluishGray,
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
  REACH_USERS_FILTER_BORDER,
  SELECT_BORDER,
  SELECT_BORDER_FOCUSED,
  SELECT_VALUE_BACKGROUND,
  White,
} from "../../Styles/HeadversityStyle";
import { LocalizationText } from "@headversity/contract/Common/LocalizationText";

const getOptionBackgroundColor = (isFocused: boolean, isSelected: boolean) => {
  if (isFocused) {
    return `${chakraSelectBorderColorFocused} !important`;
  } else if (isSelected) {
    return chakraSelectBorderColorFocused;
  } else {
    return darkBluishGray;
  }
};

const chakraStyles: ChakraStylesConfig = {
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: getOptionBackgroundColor(isFocused, isSelected),
    color: White,
    fontWeight: "500",
  }),
  container: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: INPUT_BACKGROUND_COLOR,
    borderRadius: "md",
    border: isFocused ? SELECT_BORDER_FOCUSED : SELECT_BORDER,
  }),
  placeholder: (provided) => ({
    ...provided,
    backgroundColor: INPUT_BACKGROUND_COLOR,
    color: INPUT_PRIMARY_TEXT_COLOR,
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: `${darkBluishGray} !important`,
    border: "1px solid black",
    padding: "2px",
    boxShadow: "0 0 0 1px darkgray inset",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 5,
  }),
  downChevron: (provided) => ({
    ...provided,
    color: INPUT_PRIMARY_TEXT_COLOR,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: SELECT_VALUE_BACKGROUND,
    color: INPUT_PRIMARY_TEXT_COLOR,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: INPUT_PRIMARY_TEXT_COLOR,
  }),
};

interface HVSelectProps extends SelectProps {
  isMultiSelection?: boolean;
  options?: React.ComponentProps<typeof ChakraMultiSelect>["options"];
  onMultiSelectChange?: React.ComponentProps<
    typeof ChakraMultiSelect
  >["onChange"];
  selectedValues?: React.ComponentProps<typeof ChakraMultiSelect>["value"];
  placeHolder?: React.ComponentProps<typeof ChakraMultiSelect>["placeholder"];
  hasBlackBorder?: boolean;
  zIndex?: number;
  usePrimaryColorForPlaceholder?: boolean;
}

export const HvSelect = forwardRef<HVSelectProps, "div">((props, ref) => {
  const isGray =
    (props.value === undefined || props.value === "") &&
    (props.defaultValue === undefined || props.defaultValue === "");
  const {
    isMultiSelection,
    options,
    selectedValues,
    onMultiSelectChange,
    placeHolder,
    hasBlackBorder,
    zIndex,
    usePrimaryColorForPlaceholder,
  } = props;
  let colorProp = {};
  if (usePrimaryColorForPlaceholder) {
    colorProp = {
      color: INPUT_PRIMARY_TEXT_COLOR,
    };
  }
  chakraStyles.placeholder = (provided) => ({
    ...provided,
    backgroundColor: INPUT_BACKGROUND_COLOR,
    ...colorProp,
  });
  const fallBackColor = isGray ? "gray.400" : INPUT_PRIMARY_TEXT_COLOR;
  if (hasBlackBorder) {
    chakraStyles.container = (provided) => ({
      ...provided,
      backgroundColor: INPUT_BACKGROUND_COLOR,
      borderRadius: "md",
      border: REACH_USERS_FILTER_BORDER,
    });
  }
  chakraStyles.control = (provided) => ({
    ...provided,
    backgroundColor: INPUT_BACKGROUND_COLOR,
    borderRadius: "md",
    "&:hover": {
      backgroundColor: INPUT_BACKGROUND_COLOR,
    },
    height: props.height ?? "50px",
  });
  let menuPortalProps = {};
  if (zIndex) {
    menuPortalProps = {
      menuPortalTarget: document.body,
      styles: {
        menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex }),
      },
    };
  }
  let zIndexProp = {};
  if (zIndex) {
    zIndexProp = {
      zIndex: zIndex,
    };
  }
  return (
    <>
      {isMultiSelection ? (
        <ChakraMultiSelect
          isMulti
          value={selectedValues}
          onChange={onMultiSelectChange}
          variant="filled"
          useBasicStyles
          name="certs"
          options={options}
          placeholder={placeHolder}
          closeMenuOnSelect={false}
          chakraStyles={chakraStyles}
          selectedOptionStyle="check"
          hideSelectedOptions={false}
          {...menuPortalProps}
        />
      ) : (
        <Select
          bg={props.bg ?? INPUT_BACKGROUND_COLOR}
          color={props.color ?? fallBackColor}
          ref={ref}
          height={props.height ?? "50px"}
          {...props}
          {...zIndexProp}
        />
      )}
    </>
  );
});

export interface HvSelectOption {
  value: number;
  label: string | LocalizationText;
}
