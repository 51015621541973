import {
  Black,
  DESTRUCTIVE_TEXT_COLOR,
  Equity_Black,
} from "../../../../Styles/HeadversityStyle";
import { Flex, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { HvTextInput } from "../../../Common/HvTextInput";
import { ChangeEvent, useContext } from "react";
import { HvSelect } from "../../../Common/HvSelect";
import {
  CreateSelfServeUserDto,
  UpdateSelfServeUserDto,
  UserRole,
} from "@headversity/contract";
import { ReachUsersContext } from "../../../../State/Reach/ReachUsersContext";
import dayjs from "dayjs";
import { validateEmail } from "../../../../Utils/EmailUtil";
import { ActivationDateTimePicker } from "./ActivationDateTimePicker";

interface UserModalEditTabPanelProps {
  fullName: string;
  setFullName: (fullName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  language: string;
  setLanguage: (language: string) => void;
  user: UpdateSelfServeUserDto | CreateSelfServeUserDto;
  setUser: (user: UpdateSelfServeUserDto | CreateSelfServeUserDto) => void;
  userCanEditEmail: boolean;
  setDirtyEmail: (dirtyEmail: boolean) => void;
  activationDateTime?: Date;
  handleActivationDateTimeChanged: (date: Date) => void;
  activationEmailSent?: string;
  isValidEmail: boolean;
  setIsValidEmail: (isValidEmail: boolean) => void;
  setIsValidFullName: (isValidFullName: boolean) => void;
  isValidFullName: boolean;
}

const UserModalEditTabPanel = (props: UserModalEditTabPanelProps) => {
  const {
    fullName,
    setFullName,
    email,
    setEmail,
    language,
    setLanguage,
    user,
    setUser,
    userCanEditEmail,
    setDirtyEmail,
    activationDateTime,
    handleActivationDateTimeChanged,
    activationEmailSent,
    isValidEmail,
    setIsValidEmail,
    setIsValidFullName,
    isValidFullName,
  } = props;
  const usersContext = useContext(ReachUsersContext);

  // get custom fields, if any
  const customInfoElements: JSX.Element[] = [];
  if (user?.customInfo) {
    for (let key in user?.customInfo) {
      customInfoElements.push(
        <UserEditInputField
          key={key}
          label={key}
          value={user?.customInfo[key]}
          type={"text"}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setUser({
              ...user,
              customInfo: {
                ...user?.customInfo,
                [key]: event.target.value,
              },
            })
          }
        />
      );
    }
  }
  return (
    <Flex flexDir={"column"} gap={4} color={Equity_Black}>
      <UserEditInputField
        type={"text"}
        label={HVLocalizeStrings.FULL_NAME}
        value={fullName}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFullName(event.target.value);
          setUser({
            ...user,
            userFields: {
              ...user?.userFields,
              fullName: event.target.value,
            },
          });
          setIsValidFullName(event.target.value.trim().includes(" "));
        }}
        isNotValid={!isValidFullName}
        errorMessage={HVLocalizeStrings.REACH_USER_INVALID_FULL_NAME}
      />
      <UserEditInputField
        type={"email"}
        label={HVLocalizeStrings.EMAIL_ADDRESS}
        value={email}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setDirtyEmail(true);
          setEmail(event.target.value);
          setUser({
            ...user,
            userFields: {
              ...user?.userFields,
              email: event.target.value,
            },
          });
          setIsValidEmail(validateEmail(event.target.value) !== false);
        }}
        disabled={!userCanEditEmail}
        isNotValid={!isValidEmail}
        errorMessage={HVLocalizeStrings.REACH_USER_INVALID_EMAIL_ADDRESS}
      />
      <UserEditSelectField
        label={HVLocalizeStrings.REACH_USER_ROLE}
        value={user?.userFields.role}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          setUser({
            ...user,
            userFields: {
              ...user?.userFields,
              role: parseInt(event.target.value),
            },
          })
        }
        options={[
          { label: "Admin", value: UserRole.Admin },
          { label: "Learner", value: UserRole.Learner },
        ]}
      />
      <UserEditSelectField
        label={HVLocalizeStrings.LANGUAGE_TITLE}
        value={language}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          setLanguage(event.target.value);
          setUser({
            ...user,
            userFields: {
              ...user?.userFields,
              language: event.target.value,
            },
          });
        }}
        options={[
          { label: HVLocalizeStrings.LANGUAGE_ENGLISH, value: "en" },
          { label: HVLocalizeStrings.LANGUAGE_FRENCH, value: "fr" },
          { label: HVLocalizeStrings.LANGUAGE_SPANISH, value: "es" },
        ]}
      />
      {activationEmailSent ? (
        <Flex gap={1}>
          <Text
            fontWeight={"semibold"}
            fontSize="sm"
            color={Black}
            width={"150px"}
          >
            {HVLocalizeStrings.REACH_USERS_EMAIL_SENT_DATE}
          </Text>
          <Text fontSize="sm" color={Equity_Black}>
            {dayjs
              .utc(activationEmailSent)
              .local()
              .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME)}
          </Text>
        </Flex>
      ) : (
        <UserEditDateFields
          label={HVLocalizeStrings.REACH_ACTIVATION_EMAIL_SEND_DATE}
          currentDate={activationDateTime}
          onDateTimeChanged={handleActivationDateTimeChanged}
        />
      )}

      <UserEditSelectField
        label={HVLocalizeStrings.REACH_INVITE_CODE}
        value={user?.userFields.inviteCodeId}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          setUser({
            ...user,
            userFields: {
              ...user?.userFields,
              inviteCodeId: parseInt(event.target.value),
            },
          })
        }
        options={usersContext.inviteCodesForReach?.map((inviteCode) => {
          return { label: inviteCode.code, value: inviteCode.id };
        })}
      />
      {customInfoElements}
    </Flex>
  );
};

interface LabelTextProps {
  text: string;
}

const LabelText = (props: LabelTextProps) => {
  const { text } = props;
  return (
    <Text
      fontWeight={"semibold"}
      fontSize="sm"
      color={Equity_Black}
      width={"150px"}
    >
      {text}
    </Text>
  );
};

interface UserEditInputFieldProps {
  type: string;
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isNotValid?: boolean;
  errorMessage?: string;
}

const UserEditInputField = (props: UserEditInputFieldProps) => {
  const { type, label, value, onChange, disabled, isNotValid, errorMessage } =
    props;
  return (
    <Flex gap={6} alignItems={"center"}>
      <LabelText text={label} />
      <Flex flexDirection={"column"} width={"100%"}>
        <HvTextInput
          type={type}
          value={value}
          onChange={onChange}
          bg={"white"}
          color={Equity_Black}
          disabled={disabled}
          boxShadow={"lg"}
        />
        {isNotValid && (
          <Text fontSize={"xs"} color={DESTRUCTIVE_TEXT_COLOR}>
            {errorMessage}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

interface UserEditDateFieldsProps {
  label: string;
  currentDate?: Date;
  onDateTimeChanged: (date: Date) => void;
}

const UserEditDateFields = (props: UserEditDateFieldsProps) => {
  const { label, onDateTimeChanged, currentDate } = props;
  return (
    <Flex gap={6} alignItems={"center"}>
      <LabelText text={label} />
      <Flex flexDirection={"column"} width={"100%"}>
        <ActivationDateTimePicker
          currentDate={currentDate}
          onChange={onDateTimeChanged}
          isLightTheme
        />
        <Text fontSize="sm">
          {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </Text>
      </Flex>
    </Flex>
  );
};

interface UserEditSelectFieldProps {
  label: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string | number }[];
}

const UserEditSelectField = (props: UserEditSelectFieldProps) => {
  const { label, value, onChange, options } = props;
  return (
    <Flex gap={6} alignItems={"center"}>
      <LabelText text={label} />
      <Flex flexDirection={"column"} width={"100%"}>
        <HvSelect
          placeholder={HVLocalizeStrings.SELECT}
          value={value}
          onChange={onChange}
          color={Equity_Black}
          bg={"white"}
          boxShadow={"lg"}
        >
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </HvSelect>
      </Flex>
    </Flex>
  );
};

export default UserModalEditTabPanel;
