import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
} from "@headversity/contract";

export const getPolls = (
  teamLessonId: number,
  token: string
): Promise<AxiosResponse<PollQuestionDto[]>> => {
  return AxiosClient.get(
    `api/polls/teamLesson/${teamLessonId}`,
    getConfig(token)
  );
};

export const getPollInstances = (
  token: string
): Promise<AxiosResponse<PollQuestionUserInstanceDto[]>> => {
  return AxiosClient.get(`api/pollInstances`, getConfig(token));
};

export const getPollInstanceResult = (
  pollQuestionUserInstanceId: number,
  token: string
): Promise<AxiosResponse<QuestionUserResponseDto[]>> => {
  return AxiosClient.get(
    `api/pollInstance/${pollQuestionUserInstanceId}/result`,
    getConfig(token)
  );
};

export const setPollInstance = (
  pollQuestionId: number,
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<PollQuestionUserInstanceDto>> => {
  return AxiosClient.post(
    `api/pollInstance/poll/${pollQuestionId}/teamLessonUserInstance/${teamLessonUserInstanceId}`,
    null,
    getConfig(token)
  );
};

export const setPollInstanceResult = (
  pollInstanceId: number,
  pollQuestionSessionInputDto: PollQuestionSessionInputDto,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/pollInstance/${pollInstanceId}/result`,
    {
      questionAlternativeId: pollQuestionSessionInputDto.questionAlternativeId,
      freeText: pollQuestionSessionInputDto.freeText,
    },
    getConfig(token)
  );
};
