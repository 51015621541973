import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getKey } from "../../../Utils/Helpers";
import {
  PEOPLE_EVENTS,
  PEOPLE_EVENTS_PARAMS,
  track,
} from "../../../Utils/Analytics";
import { generateErrorFromBackend } from "../Shared/ErrorCodes";
import { LandingContainer } from "../Shared/LandingContainer";
import {
  Center,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ErrorText } from "../Shared/ErrorText";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { updatePassword } from "../../../Api/People/PasswordApi";
import {
  RegistrationLoginStates,
  UpdatePasswordDto,
} from "@headversity/contract";
import { GlobalContext } from "../../../State/GlobalContext";
import { TermsOfUsePrivacyPolicy } from "../Login/TermsOfUsePrivacyPolicy";
import PasswordInput from "../Shared/PasswordInput";

interface SetPasswordStepProps {
  showTermsAndPolicyModal?: boolean;
  isValidPassword: boolean;
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  resetPasswordValidation: () => void;
  validatePassword: (password: string) => void;
}

export const SetPasswordStep = (props: SetPasswordStepProps) => {
  const {
    showTermsAndPolicyModal,
    isValidPassword,
    pwdHasNumber,
    pwdHasLowerCase,
    pwdHasUpperCase,
    pwdHasMinLength,
    resetPasswordValidation,
    validatePassword,
  } = props;
  const { saveSiteLoadedEventToServer } = useContext(GlobalContext);
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [verifyPassword, setVerifyPassword] = useState<string | null>(null);
  const [matchingPasswords, setMatchingPasswords] = useState(false);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  useEffect(() => {
    if (password && verifyPassword) {
      setMatchingPasswords(password === verifyPassword);
    }
  }, [password, verifyPassword]);

  const submitPasswordOrInviteCode = () => {
    setIsSubmitting(true);
    if (password && verifyPassword && password !== verifyPassword) {
      setError(HVLocalizeStrings.PASSWORD_MISMATCH);
      setIsSubmitting(false);
      return;
    }
    let encryptedBase64Password: string | false = false;
    if (password) {
      encryptedBase64Password = password;
    }

    track(PEOPLE_EVENTS.UpdateAccountPassword, {});
    updatePassword(getKey(), {
      password: encryptedBase64Password,
    } as UpdatePasswordDto).then(
      async (result: any) => {
        if (result.errorCode) {
          const errorText = generateErrorFromBackend(result.errorCode, null);
          track(PEOPLE_EVENTS.UpdateAccountError, {
            [PEOPLE_EVENTS_PARAMS.Error]: errorText,
          });
          setError(errorText);
          setIsSubmitting(false);
        } else {
          saveSiteLoadedEventToServer().then(async () => {
            resetPasswordValidation();
            window.location.reload();
          });
        }
      },
      (error: any) => {
        console.log(error);
        setError(`${HVLocalizeStrings.SIGNUP_ERROR} ${error}`);
        setIsSubmitting(false);
      }
    );
  };

  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string
  ) => {
    switch (stateToUpdate) {
      case RegistrationLoginStates.Password:
        setPassword(stateText);
        validatePassword(stateText);
        break;
      case RegistrationLoginStates.PasswordConfirmation:
        setVerifyPassword(stateText);
        break;
      default:
        return;
    }
    setError("");
  };

  return (
    <LandingContainer other={<></>}>
      <Text
        fontWeight={"normal"}
        fontSize={"md"}
        color={PRIMARY_TEXT_COLOR}
        align={isMobile ? "center" : undefined}
      >
        {HVLocalizeStrings.LOGIN_SET_PASSWORD}
      </Text>
      <Flex
        flexDirection={"column"}
        pt={"7"}
        gap={"2"}
        align={"center"}
        p={"20px"}
      >
        {error && (
          <Center pb={"3"} w={"300px"}>
            <ErrorText message={error} />
          </Center>
        )}
        <Flex flexDir={"column"} gap={"2"}>
          <PasswordInput
            showVerifyField={true}
            passwordError={null}
            pwdHasNumber={pwdHasNumber}
            pwdHasLowerCase={pwdHasLowerCase}
            pwdHasUpperCase={pwdHasUpperCase}
            pwdHasMinLength={pwdHasMinLength}
            pwdMatch={matchingPasswords}
            handleChange={handleChange}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                submitPasswordOrInviteCode();
              }
            }}
          ></PasswordInput>
        </Flex>
        <>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <AnimatedButton
              text={HVLocalizeStrings.LOGIN_SAVE_PASSWORD}
              disabled={isSubmitting || !matchingPasswords || !isValidPassword}
              colorSet={AnimatedButtonColorSet.Primary}
              onClick={() => {
                submitPasswordOrInviteCode();
              }}
              w={300}
            />
          )}
        </>
      </Flex>
      {showTermsAndPolicyModal && <TermsOfUsePrivacyPolicy />}
    </LandingContainer>
  );
};
