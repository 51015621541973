export enum TeamLessonUserInstanceStatus {
  Initialized,
  InProgress,
  ActivityInProgress,
  Complete,
  Cancelled,
  ForcedComplete,
  Catchup,
  Incomplete,
  UpNext,
}
