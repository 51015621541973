import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  ErrorCodeType,
  NewTeamUserDto,
  TeamDto,
  TeamUserDto,
} from "@headversity/contract";

export const saveTeam = (token: string, teamDto: TeamDto) => {
  return AxiosClient.post("api/teams", teamDto, getConfig(token));
};

export const saveTeamUser = (
  token: string,
  newTeamUserDto: NewTeamUserDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post("api/teamUser", newTeamUserDto, getConfig(token));
};
export const deleteTeam = (
  id: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete(`api/teams/${id}`, getConfig(token));
};
export const deleteTeamUser = (
  teamId: number,
  userId: number,
  token: string
): Promise<AxiosResponse<ErrorCodeType | string>> => {
  return AxiosClient.delete(
    `api/teamUser/${teamId}/${userId}`,
    getConfig(token)
  );
};

export const getTeams = (token: string): Promise<AxiosResponse<TeamDto[]>> => {
  return AxiosClient.get("api/teams", getConfig(token));
};

export const joinTeam = (
  token: string,
  teamCode: string
): Promise<AxiosResponse<ErrorCodeType | TeamUserDto>> => {
  return AxiosClient.post(
    `api/teamUser/join/${teamCode}`,
    {},
    getConfig(token)
  );
};
