import { ParticipantTeamLessonStepProps } from "./ParticipantModal";
import { PresentationRatingView } from "../Shared/PresentationRatingView";

interface ParticipantFeedbackStepProps extends ParticipantTeamLessonStepProps {
  teamLessonUserInstanceId?: number;
  closeFunction: () => void;
}

export const ParticipantFeedbackStep = (
  props: ParticipantFeedbackStepProps
) => {
  const { closeFunction, teamLessonUserInstanceId } = props;

  let content = (
    <PresentationRatingView
      teamLessonUserInstanceId={teamLessonUserInstanceId}
      closeFunction={() => {
        closeFunction();
      }}
    />
  );

  return <>{content}</>;
};
