import {
  BlogPostDto,
  CertQualificationDto,
  CompanyDto,
  Dictionary,
  GoalDto,
  GoalWinThemeDto,
  MicroLessonDto,
  NanoPracticeDto,
  NanoPracticeUserInstanceDto,
  SchedulerDto,
  SchedulerFrequency,
  SkillDto,
  ToolDto,
  UserSkillV2Dto,
} from "@headversity/contract";
import dayjs, { Dayjs } from "dayjs";
import { BadgeData } from "../Components/Common/BadgesAndShields/BadgeCard";
import { getDaysSinceOrDate } from "./TimeUtils";
import { sortByProperty } from "./Helpers";
import { CertQualificationData } from "../Components/Cert/Shared/QualificationProgressCard";
import { HeadScanResponse } from "../Components/Solo/HeadScan/HeadScanQuestionContent";
import { isNanoPracticeDoneTodayOrByStartDate } from "../Components/Solo/NanoPractices/NanoPracticeUtils";
import { EVENTS, track } from "./Analytics";
import { getFrequencyFromScheduleExpression } from "./CronParserUtil";
import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";

export const SOLO_GOAL_SKILL_LEVEL_TARGET = 2;
export const SOLO_GOAL_WELCOME_BACK_KEY = "interstitial-goal-welcome-back";
export const SOLO_GOAL_CHALLENGE_KEY = "lastChallengeShown";
export const SOLO_GOAL_STREAK_KEY = "lastStreakShown";

export enum SkillType {
  SelfExpertise = 1,
  Mindfulness = 2,
  MentalFitness = 3,
  MentalHealth = 4,
  Hardiness = 5,
  EnergyManagement = 6,
}

export interface ToolSkillInfo {
  name: string;
  description: string;
  skill1Icon: string;
  skill1: string;
  skill1Description: string;
  skill2Icon: string;
  skill2: string;
  skill2Description: string;
  skill3Icon: string;
  skill3: string;
  skill3Description: string;
  buttonText: string;
}

export const getSkillName = (skillId: number, skills: any[]) => {
  const filteredSkills = skills?.filter((item: any) => {
    return item.id === skillId;
  });
  if (filteredSkills.length > 0) {
    return filteredSkills[0].name;
  }
  return "";
};

export const getSkillDescription = (skillId: number, skills: any[]) => {
  const filteredSkills = skills?.filter((item: any) => {
    return item.id === skillId;
  });
  if (filteredSkills.length > 0) {
    return filteredSkills[0].description;
  }
  return "";
};

export const getToolSkillInfo = (
  tool: ToolDto,
  skills: SkillDto[]
): ToolSkillInfo => {
  const toolSkillInfo: ToolSkillInfo = {
    name: tool?.name as string,
    description: tool?.description as string,
    skill1: "",
    skill1Icon: "",
    skill1Description: "",
    skill2: "",
    skill2Icon: "",
    skill2Description: "",
    skill3: "",
    skill3Icon: "",
    skill3Description: "",
    buttonText: "",
  };
  for (let i = 0; i < tool?.skillCompetencies?.length && i < 3; i++) {
    const skillId = tool?.skillCompetencies[i].skillId;
    const filteredSkills = skills.filter((skill) => skill.id === skillId);
    if (filteredSkills.length > 0) {
      const skillName = filteredSkills[0].name as string;
      const skillDescription = filteredSkills[0].description as string;
      if (i === 0) {
        toolSkillInfo.skill1 = skillName;
        toolSkillInfo.skill1Icon =
          SkillIcons[SkillIdsToName[filteredSkills[0].id]];
        toolSkillInfo.skill1Description = skillDescription;
      } else if (i === 1) {
        toolSkillInfo.skill2 = skillName;
        toolSkillInfo.skill2Icon =
          SkillIcons[SkillIdsToName[filteredSkills[0].id]];
        toolSkillInfo.skill2Description = skillDescription;
      } else if (i === 2) {
        toolSkillInfo.skill3 = skillName;
        toolSkillInfo.skill3Icon =
          SkillIcons[SkillIdsToName[filteredSkills[0].id]];
        toolSkillInfo.skill3Description = skillDescription;
      }
    }
  }

  return toolSkillInfo;
};

export const SkillIdsToName: any = {
  "1": "SelfExpertise",
  "2": "Mindfulness",
  "3": "MentalFitness",
  "4": "MentalHealth",
  "5": "Hardiness",
  "6": "EnergyManagement",
};

export const SkillIcons: any = {
  EnergyManagement:
    "https://cdn.headversity.com/app/resources/skillIcons/energy-mgmt.svg",
  Hardiness:
    "https://cdn.headversity.com/app/resources/skillIcons/hardiness.svg",
  MentalFitness:
    "https://cdn.headversity.com/app/resources/skillIcons/mental-fitness.svg",
  MentalHealth:
    "https://cdn.headversity.com/app/resources/skillIcons/mental-health.svg",
  Mindfulness:
    "https://cdn.headversity.com/app/resources/skillIcons/mindfulness.svg",
  SelfExpertise:
    "https://cdn.headversity.com/app/resources/skillIcons/self-expertise.svg",
};

export const SkillIdToShieldAnimation: any = {
  "1": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_Purpose.gif",
  "2": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_focus.gif",
  "3": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_Confidence.gif",
  "4": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_compassion.gif",
  "5": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_Grit.gif",
  "6": "https://images.headversity.com/app/shield-animations/Core/Shield_LU_Recharge.gif",
  "9": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Grow.gif",
  "10": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Protect.gif",
  "11": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Respect.gif",
  "12": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Connect.gif",
  "13": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Optimism.gif",
  "14": "https://images.headversity.com/app/shield-animations/Work/Shield_LU_Return.gif",
  "15": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Balance.gif",
  "16": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Coping.gif",
  "17": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Empathy.gif",
  "18": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Nurture.gif",
  "19": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Harmonize.gif",
  "20": "https://images.headversity.com/app/shield-animations/Home/Shield_LU_Recovery.gif",
};

interface SkillLastPractice {
  skillId: number;
  lastPracticed: Dayjs;
}

export const getSuggestedPracticesForUser = (
  skills: SkillDto[],
  nanoPractices: NanoPracticeDto[],
  nanoPracticeInstances: Dictionary<NanoPracticeUserInstanceDto[]>,
  selectedGoal?: GoalDto
) => {
  const otherPractices: NanoPracticeDto[] = [];
  const preferedPractices: NanoPracticeDto[] = [];

  const skillIdsPracticed: number[] = [];
  const goalSkillIdsByPracticeDate: SkillLastPractice[] = [];

  const allInstances = Object.values(nanoPracticeInstances).flat();

  let goalNotPracticedCount = 0;

  for (const s of skills) {
    const practicesInThisSkill = nanoPractices.filter((x) =>
      x.skillCompetencies.some((y) => y.skillId === s.id)
    );

    // get the max completion of practices in goal skills
    if (selectedGoal && selectedGoal.skillIds.includes(s.id)) {
      const instancesInThisSkill = allInstances.filter((x) =>
        practicesInThisSkill.some((y) => y.id === x.nanoPracticeId)
      );

      if (instancesInThisSkill.length > 0) {
        const maxInstance = instancesInThisSkill.reduce((prev, current) => {
          return dayjs
            .utc(prev.createdAt.toString())
            .isAfter(dayjs.utc(current.createdAt.toString()))
            ? prev
            : current;
        });

        goalSkillIdsByPracticeDate.push({
          skillId: s.id,
          lastPracticed: dayjs.utc(maxInstance.createdAt.toString()),
        });
      } else {
        goalNotPracticedCount++;

        goalSkillIdsByPracticeDate.push({
          skillId: s.id,
          lastPracticed: dayjs.utc(`2000-01-0${goalNotPracticedCount}`),
        });
      }
    }

    // check if this skill was ever practiced before
    for (const np of practicesInThisSkill) {
      const isPracticed = isNanoPracticeDoneTodayOrByStartDate(
        np.id,
        nanoPracticeInstances,
        "2000-01-01"
      );

      if (isPracticed) {
        skillIdsPracticed.push(s.id);
        break; // go to next skill
      }
    }
  }

  // start with goal skills
  if (goalSkillIdsByPracticeDate.length > 0) {
    // sort by oldest practice date
    goalSkillIdsByPracticeDate.sort((a, b) => {
      return a.lastPracticed.isAfter(b.lastPracticed) ? 1 : -1;
    });

    for (const s of goalSkillIdsByPracticeDate) {
      // pick the oldest practice in the goal skill
      const practicesInThisSkill = nanoPractices.filter((x) =>
        x.skillCompetencies.some((y) => y.skillId === s.skillId)
      );

      sortPracticesByLastCompleted(practicesInThisSkill, nanoPracticeInstances);

      preferedPractices.push(practicesInThisSkill[0]);
    }
  }

  // then order the rest of the practices so that what we've done is at the end
  for (const s of skills) {
    const skillPractices = nanoPractices.filter(
      (p) =>
        p.skillCompetencies.some((sc) => sc.skillId === s.id) &&
        !preferedPractices.some((y) => y.id === p.id)
    );

    for (const np of skillPractices) {
      const isSkillDone = skillIdsPracticed.includes(s.id);

      const gotPracticeInThisSkill =
        preferedPractices.filter((x) =>
          x.skillCompetencies.some((sc) => sc.skillId === s.id)
        ).length > 0;

      if (isSkillDone || gotPracticeInThisSkill) {
        otherPractices.push(np);
      } else {
        preferedPractices.push(np);
      }
    }
  }

  // sort by oldest practiced date for practices in skills we've already done
  sortPracticesByLastCompleted(otherPractices, nanoPracticeInstances);

  return preferedPractices.concat(otherPractices);
};

const sortPracticesByLastCompleted = (
  practices: NanoPracticeDto[],
  nanoPracticeInstances: Dictionary<NanoPracticeUserInstanceDto[]>
) => {
  practices.sort((a, b) => {
    const aLastPracticed = nanoPracticeInstances[a.id];
    const bLastPracticed = nanoPracticeInstances[b.id];

    if (
      aLastPracticed &&
      bLastPracticed &&
      aLastPracticed.length > 0 &&
      bLastPracticed.length > 0
    ) {
      return dayjs
        .utc(aLastPracticed[0].createdAt.toString())
        .isAfter(bLastPracticed[0].createdAt.toString())
        ? 1
        : -1;
    } else if (aLastPracticed && aLastPracticed.length > 0) {
      return 1;
    } else if (bLastPracticed && bLastPracticed.length > 0) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const getSuggestedLessonsForUser = (
  getSuggestedLessons: (
    preferredSkillIds: number[],
    allSkillIds: number[],
    includeCustom?: boolean
  ) => MicroLessonDto[],
  skills: SkillDto[],
  userSkillStats: UserSkillV2Dto[],
  selectedGoal?: GoalDto,
  includeCustom?: boolean
) => {
  const allSkillIds = getSortedSkillsForUser(
    skills,
    userSkillStats,
    selectedGoal
  )
    .map((x) => x.skillCompetencies.map((y) => y.skillId))
    .flat();

  const skillIdsToUse = getPrioritySkillIds(
    userSkillStats,
    selectedGoal
  ).filter((x) => allSkillIds.includes(x));

  return getSuggestedLessons(skillIdsToUse, allSkillIds, includeCustom);
};

export const getSortedSkillsForUser = (
  skills: SkillDto[],
  userSkillStats: UserSkillV2Dto[],
  selectedGoal?: GoalDto
): SkillDto[] => {
  return sortSkillsByGoalAndUserSkillsAttribute(
    skills,
    userSkillStats,
    "headscanPercentage",
    false,
    selectedGoal
  );
};

export const getSkillDarkModeBgColor = (skill: SkillDto): string => {
  switch (skill.context) {
    case "Core":
      switch (skill.domain) {
        case "Establishing":
          return "#2e4774";
        case "Understanding":
          return "#1d3470";
        case "Excelling":
          return "#1c2a72";
        default:
          return "";
      }
    case "Work":
      switch (skill.domain) {
        case "Establishing":
          return "#4d4e57";
        case "Understanding":
          return "#494253";
        case "Excelling":
          return "#483c55";
        default:
          return "";
      }

    case "Home":
      switch (skill.domain) {
        case "Establishing":
          return "#494174";
        case "Understanding":
          return "#393162";
        case "Excelling":
          return "#372d6c";
        default:
          return "";
      }
  }
  return "";
};

export const getSkillId = (obj: MicroLessonDto) => {
  return obj?.skillCompetencies?.length > 0
    ? obj.skillCompetencies[0].skillId
    : -1;
};

export const getOrderedSkills = (skills: SkillDto[]) => {
  return [
    skills.find((item) => item.id === 3) as SkillDto, // 0 Confidence
    skills.find((item) => item.id === 1) as SkillDto, // 1 Purpose
    skills.find((item) => item.id === 4) as SkillDto, // 2 Compassion
    skills.find((item) => item.id === 5) as SkillDto, // 3 Grit
    skills.find((item) => item.id === 2) as SkillDto, // 4 Focus
    skills.find((item) => item.id === 6) as SkillDto, // 5 Recharge
    skills.find((item) => item.id === 9) as SkillDto, // 6 Grow
    skills.find((item) => item.id === 10) as SkillDto, // 7 Protect
    skills.find((item) => item.id === 11) as SkillDto, // 8 Respect
    skills.find((item) => item.id === 12) as SkillDto, // 9 Connect
    skills.find((item) => item.id === 13) as SkillDto, // 10 Optimize
    skills.find((item) => item.id === 14) as SkillDto, // 11 Return
    skills.find((item) => item.id === 15) as SkillDto, // 12 Balance
    skills.find((item) => item.id === 16) as SkillDto, // 13 Coping
    skills.find((item) => item.id === 17) as SkillDto, // 14 Empathy
    skills.find((item) => item.id === 18) as SkillDto, // 15 Nurture
    skills.find((item) => item.id === 19) as SkillDto, // 16 Harmonize
    skills.find((item) => item.id === 20) as SkillDto, // 17 Recovery
  ].filter((x) => !!x);
};

/*
 * Calculate the total minutes trained by the user
 *  2 minutes for practice, 5 minutes for lesson, 1 minute for post
 */
export const calculateMinutesTrained = (
  completedPractices: number,
  completedLessons: number,
  completedPosts: number
) => {
  return completedPractices * 2 + completedLessons * 5 + completedPosts;
};

export const getBadgeData = (
  skill?: SkillDto,
  userScoreLocal?: UserSkillV2Dto,
  userSkill?: UserSkillV2Dto
): BadgeData => {
  const skillId = skill?.id ?? 0;

  return {
    skillId: skillId,
    iconUrl: skill?.imageUrl?.toString(),
    iconAnimation: skill?.id
      ? SkillIdToShieldAnimation[skill?.id?.toString()]
      : "",
    shieldName: skill?.shieldName as string,
    shieldColor: skill?.color,
    shieldOutlineUrl: `https://cdn.headversity.com/app/resources/shield/${skill?.context.toLowerCase()}-shield.svg`,
    levelledUp: userScoreLocal?.level !== userSkill?.level,
    previousPointsOnLevel: userScoreLocal?.points ? userScoreLocal?.points : 0,
    newPointsOnLevel: userSkill?.points ?? 0,
    newLevel: userSkill?.level ?? 0,
    totalLevels: 50,
    get lastActivity() {
      if (!userSkill?.lastActivityDate) {
        return "-";
      }

      return getDaysSinceOrDate(dayjs(userSkill?.lastActivityDate));
    },
    minutesTrained: calculateMinutesTrained(
      userSkill?.completedPractices ?? 0,
      userSkill?.completedLessons ?? 0,
      userSkill?.completedPosts ?? 0
    ),
    lessonsCompleted: userSkill?.completedLessons ?? 0,
    practicesCompleted: userSkill?.completedPractices ?? 0,
    postsCompleted: userSkill?.completedPosts ?? 0,
    get lastLevelledUp() {
      if (!userSkill?.lastActivityDate || !userSkill?.lastLevelledUpDate) {
        return "-";
      }

      return getDaysSinceOrDate(dayjs(userSkill?.lastLevelledUpDate));
    },
  };
};

export const getCertQualificationData = (
  certQualificationLocal?: CertQualificationDto,
  certQualification?: CertQualificationDto
): CertQualificationData => {
  // only set the flag for completing a qualification and the previous points earned if the qualification period has not changed since the user last visited
  let completedQualification = false;
  let previousPointsEarned = 0;
  if (certQualificationLocal?.startDate === certQualification?.startDate) {
    completedQualification =
      (certQualification?.pointsEarned as number) >=
        (certQualification?.pointsNeeded as number) &&
      certQualificationLocal?.pointsEarned !== certQualification?.pointsEarned;
    previousPointsEarned = certQualificationLocal?.pointsEarned
      ? certQualificationLocal?.pointsEarned
      : 0;
  }

  return {
    certId: certQualification?.certId ?? 0,
    completedQualification: completedQualification,
    previousPointsEarned: previousPointsEarned,
    pointsEarned: certQualification?.pointsEarned ?? 0,
    pointsNeeded: certQualification?.pointsNeeded ?? 0,
    startDate: certQualification?.startDate.toString() as string,
    endDate: certQualification?.endDate.toString() as string,
  };
};

export const getPrioritySkillIds = (
  userSkillStats: UserSkillV2Dto[],
  selectedGoal?: GoalDto
) => {
  const skillsByScore = getScoredSkillIds(userSkillStats);

  // if we have no selected goal, use unlocked skills in score order
  if (!selectedGoal) {
    return skillsByScore;
  }

  // start with unlocked skills in the goal in score order
  const result = skillsByScore.filter((sId) =>
    selectedGoal.skillIds.includes(sId)
  );

  // add the rest of the goal-related skills, in order specified on goal
  for (const id of selectedGoal.skillIds) {
    if (!result.includes(id)) {
      result.push(id);
    }
  }

  return result;
};

export const getScoredSkillIds = (userSkillStats: UserSkillV2Dto[]) => {
  userSkillStats.sort((a, b) => a.headscanPercentage - b.headscanPercentage);

  return userSkillStats.map((usk) => usk.skillId);
};

export const getSortedSkillsForUserDesc = (
  skills: SkillDto[],
  userSkillStats: UserSkillV2Dto[],
  selectedGoal?: GoalDto
) => {
  return sortSkillsByGoalAndUserSkillsAttribute(
    skills,
    userSkillStats,
    "level",
    true,
    selectedGoal
  );
};

const sortSkillsByGoalAndUserSkillsAttribute = (
  skills: SkillDto[],
  userSkillStats: UserSkillV2Dto[],
  attr: string,
  descending: boolean,
  selectedGoal?: GoalDto
) => {
  const addUnscoredSkills = (
    skills: SkillDto[],
    selectedGoal: GoalDto,
    userSkillStats: UserSkillV2Dto[],
    finalSkills: SkillDto[]
  ) => {
    for (const id of selectedGoal.skillIds) {
      const skill = skills.find((skill) => skill.id === id);
      const userSkill = userSkillStats.find((x) => x.skillId === id);

      if (!userSkill && skill && !finalSkills.includes(skill)) {
        finalSkills.push(skill);
      }
    }
  };

  const sortedUserSkills = sortByProperty<UserSkillV2Dto>(
    userSkillStats,
    (usk) => usk[attr as keyof UserSkillV2Dto] as any,
    descending
  );

  const finalSkills: SkillDto[] = [];

  // goal-related skills first
  if (selectedGoal) {
    if (!descending) {
      // first, unscored
      addUnscoredSkills(skills, selectedGoal, userSkillStats, finalSkills);

      // then, skills below the target level
      for (const id of selectedGoal.skillIds) {
        const skill = skills.find((skill) => skill.id === id);
        const userSkill = userSkillStats.find((x) => x.skillId === id);

        if (
          userSkill &&
          userSkill.level < SOLO_GOAL_SKILL_LEVEL_TARGET &&
          skill &&
          !finalSkills.includes(skill)
        ) {
          finalSkills.push(skill);
        }
      }
    }

    // finally, in order of score
    for (const userSkill of sortedUserSkills) {
      if (selectedGoal.skillIds.includes(userSkill.skillId)) {
        const skill = skills.find((skill) => skill.id === userSkill.skillId);
        if (skill && !finalSkills.includes(skill)) {
          finalSkills.push(skill);
        }
      }
    }

    if (descending) {
      // last, unscored
      addUnscoredSkills(skills, selectedGoal, userSkillStats, finalSkills);
    }
  }

  // scored, non-goal-related skills next
  for (const userSkill of sortedUserSkills) {
    const skill = skills.find((skill) => skill.id === userSkill.skillId);
    if (skill && !finalSkills.includes(skill)) {
      finalSkills.push(skill);
    }
  }

  // unscored by relationship to the first
  const suggestFromSkill = finalSkills.length > 0 ? finalSkills[0] : skills[0];
  const veryRelatedSkills: SkillDto[] = [];
  const relatedSkills: SkillDto[] = [];
  const slightlyRelatedSkills: SkillDto[] = [];
  const otherSkills: SkillDto[] = [];

  for (const skill of skills) {
    if (!finalSkills.includes(skill)) {
      // same domain and context
      if (
        skill.context === suggestFromSkill.context &&
        skill.domain === suggestFromSkill.domain
      ) {
        veryRelatedSkills.push(skill);
      }
      // same domain, different context
      else if (
        skill.context !== suggestFromSkill.context &&
        skill.domain === suggestFromSkill.domain
      ) {
        relatedSkills.push(skill);
      }
      // different domain, same context
      else if (
        skill.context === suggestFromSkill.context &&
        skill.domain !== suggestFromSkill.domain
      ) {
        slightlyRelatedSkills.push(skill);
      }
      // different domain, different context
      else if (
        skill.context !== suggestFromSkill.context &&
        skill.domain !== suggestFromSkill.domain
      ) {
        otherSkills.push(skill);
      }
    }
  }

  return finalSkills.concat(
    veryRelatedSkills,
    relatedSkills,
    slightlyRelatedSkills,
    otherSkills
  );
};

/*
 * true if the user hasn't scored in this skill within the last 30 days
 */
export const needsSkillScore = (
  userSkills: UserSkillV2Dto[],
  skillId?: number
) => {
  if (!skillId) return false;

  const userSkill = userSkills.find((usk) => usk.skillId === skillId);

  if (!userSkill || userSkill.headscanHistory.length === 0) return true;

  const lastScore = dayjs.utc(
    userSkill.headscanHistory[userSkill.headscanHistory.length - 1].date
  );

  return lastScore < dayjs.utc().subtract(14, "days");
};

export const getUserSkillBySkillId = (
  userSkills: UserSkillV2Dto[],
  skillId?: number
) => {
  if (!skillId) return undefined;
  return userSkills.find((usk) => usk.skillId === skillId);
};

export const getScoreFromHeadscanPercentage = (
  headscanPercentage: number
): number => {
  // Ensure the minimum value it can goto is 3.
  // This is used for showing some progress on the gauge
  return Math.max(3, headscanPercentage);
};

export const getBgImageSource = (
  src: string,
  isDesktop?: boolean,
  isForeground?: boolean
) => {
  const val = isDesktop ? "desktop" : "mobile";

  let result = src.replace("{0}", val);

  if (!isForeground) {
    result = result.replace("bg-goals", "bg-goals/blurred");
  }

  return result;
};

export const getGoalColor = (goal?: GoalDto) => {
  if (!goal) return "";

  switch (goal.domain) {
    case "social":
      return "#0c2454";
    case "intellectual":
      return "#103c55";
    case "motivational":
      return "#cb5f21";
    default:
      return "#5f1f80";
  }
};

export const getGoalsToUse = (
  goals: GoalDto[],
  goalWinThemes?: GoalWinThemeDto[],
  selectedGoal?: GoalDto
) => {
  let goalsToUse =
    goalWinThemes && goalWinThemes.length > 0
      ? goals.filter((x) => goalWinThemes.some((y) => y.id === x.winThemeId))
      : goals;

  // filter out the user's current goal
  return selectedGoal && goalsToUse.length > 1
    ? goalsToUse.filter((x) => x.id !== selectedGoal.id)
    : goalsToUse;
};

export const getSuggestedGoalFromHeadscanResponses = (
  responses: HeadScanResponse[],
  goals: GoalDto[],
  company: CompanyDto | null,
  selectedGoal?: GoalDto
) => {
  if (responses.length === 0) return goals[0];

  // get the lowest response
  const lowestResponse = responses.reduce((prev, current) =>
    prev.scale < current.scale ? prev : current
  );

  const goalsToUse = getGoalsToUse(goals, company?.goalWinThemes, selectedGoal);

  // determine the goal based on lowest response
  let goal = goalsToUse.find(
    (x) => x.headscanQuestionId === lowestResponse.questionId
  );

  // if we didn't answer a headscan question in one of the allowed goals, just pick the first one
  if (!goal) {
    goal = goalsToUse[0];
  }

  return goal;
};

export const getGoalMinSkillLevel = (
  userSkillStats: UserSkillV2Dto[],
  selectedGoal: GoalDto | undefined
) => {
  if (!selectedGoal) return -1;

  let minLevel: number = -1;
  for (const id of selectedGoal.skillIds) {
    const userStats = getUserSkillBySkillId(userSkillStats, id);

    if (!userStats) return 0;

    if (minLevel === -1) {
      minLevel = userStats.level;
    } else {
      minLevel = Math.min(minLevel, userStats.level);
    }
  }

  return minLevel;
};

export const saveSelectedGoal = (
  setGoalToServer: (goal: GoalDto) => void,
  userSkillStats: UserSkillV2Dto[],
  newGoal: GoalDto,
  curGoal: GoalDto | undefined
) => {
  // track
  track(EVENTS.GoalSelected, {
    HV_Goal: newGoal.name,
    HV_GoalId: newGoal.id,
    HV_OldGoal: curGoal?.name,
  });

  // don't re-save existing goal
  if (curGoal && curGoal.id === newGoal.id) return;

  // save
  setGoalToServer(newGoal);

  // set the welcome modal trigger
  //  if we're already at the target level already, we'll forward date a while so we wait to check-in
  //  if we're not, we'll date to today so we see it tomorrow
  const days =
    getGoalMinSkillLevel(userSkillStats, newGoal) >=
    SOLO_GOAL_SKILL_LEVEL_TARGET
      ? 13
      : 0;

  localStorage.setItem(
    SOLO_GOAL_WELCOME_BACK_KEY,
    dayjs().add(days, "day").format("YYYY-MM-DD")
  );
};

const toRoman = (num: number) => {
  const values = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const symbols = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];
  let result = "";

  for (let i = 0; i < values.length; i++) {
    while (num >= values[i]) {
      result += symbols[i];
      num -= values[i];
    }
  }

  return result;
};

export const getNameFromLevel = (level: number) => {
  if (level >= 11) {
    return `${HVLocalizeStrings.LEVEL_NAMES[9]} ${toRoman(level - 9)}`;
  } else {
    return HVLocalizeStrings.LEVEL_NAMES[level - 1];
  }
};

export const getDescriptionFromLevel = (level: number) => {
  return HVLocalizeStrings.LEVEL_DESCRIPTIONS[Math.min(10, level - 1)];
};

export const getPointsForLevel = (level: number) => {
  switch (level) {
    case 1:
      return 2;
    case 2:
      return 10;
    case 3:
      return 20;
    case 4:
      return 40;
    case 5:
      return 80;
    case 6:
      return 150;
    case 7:
      return 250;
    case 8:
      return 400;
    case 9:
      return 550;
    case 10:
      return 700;
    default:
      if (level > 10) {
        return level * 150 - 800;
      }
      return 0;
  }
};

export const getLevelFromPoints = (points: number) => {
  for (let i = 1; i <= 99999; i++) {
    if (points < getPointsForLevel(i)) {
      return i - 1;
    }
  }

  return 0;
};

export const getTotalPoints = (userSkillStats: UserSkillV2Dto[]) => {
  return parseInt(
    userSkillStats.reduce((acc, skill) => acc + skill.points, 0).toFixed()
  );
};

export enum StreakType {
  None,
  Day,
  Week,
  Month,
}

/*
 * singlePhrase flag is used to return non-plural in English and plural in French/Spanish
 *  (for phrases like "x week streak" in English which is "streak of 1 week" or "streak of x weeks" in French/Spanish)
 */
export const getStreakNameFromType = (
  type: StreakType,
  len: number,
  singlePhrase: boolean
) => {
  switch (type) {
    case StreakType.Day:
      return len > 1
        ? singlePhrase
          ? HVLocalizeStrings.STREAK_DAY_SINGLE_PHRASE
          : HVLocalizeStrings.STREAK_DAY_PLURAL
        : HVLocalizeStrings.STREAK_DAY.toLowerCase();
    case StreakType.Week:
      return len > 1
        ? singlePhrase
          ? HVLocalizeStrings.STREAK_WEEK_SINGLE_PHRASE
          : HVLocalizeStrings.STREAK_WEEK_PLURAL
        : HVLocalizeStrings.STREAK_WEEK.toLowerCase();
    case StreakType.Month:
      return len > 1
        ? singlePhrase
          ? HVLocalizeStrings.STREAK_MONTH_SINGLE_PHRASE
          : HVLocalizeStrings.STREAK_MONTH_PLURAL
        : HVLocalizeStrings.STREAK_MONTH.toLowerCase();
    default:
      return "";
  }
};

export const getAllActivityCompletionDates = (
  nanoPracticeInstances: Dictionary<NanoPracticeUserInstanceDto[]>,
  microLessons: MicroLessonDto[],
  blogPosts: BlogPostDto[]
) => {
  const a = microLessons.flatMap((l) =>
    l.microLessonUserInstances.map((x) => dayjs.utc(x.updatedAt.toString()))
  );

  // get all activity completion dates
  const b = Object.values(nanoPracticeInstances)
    .flat()
    .map((x) => dayjs.utc(x.createdAt.toString()));

  const c = blogPosts.flatMap((p) =>
    p.blogPostUserViews.map((x) => dayjs.utc(x.createdAt.toString()))
  );

  return a.concat(b).concat(c);
};

export interface StreakDetails {
  type: StreakType;
  length: number;
  isIncomplete: boolean;
}

/**
 * Check if the user has completed an activity for a number of days, weeks, or months in a row
 *
 * @activityCompletions - The dates of the user's completed activities
 * @reminders - An array of reminders where the first item in the array is the user's active reminder, if any
 */
export const getStreakInfo = (
  activityCompletions: Dayjs[],
  reminders?: SchedulerDto[]
): StreakDetails => {
  const freq =
    reminders && reminders.length > 0
      ? getFrequencyFromScheduleExpression(reminders[0].scheduleExpression)
      : SchedulerFrequency.Daily;

  const today = dayjs().startOf("day");

  if (freq === SchedulerFrequency.Daily) {
    // start going back day by day, starting from today, and see if the user did something each day
    let dayStreak = 1;
    let i = 1;
    while (true) {
      const day = today.subtract(i, "day");

      const completed = activityCompletions.some((x) =>
        x.local().isSame(day, "day")
      );

      if (!completed) break;

      dayStreak++;
      i++;
    }

    // check if the user completed something this period
    const completedToday = activityCompletions.some((x) =>
      x.local().isSame(today, "day")
    );

    if (dayStreak > 1 || completedToday) {
      return {
        type: StreakType.Day,
        length: dayStreak,
        isIncomplete: !completedToday,
      };
    }
  } else if (freq === SchedulerFrequency.Weekly) {
    // get the Sunday before today
    const startOfThisWeek = today.subtract(today.day(), "day");

    // start going back week by week, starting from today, and see if the user did something each week
    let weekStreak = 1;
    let j = 1;
    while (true) {
      const weekStart = startOfThisWeek.subtract(j, "week");
      const weekEnd = startOfThisWeek.subtract(j - 1, "week");

      const completed = activityCompletions.some(
        (x) => x.local().isAfter(weekStart) && x.local().isBefore(weekEnd)
      );

      if (!completed) break;

      weekStreak++;
      j++;
    }

    // check if the user completed something this period
    const completedThisWeek = activityCompletions.some((x) =>
      x.local().isAfter(startOfThisWeek)
    );

    if (weekStreak > 1 || completedThisWeek) {
      return {
        type: StreakType.Week,
        length: weekStreak,
        isIncomplete: !completedThisWeek,
      };
    }
  } else if (freq === SchedulerFrequency.Monthly) {
    // start going back month by month, starting from today, and see if the user did something each month
    let monthStreak = 1;
    let k = 1;
    while (true) {
      const month = today.subtract(k, "month");

      const completed = activityCompletions.some((x) =>
        x.local().isSame(month, "month")
      );

      if (!completed) break;

      monthStreak++;
      k++;
    }

    // check if the user completed something this period
    const completedThisMonth = activityCompletions.some((x) =>
      x.local().isSame(today, "month")
    );

    if (monthStreak > 1 || completedThisMonth) {
      return {
        type: StreakType.Month,
        length: monthStreak,
        isIncomplete: !completedThisMonth,
      };
    }
  }

  return { type: StreakType.None, length: 0, isIncomplete: true };
};

export enum PostActivityStep {
  None,
  LevelUp,
  Reminder,
  Streak,
  ChooseTraining,
  LaunchTraining,
}

export const getPostActivityStep = (
  curStep: PostActivityStep,
  initPoints: number,
  curPoints: number,
  activityCompletionDates: Dayjs[],
  schedulerReminders: SchedulerDto[]
) => {
  // did we level up?
  const leveledUp =
    getLevelFromPoints(initPoints) !== getLevelFromPoints(curPoints);

  if (curStep < PostActivityStep.LevelUp && leveledUp) {
    track(EVENTS.PostActivityLevelUpShown);

    return PostActivityStep.LevelUp;
  }

  if (curStep < PostActivityStep.Reminder && schedulerReminders.length === 0) {
    // get the last time we showed the reminder
    const lastShown = localStorage.getItem(SOLO_GOAL_CHALLENGE_KEY);
    const daysSince = !lastShown
      ? 1
      : dayjs().startOf("day").diff(dayjs(lastShown), "days");

    // always after we level up, otherwise once per day
    if (leveledUp || daysSince >= 1) {
      // set local storage to now
      localStorage.setItem(
        SOLO_GOAL_CHALLENGE_KEY,
        dayjs().format("YYYY-MM-DD")
      );

      track(EVENTS.PostActivityReminderShown);
      return PostActivityStep.Reminder;
    }
  }

  if (curStep < PostActivityStep.Streak) {
    // get the last time we showed the streak notification
    const lastShown = localStorage.getItem(SOLO_GOAL_STREAK_KEY);
    const daysSince = !lastShown
      ? 1
      : dayjs().startOf("day").diff(dayjs(lastShown), "days");

    const streak = getStreakInfo(activityCompletionDates, schedulerReminders);

    if (streak.length > 0) {
      const daysBetween =
        streak.type === StreakType.Month
          ? 30
          : streak.type === StreakType.Week
          ? 7
          : 1;

      if (daysSince >= daysBetween) {
        // set local storage to now
        localStorage.setItem(
          SOLO_GOAL_STREAK_KEY,
          dayjs().format("YYYY-MM-DD")
        );

        track(EVENTS.PostActivityStreakShown);
        return PostActivityStep.Streak;
      }
    }
  }

  if (curStep < PostActivityStep.ChooseTraining) {
    track(EVENTS.StartTrainingShown, {
      HV_From: "PostActivity",
    });
    return PostActivityStep.ChooseTraining;
  }

  track(EVENTS.PostActivityLaunchTraining);
  return PostActivityStep.LaunchTraining;
};
