import { Box, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { Swiper } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import { useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Black30,
  Border_Radius,
  CONTRAST_TEXT_COLOR,
  Grey,
} from "../../Styles/HeadversityStyle";

interface ScrollPanelProps {
  scrollOnMobileOnly?: Boolean;
  children: any;
  slidesPerView?: any;
  slidesPerGroup?: number;
  autoPlayDelay?: number;
  showChevronContainer?: boolean;
  showChevronOnMobile?: boolean;
  spaceBetween?: number;
  isPaginationOnContainer?: boolean;
  onSlideChangeCallBack?: (index: number) => void;
  customBulletClass?: string;
  pagination?: string; // "hide" = visibility: hidden or "remove" = display: none;
  currentIndex?: number;
  bottomPadding?: boolean;
  invertChevronContainer?: boolean;
  centeredSlides?: boolean;
  noCenteredOffset?: boolean;
  noChevronBg?: boolean;
}

export const ScrollPanel = (props: ScrollPanelProps) => {
  const {
    scrollOnMobileOnly,
    children,
    slidesPerGroup,
    slidesPerView,
    autoPlayDelay,
    showChevronContainer,
    showChevronOnMobile,
    spaceBetween,
    isPaginationOnContainer,
    onSlideChangeCallBack,
    customBulletClass,
    pagination,
    currentIndex,
    bottomPadding,
    invertChevronContainer,
    centeredSlides,
    noCenteredOffset,
    noChevronBg,
  } = props;
  const leftElement = useRef(null);
  const rightElement = useRef(null);
  const swiperRef = useRef(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const indexRef = useRef<number>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (swiperRef && swiperRef.current && !autoPlayDelay) {
      (swiperRef.current as any)?.autoplay.stop();
    }
  }, [swiperRef, autoPlayDelay]);

  useEffect(() => {
    if (
      currentIndex === undefined ||
      currentIndex < 0 ||
      !swiperRef ||
      !swiperRef.current
    )
      return;

    // debounce to allow fast slide change
    indexRef.current = currentIndex;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      try {
        (swiperRef.current as any).slideTo(indexRef.current);
      } catch {}
    }, 100);
  }, [currentIndex]);

  if (isDesktop && scrollOnMobileOnly) {
    return children;
  }

  const noPagination = pagination !== undefined;

  return (
    <Box
      className="no-ease" // prevent conflict with slider's own transform/sizing
      pos={"relative"}
      w={"100%"}
      onMouseEnter={() => {
        (swiperRef.current as any)?.autoplay.stop();
      }}
      /* revisit if we want more Netflix-style
      onMouseMove={() => {
        if ((leftElement.current as any).className.indexOf('swiper-button-disabled') === -1) {
          (leftElement.current as any).style.visibility = 'visible';
        }

        if ((rightElement.current as any).className.indexOf('swiper-button-disabled') === -1) {
          (rightElement.current as any).style.visibility = 'visible';
        }
      }}
      */
      onMouseLeave={() => {
        if (autoPlayDelay) {
          (swiperRef.current as any)?.autoplay.start();
        }
        /*
        (leftElement.current as any).style.visibility = 'hidden';
        (rightElement.current as any).style.visibility = 'hidden';
        */
      }}
    >
      {showChevronContainer && (
        <Box
          ref={leftElement}
          background={noChevronBg ? undefined : Black30}
          width={"30px"}
          height={"calc(100% - 40px)"}
          zIndex={2}
          position={"absolute"}
          className={`chevron-container ${
            noPagination ? "no-pagination chevron-on-mobile " : ""
          } ${showChevronOnMobile ? "chevron-on-mobile " : ""}`}
          cursor={"pointer"}
          borderRadius={Border_Radius}
          borderRightRadius={0}
          filter={invertChevronContainer ? "invert(1)" : undefined}
        >
          <IconButton
            className={"chevron-icon"}
            pos={"absolute"}
            left={isDesktop ? "0px" : "-3px"}
            top={"calc(50% - 16px)"}
            zIndex={10}
            icon={<ChevronLeftIcon />}
            aria-label={"left"}
            borderRadius={"50%"}
            size={"sm"}
            color={
              CONTRAST_TEXT_COLOR
            } /*TODO: Revise what color to select for Icon buttons */
            borderColor={Grey}
            borderWidth={"0px"}
            fontSize={"24px"}
            bg={"none"}
            _hover={{}}
          />
        </Box>
      )}
      {showChevronContainer && (
        <Box
          ref={rightElement}
          background={noChevronBg ? undefined : Black30}
          width={"30px"}
          height={"calc(100% - 40px)"}
          zIndex={2}
          position={"absolute"}
          right={"0px"}
          className={`chevron-container ${
            noPagination ? "no-pagination " : ""
          } ${noPagination || showChevronOnMobile ? "chevron-on-mobile " : ""}`}
          cursor={"pointer"}
          borderRadius={Border_Radius}
          borderLeftRadius={0}
          filter={invertChevronContainer ? "invert(1)" : undefined}
        >
          <IconButton
            className={"chevron-icon"}
            pos={"absolute"}
            right={isDesktop ? "0px" : "-3px"}
            top={"calc(50% - 16px)"}
            zIndex={10}
            icon={<ChevronRightIcon />}
            aria-label={"right"}
            borderRadius={"50%"}
            size={"sm"}
            color={CONTRAST_TEXT_COLOR}
            borderColor={Grey}
            borderWidth={"0px"}
            fontSize={"24px"}
            bg={"none"}
            _hover={{}}
          />
        </Box>
      )}
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper as any;
        }}
        onSlideChange={(e) => {
          if (onSlideChangeCallBack) {
            onSlideChangeCallBack(e.activeIndex);
          }
        }}
        slidesPerView={isDesktop ? slidesPerView : "auto"}
        slidesPerGroup={slidesPerGroup}
        modules={[Navigation, Pagination, Autoplay, FreeMode]}
        freeMode={{
          enabled: true,
          sticky: true,
          momentumRatio: 0.05,
          minimumVelocity: 0.25,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
          //  custom className needs to be added to index.css
          bulletClass: `swiper-pagination-bullet ${customBulletClass} ${
            isPaginationOnContainer ? "on-container" : ""
          }`,
        }}
        autoplay={{
          delay: autoPlayDelay ?? 10000,
        }}
        navigation={{
          enabled: true,
          nextEl: rightElement.current,
          prevEl: leftElement.current,
        }}
        spaceBetween={spaceBetween ?? 20}
        className={`${
          pagination === "remove"
            ? "remove-pagination"
            : noPagination
            ? "no-pagination"
            : ""
        }`}
        style={{ height: bottomPadding ? "50px" : undefined }}
        centeredSlides={centeredSlides}
        centeredSlidesBounds={!noCenteredOffset ? centeredSlides : undefined}
      >
        {children}
      </Swiper>
    </Box>
  );
};
