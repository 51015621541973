import { LargeModal } from "../../Common/LargeModal";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ParticipantIntroStep } from "./ParticipantIntroStep";
import { ParticipantProgressStep } from "./ParticipantProgressStep";
import { ParticipantFeedbackStep } from "./ParticipantFeedbackStep";
import { ParticipantCatchUpStep } from "./ParticipantCatchUpStep";
import { Box, Flex, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import {
  setOpenWrapperForPollModals,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import {
  getKey,
  addSearchParam,
  removeSearchParam,
} from "../../../Utils/Helpers";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import { PathDto, PathTeamLessonDto } from "@headversity/contract";
import { useNavigate } from "react-router";

export interface ParticipantTeamLessonStepProps {
  pathTeamLesson: PathTeamLessonDto | undefined;
}

interface ParticipantModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  forceCurrentStep: string | null;
  teamLessonUserInstanceId?: number;
  catchUpCallBack?: () => void;
  closeIntroModalCallback?: () => void;
}

export const PARTICIPANT_INTRO_STEP = "intro";
export const PARTICIPANT_IN_PROGRESS_STEP = "in-progress";
export const PARTICIPANT_CATCH_UP_STEP = "catch-up";
export const PARTICIPANT_END_STEP = "end";
export const PARTICIPANT_CANCEL_STEP = "cancel";

export const ParticipantModal = (props: ParticipantModalProps) => {
  const {
    open,
    setOpen,
    forceCurrentStep,
    teamLessonUserInstanceId,
    catchUpCallBack,
    closeIntroModalCallback,
  } = props;
  const [currentStep, setCurrentStep] = useState<string | null>(null);
  const [size, setSize] = useState<string | undefined>(undefined);
  const {
    paths,
    removeSelfForTeamLessonParticipationOnServer,
    setParticipantModalOpen,
    getPathsFromServer,
    getPollInstancesFromServer,
    currentTeam,
    participantPathTeamLesson,
    updateParticipantTeamLesson,
    resetTeamSession,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { confirmationDialogRef, safeAreaInsets } =
    useContext<IShellProvider>(ShellContext);

  const navigate = useNavigate();

  useEffect(() => {
    setSize(undefined);
    if (
      currentStep === PARTICIPANT_CATCH_UP_STEP ||
      currentStep === PARTICIPANT_IN_PROGRESS_STEP
    ) {
      setSize("full");
    }

    if (currentStep === PARTICIPANT_IN_PROGRESS_STEP) {
      window.onunload = (e) => {
        e.preventDefault();
        fetch(
          `${process.env.REACT_APP_API_URL}/api/teamLessonUserInstances/${teamLessonUserInstanceId}/leaveParticipation`,
          {
            keepalive: true,
            method: "POST",
            headers: {
              Authorization: `Bearer ${getKey()}`,
            },
            body: JSON.stringify({}),
          }
        );
      };
    } else {
      window.onunload = () => {};
    }
  }, [currentStep]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        currentStep &&
        [PARTICIPANT_INTRO_STEP, PARTICIPANT_IN_PROGRESS_STEP].indexOf(
          currentStep
        ) > -1
      ) {
        getPathsFromServer();
        getPollInstancesFromServer();
      }
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (paths) {
      let notFound = true;
      const keys = Object.keys(paths);
      keys.forEach((key) => {
        if (key !== "0") {
          paths[key]?.forEach((path: PathDto) => {
            path.pathTeamLessons?.forEach(
              (pathTeamLesson: PathTeamLessonDto) => {
                if (
                  pathTeamLesson.teamLessonUserInstanceId ==
                  teamLessonUserInstanceId
                ) {
                  notFound = false;
                  if (teamLessonUserInstanceId) {
                    const searchParams = addSearchParam(
                      "teamLessonUserInstanceId",
                      teamLessonUserInstanceId.toString()
                    );
                    navigate(
                      {
                        search: searchParams.toString(),
                      },
                      { replace: true }
                    );
                  }
                  updateParticipantTeamLesson(pathTeamLesson);
                  if (forceCurrentStep) {
                    setCurrentStep(forceCurrentStep);
                  } else if (
                    pathTeamLesson.teamLessonInstanceStatus ===
                    TeamLessonUserInstanceStatus.Initialized
                  ) {
                    setCurrentStep(PARTICIPANT_INTRO_STEP);
                  } else if (
                    pathTeamLesson.teamLessonInstanceStatus ===
                    TeamLessonUserInstanceStatus.InProgress
                  ) {
                    setCurrentStep(PARTICIPANT_IN_PROGRESS_STEP);
                  } else if (
                    pathTeamLesson.teamLessonInstanceStatus ===
                    TeamLessonUserInstanceStatus.Catchup
                  ) {
                    setCurrentStep(PARTICIPANT_CATCH_UP_STEP);
                  } else if (
                    pathTeamLesson.teamLessonInstanceStatus ===
                    TeamLessonUserInstanceStatus.ForcedComplete
                  ) {
                    setCurrentStep(PARTICIPANT_END_STEP);
                  }
                }
              }
            );
          });
        }
      });

      if (notFound) {
        // teamLessonUserInstanceId not found, it means the teamLesson
        // got cancel.
        setCurrentStep(PARTICIPANT_CANCEL_STEP);
        const searchParams = removeSearchParam("teamLessonUserInstanceId");
        navigate(
          {
            search: searchParams.toString(),
          },
          { replace: true }
        );
      }
    }
  }, [paths]);

  let hideCloseButton = false;
  let view = <></>;
  switch (currentStep) {
    case PARTICIPANT_INTRO_STEP:
      view = (
        <ParticipantIntroStep pathTeamLesson={participantPathTeamLesson} />
      );
      break;
    case PARTICIPANT_IN_PROGRESS_STEP:
      view = (
        <ParticipantProgressStep pathTeamLesson={participantPathTeamLesson} />
      );
      break;
    case PARTICIPANT_CATCH_UP_STEP:
      hideCloseButton = true;
      view = (
        <ParticipantCatchUpStep
          teamLessonUserInstanceId={teamLessonUserInstanceId}
          pathTeamLesson={participantPathTeamLesson}
          catchUpDoneCallBack={catchUpCallBack as () => void}
          closeIntroModalCallback={closeIntroModalCallback as () => void}
        />
      );
      break;
    case PARTICIPANT_END_STEP:
      view = (
        <ParticipantFeedbackStep
          teamLessonUserInstanceId={teamLessonUserInstanceId}
          pathTeamLesson={participantPathTeamLesson}
          closeFunction={() => {
            setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
          }}
        />
      );
      break;
    case PARTICIPANT_CANCEL_STEP:
      view = (
        <Flex
          flexDir={"column"}
          align={"center"}
          justify={"center"}
          gap={"3"}
          minH={"200px"}
        >
          <Text
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontSize={"lg"}
            fontWeight={"semibold"}
          >
            {HVLocalizeStrings.TEAM_TEAM_LESSON_CANCEL_MESSAGE}
          </Text>
          <AnimatedButton
            text={HVLocalizeStrings.CONFIRM_OK}
            onClick={() => {
              setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
            }}
          />
        </Flex>
      );
      break;
  }

  return (
    <>
      <LargeModal
        open={open}
        setOpen={
          ((value: boolean) => {
            if (
              !value &&
              currentStep !== PARTICIPANT_END_STEP &&
              currentStep !== PARTICIPANT_CANCEL_STEP &&
              currentStep !== PARTICIPANT_CATCH_UP_STEP
            ) {
              confirmationDialogRef.current.confirmation(
                HVLocalizeStrings.TEAM_PARTICIPATE_END,
                HVLocalizeStrings.TEAM_PARTICIPATE_END_MESSAGE,
                ConfirmationButtons.YesNo,
                () => {
                  trackTeamLesson(
                    TEAM_EVENTS.LeavePresentation,
                    currentTeam,
                    participantPathTeamLesson
                  );
                  setParticipantModalOpen(false);
                  updateParticipantTeamLesson(null);
                  removeSelfForTeamLessonParticipationOnServer(
                    teamLessonUserInstanceId as number
                  );
                  setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
                }
              );
            } else {
              setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
            }
          }) as any
        }
        size={size as any}
        hideHeader={hideCloseButton}
        preventClose={hideCloseButton}
      >
        <Box
          pt={`${safeAreaInsets.top}px`}
          pb={`${safeAreaInsets.bottom}px`}
          h={"100%"}
        >
          {view}
        </Box>
      </LargeModal>
    </>
  );
};
