import {
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  BLOCK_SECTION_SECONDARY_TEXT_COLOR,
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { ReactNode, useEffect, useState } from "react";

interface BlockSectionTitleProps {
  title: any;
  image?: string;
  description?: any;
  descriptionMaxWidth?: string;
  primaryTextColor?: string;
  children?: ReactNode;
  descriptionMarginTop?: number;
  marginTop?: number;
}

const BlockSectionTitle = (props: BlockSectionTitleProps) => {
  const {
    title,
    image,
    description,
    descriptionMaxWidth,
    descriptionMarginTop,
    primaryTextColor,
    children,
    marginTop,
  } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);

  const [fadeOut, setFadeout] = useState(false);
  const [titleToShow, setTitleToShow] = useState("");

  useEffect(() => {
    setFadeout(true);

    setTimeout(() => {
      setTitleToShow(title);
      setFadeout(false);
    }, 200);
  }, [title]);

  return (
    <>
      <HStack mt={marginTop}>
        {titleToShow.length > 0 && (
          <Heading
            color={primaryTextColor ?? BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            fontSize={isDesktop ? "1.6rem" : "xl"}
            borderBottom={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
            fontWeight={600}
            display={"inline"}
            lineHeight={isDesktop ? "36px" : "auto"}
            mb={1}
            data-testid={HVTestId.BlockSectionTitle.title}
            as="h3"
            opacity={fadeOut ? 0 : 1}
            transition={"opacity 0.2s ease-in-out"}
          >
            {titleToShow}
          </Heading>
        )}
        {image && (
          <Image
            src={image}
            h={"40px"}
            w={"auto"}
            ps={"5px"}
            filter={imageFilter}
            alt=""
          />
        )}
        {children}
      </HStack>
      {description && (
        <Text
          fontWeight={400}
          fontSize={isDesktop ? "15px" : "14px"}
          lineHeight="1.3"
          color={primaryTextColor ?? BLOCK_SECTION_SECONDARY_TEXT_COLOR}
          mt={descriptionMarginTop ?? 1}
          mb={isDesktop ? 4 : 3}
          maxWidth={descriptionMaxWidth ?? "100%"}
        >
          {description}
        </Text>
      )}
    </>
  );
};

export default BlockSectionTitle;
