import {
  CertInstanceDto,
  CertQualificationDto,
} from "../@headversity/contract";

export const isTimeForQualification = (
  certQualification: CertQualificationDto | undefined
): boolean => {
  return (
    certQualification !== undefined &&
    certQualification.pointsEarned < certQualification.pointsNeeded
  );
};

export const qualificationExists = (
  certInstances: CertInstanceDto[] | undefined
): boolean => {
  return (
    certInstances !== undefined &&
    certInstances?.length > 0 &&
    certInstances[0].certQualification !== undefined
  );
};
