import {
  Box,
  Button,
  Divider,
  Fade,
  Flex,
  Heading,
  Icon,
  Img,
  Link,
  List,
  ListItem,
  OrderedList,
  SlideFade,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import {
  CompletionState,
  QuestionDto,
  QuestionType,
  SurveyInstanceDto,
  SurveyQuestionDto,
  UserCertificateDto,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillFileText,
  AiFillLinkedin,
  AiOutlineCalendar,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { BiCircle, BiRightArrowAlt } from "react-icons/bi";
import { SurveyResponse } from "../../Hooks/Cert/useSurvey";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { ShellContext } from "../../State/ShellContext";
import {
  Border_Radius,
  HVDefaultTheme,
  LinkedIn_Blue,
  MODAL_PRIMARY_TEXT_COLOR,
  Respect_Maroon,
  Respect_Maroon_Light,
} from "../../Styles/HeadversityStyle";
import { EVENTS } from "../../Utils/Analytics";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { BottomActionBar } from "../Common/BottomActionBar";
import { LargeModal } from "../Common/LargeModal";
import { StepPanel } from "../Common/StepPanel";
import { CertStepViewModel } from "./CertViewModels";
import { Question } from "../Shared/Questionnaire/Question";
import { ConfirmationButtons } from "../Common/ConfirmationDialog";
import { writeToClipboard } from "../../Utils/mobileUtils";
import dayjs from "dayjs";
import { HVTestId } from "../../Testing/dataTestIds";
import { useNavigate } from "react-router-dom";

interface CertQuestionnaireModalProps {
  open: boolean;
  onClose: (shouldContinue: boolean) => void;
  preTitle: string;
  postTitle?: string;
  introInstructions?: string;
  outroInstructions?: string;
  bgImage: string;
  steps: CertStepViewModel[];
  canGoBack?: boolean;
  onReviewStep?: (step: CertStepViewModel) => void;
  onStarted: () => void;
  onComplete?: () => void;
  surveyInstance?: SurveyInstanceDto;
  instructionsOnly?: boolean;
  curStep: number;
  guid?: string;
  certificate?: UserCertificateDto;
  trackCertEvent: (
    eventName: string,
    curStep?: number,
    certSectionInfo?: boolean,
    certSurveyInfo?: boolean,
    surveyQuestion?: SurveyQuestionDto,
    questionAlternativeId?: number,
    surveyAnswer?: string
  ) => void;
  isTimeToQualify?: boolean;
  qualificationExists?: boolean;
}

export const CertQuestionnaireModal = ({
  open,
  onClose,
  preTitle,
  postTitle,
  introInstructions,
  outroInstructions,
  bgImage,
  steps,
  canGoBack,
  onReviewStep,
  onStarted,
  onComplete,
  surveyInstance,
  instructionsOnly,
  curStep,
  guid,
  certificate,
  trackCertEvent,
  isTimeToQualify,
  qualificationExists,
}: CertQuestionnaireModalProps) => {
  const survey = surveyInstance ? surveyInstance.survey : undefined;
  const surveyQuestions = survey ? survey.surveyQuestions : [];

  const [currentQuestionStep, setCurrentQuestionStep] = useState(-1);
  const [forceIntro, setForceIntro] = useState(false);
  const [forceOutro, setForceOutro] = useState(false);

  const { saveSurveyResponseToServer, setCloseDialog, selectedUserLanguage } =
    useContext<IGlobalProvider>(GlobalContext);

  const { confirmationDialogRef, safeAreaInsets, showToast } =
    useContext(ShellContext);

  const navigate = useNavigate();

  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionDto | undefined
  >(surveyQuestions.length > 0 ? surveyQuestions[0].question : undefined);
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    null
  );
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [hasWrongAnswer, setHasWrongAnswer] = useState<boolean>(false);
  const [hasCorrectAnswer, setHasCorrectAnswer] = useState<boolean>(false);

  const onSelectedResponseChanged = (val: string | number, index: number) => {
    setSelectedValue(val);
    setSelectedIndex(index);

    setHasWrongAnswer(false);
  };

  const loadQuestion = (questionToLoad: number) => {
    if (!surveyQuestions) return;

    const index = questionToLoad - 1;

    // previously answered?
    const question = surveyQuestions[index].question;

    setSelectedValue(null);
    setCurrentQuestion(question);
    setCurrentQuestionStep(questionToLoad);
  };

  const handleNextButtonClick = async () => {
    // on pre?
    if (isIntro) {
      if (!surveyInstance) {
        setSaving(true);
        onStarted();
      } else {
        setForceIntro(false);
      }
      return;
    }
    // on post?
    else if (isOutro) {
      onClose(true);
      if (qualificationExists) {
        navigate(`/cert/${certificate?.id}`);
      }
      return;
    }

    if (
      !currentQuestion?.optional &&
      (selectedValue === null || selectedIndex === null)
    )
      return;

    if (!surveyInstance || !currentQuestion) return;

    setSaving(true);

    // delay for the correct response icon display on exams
    const timeoutVal = surveyInstance.survey.percentCorrectToPass ? 1000 : 1;

    saveSurveyResponseToServer(
      surveyInstance.id,
      surveyQuestions[currentQuestionStep - 1].id,
      {
        questionAlternativeId:
          currentQuestion.questionType === QuestionType.FreeText ||
          selectedIndex === null
            ? undefined
            : currentQuestion.questionAlternatives[selectedIndex].id,
        responseText:
          selectedValue === null ? undefined : selectedValue.toString(),
      }
    ).then((response: SurveyResponse) => {
      trackCertEvent(
        EVENTS.CertAnswerSaved,
        curStep,
        false,
        true,
        surveyQuestions[currentQuestionStep - 1],
        currentQuestion.questionType === QuestionType.FreeText || !selectedIndex
          ? undefined
          : currentQuestion.questionAlternatives[selectedIndex].id,
        currentQuestion.questionType === QuestionType.FreeText || !selectedValue
          ? ""
          : selectedValue.toString()
      );

      if (response.isCorrect) {
        if (surveyInstance.survey.percentCorrectToPass) {
          setHasCorrectAnswer(true);
        }

        setTimeout(() => {
          setHasCorrectAnswer(false);
          setSaving(false);

          const next = currentQuestionStep + 1;

          // up to the outro?
          if (next === surveyQuestions.length + 1) {
            // TODO CERT 1.1: score

            // this will trigger a refresh of the cert data
            if (onComplete) {
              setSaving(true);
              onComplete();
            }
          } else {
            loadQuestion(next);
          }
        }, timeoutVal);
      } else {
        setSaving(false);
        setSelectedIndex(null);
        setSelectedValue(null);

        setHasWrongAnswer(true);
        setTimeout(() => {
          setHasWrongAnswer(false);
        }, 2500);
      }
    });
  };

  const getButtonText = () => {
    if (isTimeToQualify) {
      return HVLocalizeStrings.CERT_STAY_QUALIFIED;
    } else if (qualificationExists) {
      // The user completed their qualification
      return HVLocalizeStrings.REVIEW_QUALIFICATION;
    } else {
      return isOutro && steps.filter((x) => !x.isDone).length === 0
        ? HVLocalizeStrings.CLOSE
        : HVLocalizeStrings.NEXT;
    }
  };

  useEffect(() => {
    if (certificate?.completedDate && window.parent) {
      window.parent.postMessage(
        `
      API.LMSSetValue('cmi.core.lesson_status', 'completed');
      API.LMSCommit('');
      `,
        "*"
      );
    }
  }, [certificate]);

  useEffect(() => {
    setSaving(false);

    if (instructionsOnly) {
      setForceIntro(false);
      setForceOutro(true);
      return;
    }

    if (!surveyInstance) {
      setForceIntro(true);
      setForceOutro(false);
      return;
    }

    if (surveyInstance.state === CompletionState.done) {
      setForceIntro(false);
      setForceOutro(true);

      return;
    }

    // if we were saving, we just created our instance, so skip intro
    if (saving) {
      setForceIntro(false);
    } else {
      setForceIntro(true);
      setForceOutro(false);
    }

    // for in-progress, start from the last answered question
    if (surveyInstance.state === CompletionState.notStarted) {
      loadQuestion(1);
    } else {
      // find the first unanswered question
      const firstUnanswered = surveyQuestions.findIndex(
        (x) =>
          surveyInstance.questionUserResponses.find(
            (y) => y.questionId === x.questionId
          ) === undefined
      );

      if (firstUnanswered === -1) return;

      loadQuestion(firstUnanswered + 1);
    }
  }, [surveyInstance]);

  const isIntro = forceIntro || currentQuestionStep === 0;
  const isOutro =
    forceOutro || currentQuestionStep === surveyQuestions.length + 1;

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const showIntroOutro = isDesktop && (isIntro || isOutro);

  const certificateLinks = guid && certificate && certificate.completedDate && (
    <VStack pt={"4"} spacing={1} color="black">
      <Button
        w="100%"
        bgColor={`${Respect_Maroon_Light}DE`}
        _hover={{ bgColor: `${Respect_Maroon_Light}FF` }}
        color="white"
        fontSize={"14px"}
        fontWeight="400"
        onClick={() => {
          trackCertEvent(EVENTS.CertDownload);
          window.open(
            `${process.env.REACT_APP_CERT_LINK_URL}/cert-instance?guid=${guid}`,
            "_blank"
          );
        }}
        data-testid={HVTestId.CertQuestionnaireModal.downloadLink}
      >
        <Icon mr={1} boxSize={"18px"} as={AiFillFileText} />
        {HVLocalizeStrings.CERT_COMPLETE_DOWNLOAD_LINK}
      </Button>
      <Button
        w="100%"
        bgColor={`${LinkedIn_Blue}DE`}
        _hover={{ bgColor: `${LinkedIn_Blue}FF` }}
        color="white"
        fontSize={"14px"}
        fontWeight="400"
        data-testid={HVTestId.CertQuestionnaireModal.shareLink}
        onClick={(e) => {
          trackCertEvent(EVENTS.CertShareOnLinkedIn);
          (confirmationDialogRef.current as any).confirmation(
            HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_TITLE,
            <Flex px={"6"} pt={"2"} w={"100%"}>
              <List color={MODAL_PRIMARY_TEXT_COLOR}>
                <ListItem py={"2"}>
                  {
                    HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_1
                  }
                </ListItem>
                <ListItem py={"2"}>
                  {
                    HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_2
                  }
                </ListItem>
                <ListItem>
                  <OrderedList>
                    <ListItem>
                      <Text color={MODAL_PRIMARY_TEXT_COLOR}>
                        <Link
                          textDecoration={"underline"}
                          fontWeight={"semibold"}
                          onClick={() => {
                            trackCertEvent(EVENTS.CertShareOnLinkedInClickHere);
                            const certDate = dayjs(
                              certificate?.completedDate.toString()
                            ).local();

                            const encodedCertName = encodeURIComponent(
                              certificate.certName as string
                            );
                            window.open(
                              `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodedCertName}&organizationId=11779896&issueYear=${certDate.year()}&issueMonth=${
                                certDate.month() + 1
                              }&certUrl=${
                                process.env.REACT_APP_CERT_LINK_URL
                              }/cert-instance?guid%3D${guid}%26utm_source%3Dlinkedin%26utm_medium%3Dsocial%26utm_campaign%3D${encodeURIComponent(
                                encodedCertName
                              )}`,
                              "_blank"
                            );
                          }}
                        >
                          {HVLocalizeStrings.CLICK_HERE}
                        </Link>
                        {
                          HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_1
                        }
                      </Text>
                    </ListItem>
                    <ListItem>
                      {
                        HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_2
                      }
                    </ListItem>
                    <ListItem>
                      {
                        HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_3
                      }
                    </ListItem>
                    <ListItem>
                      {
                        HVLocalizeStrings.CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_4
                      }
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem py={"2"}>
                  <Flex flexDir={"column"} gap={"3"} align={"center"}>
                    <Text
                      color={MODAL_PRIMARY_TEXT_COLOR}
                      dangerouslySetInnerHTML={{
                        __html: HVLocalizeStrings.CERT_LINKEDIN_INSTRUCTIONS,
                      }}
                    />
                    <Flex
                      flexDir={"column"}
                      cursor={"pointer"}
                      onClick={async () => {
                        await writeToClipboard(
                          certificate?.certificateImageUrl as string
                        );
                        showToast(
                          true,
                          "linkedInCopyToast",
                          `${HVLocalizeStrings.CERT_BADGE_COPIED_TO_CLIP_BOARD}`
                        );
                      }}
                    >
                      <Img
                        src={certificate?.certificateImageUrl as string}
                        mt={"2"}
                        maxW={"250px"}
                      ></Img>
                    </Flex>
                  </Flex>
                </ListItem>
              </List>
            </Flex>,
            ConfirmationButtons.JSX,
            () => {},
            [
              <Flex
                key={"close"}
                gap={"5"}
                w={"100%"}
                flexDirection={isDesktop ? "row" : "column"}
                justify={"center"}
                justifyContent={"center"}
                align={"center"}
              >
                <AnimatedButton
                  text={HVLocalizeStrings.CLOSE}
                  onClick={() => {
                    setCloseDialog(true);
                  }}
                ></AnimatedButton>
              </Flex>,
            ],
            true,
            "2xl"
          );
        }}
      >
        <Icon mr={1} boxSize={"18px"} as={AiFillLinkedin} />

        {HVLocalizeStrings.CERT_COMPLETE_SHARE_LINKEDIN}
      </Button>
    </VStack>
  );

  return (
    <LargeModal
      open={open}
      setOpen={() => {
        onClose(false);
      }}
      title={""}
      bgImage={
        showIntroOutro
          ? bgImage
          : "https://cdn.headversity.com/app/resources/modal/modal-bg-light-cert.jpg"
      }
      noBgSmoothing={true}
      forceLightMode={!showIntroOutro}
      forceDarkMode={showIntroOutro}
      dataTestId={HVTestId.CertQuestionnaireModal.modal}
    >
      <Box minH={isDesktop ? "400px" : undefined}>
        {isIntro || isOutro ? (
          <Box
            color={isDesktop ? "white" : undefined}
            p={{ base: 2, md: 6 }}
            pt={"0 !important"}
          >
            <Text
              pr="25px"
              fontWeight={"600"}
              fontSize="22px"
              lineHeight={"1.3"}
              data-testid={HVTestId.CertQuestionnaireModal.title}
            >
              {isOutro && postTitle ? postTitle : preTitle}
            </Text>

            <Flex direction={{ base: "column", md: "row" }}>
              <Box
                pr={{
                  base: undefined,
                  md: "40px",
                }}
                w={{ base: "100%", md: "600px" }}
              >
                <>
                  {qualificationExists ? (
                    <>
                      <Box mt={4} lineHeight={{ base: "1.4", md: "1.3" }}>
                        <Heading as="h3" fontSize={"30px"}>
                          {isTimeToQualify
                            ? HVLocalizeStrings.CERT_MODAL_QUALIFY_HEADER
                            : HVLocalizeStrings.YOU_ARE_QUALIFIED}
                        </Heading>
                      </Box>
                      <Text
                        mt={"20px"}
                        fontSize={"md"}
                        dangerouslySetInnerHTML={{
                          __html: (isTimeToQualify
                            ? HVLocalizeStrings.CERT_MODAL_QUALIFY_SUB_TEXT
                            : HVLocalizeStrings.CERT_MODAL_ALREADY_QUALIFIED_SUB_TEXT
                          ).replace(
                            "{0}",
                            `<strong>${
                              certificate?.certName as string
                            }</strong>`
                          ),
                        }}
                      ></Text>
                    </>
                  ) : (
                    <Text
                      mt={4}
                      lineHeight={{ base: "1.4", md: "1.3" }}
                      dangerouslySetInnerHTML={{
                        __html: isIntro
                          ? introInstructions ?? ""
                          : outroInstructions ?? "",
                      }}
                      data-testid={HVTestId.CertQuestionnaireModal.desc}
                    />
                  )}
                </>
                {canGoBack && isIntro && (
                  <Text mt={4}>
                    {`${HVLocalizeStrings.CERT_FOR_A_QUICK_REMINDER} `}
                    <Link
                      textDecoration={"underline"}
                      href="javascript:void(0)" // focus and enter key support
                      onClick={() => {
                        if (onReviewStep) {
                          onReviewStep(steps[curStep - 1]);
                        }
                      }}
                    >
                      {` ${HVLocalizeStrings.CERT_REVIEW_THE_LESSON}`}
                    </Link>{" "}
                    {HVLocalizeStrings.CERT_BEFORE_YOU_PROCEED}
                  </Text>
                )}
              </Box>

              <Box
                pb={{ base: `${60 + safeAreaInsets.bottom}px`, md: undefined }}
              >
                {!isDesktop && certificateLinks}
                <InfoBox steps={steps} onReviewStep={onReviewStep} />
                {isDesktop && certificateLinks}
              </Box>
            </Flex>
          </Box>
        ) : (
          <SlideFade in={true}>
            <StepPanel
              questionHeader={true}
              showProgressBar={true}
              showBackArrow={false} // TODO CERT 1.1: allow user to go back on survey
              onBackClick={() => {
                loadQuestion(currentQuestionStep - 1);
              }}
              step={currentQuestionStep}
              totalStep={surveyQuestions.length}
              value={(currentQuestionStep / surveyQuestions.length) * 100}
              forceLightMode={true}
            >
              {currentQuestion && (
                <>
                  <Box
                    zIndex={hasCorrectAnswer || hasWrongAnswer ? 1 : undefined}
                  >
                    <Fade in={hasCorrectAnswer}>
                      <Box
                        w="100%"
                        pos={"absolute"}
                        top="35%"
                        left="0"
                        display={"flex"}
                        justifyContent="center"
                      >
                        <Text
                          bgColor={"white"}
                          height="100px"
                          width="100px"
                          borderRadius="50%"
                          textAlign={"center"}
                        >
                          <Icon
                            color={"#2e7d32"}
                            boxSize={hasCorrectAnswer ? "100px" : "0px"}
                            mr={1}
                            mb={1}
                            as={AiFillCheckCircle}
                            filter={
                              "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))"
                            }
                          />
                        </Text>
                      </Box>
                    </Fade>

                    <Fade in={hasWrongAnswer}>
                      <Box
                        p={8}
                        bgColor={"#ffffffee"}
                        w="100%"
                        pos={"absolute"}
                        top="35%"
                        left="0"
                        borderTop="solid 1px #660B3566"
                        borderBottom="solid 1px #660B3566"
                      >
                        <Text
                          fontSize="xl"
                          fontWeight={"500"}
                          color={Respect_Maroon}
                          textAlign={"center"}
                        >
                          <Icon mr={1} mb={1} as={AiFillExclamationCircle} />{" "}
                          {HVLocalizeStrings.CERT_NOT_QUITE}
                        </Text>
                      </Box>
                    </Fade>
                  </Box>

                  <Question
                    question={currentQuestion}
                    onSelectedResponseChanged={onSelectedResponseChanged}
                    textColor={HVDefaultTheme.colors[MODAL_PRIMARY_TEXT_COLOR]}
                    isDisabled={saving || hasCorrectAnswer || hasWrongAnswer}
                    onEnterKeyPress={handleNextButtonClick}
                  />
                </>
              )}
            </StepPanel>
          </SlideFade>
        )}

        <BottomActionBar
          isNextButtonDisabled={() => {
            if (saving) return true;
            if (isIntro || isOutro) return false;
            if (currentQuestion?.optional) return false;

            return selectedValue === null;
          }}
          nextButtonText={getButtonText()}
          nextButtonWidth={
            selectedUserLanguage !== "en" ||
            (qualificationExists && !isTimeToQualify) // More space needed for ES/FR or the text for if a user is qualified
              ? "100%"
              : undefined
          }
          onNextButtonClick={handleNextButtonClick}
          colorSet={AnimatedButtonColorSet.Cert}
        />
      </Box>
    </LargeModal>
  );
};

interface InfoBoxProps {
  steps: CertStepViewModel[];
  onReviewStep?: (step: CertStepViewModel) => void;
}

const InfoBox = ({ steps, onReviewStep }: InfoBoxProps) => {
  // 10 minutes for sections with lessonCourses and 5 minutes for sections without (exam sections)
  const timeRemaining =
    steps.filter(
      (x) => x.sectionInstance?.certSection.lessonCourse && !x.isDone
    ).length *
      10 +
    steps.filter(
      (x) => !x.sectionInstance?.certSection.lessonCourse && !x.isDone
    ).length *
      5;

  return (
    <Box
      p={5}
      color="black"
      background="white"
      w={{ base: "100%", md: "275px" }}
      borderRadius={Border_Radius}
      mt={5}
    >
      {steps.map((step, idx) => {
        const renderAs =
          !step.isDone && (idx === 0 || steps[idx - 1].isDone)
            ? BiRightArrowAlt
            : step.isDone
            ? AiOutlineCheckCircle
            : BiCircle;

        return (
          <Text
            key={idx}
            mt={1}
            fontSize={"13px"}
            opacity={
              step.isDone || idx === 0 || steps[idx - 1].isDone ? 1 : 0.4
            }
          >
            <Icon
              color={Respect_Maroon}
              mr={1}
              mb={"2px"}
              boxSize="18px"
              as={renderAs}
            />{" "}
            {step.sectionInstance?.certSection.lessonCourse &&
            step.isDone &&
            steps[idx + 1].isDone ? (
              <Link
                textDecoration={"underline"}
                href="javascript:void(0)" // focus and enter key support
                onClick={() => {
                  if (onReviewStep) {
                    onReviewStep(step);
                  }
                }}
              >
                {step.description}
              </Link>
            ) : (
              <>{step.description}</>
            )}
          </Text>
        );
      })}

      {timeRemaining > 0 && (
        <>
          <Divider my={4} />
          <Text mt={1} fontSize={"13px"}>
            <Icon color="#660B35" mr={1} mb={1} as={AiOutlineCalendar} />
            {HVLocalizeStrings.CERT_TIME_REMAINING.replace(
              "{0}",
              timeRemaining.toString()
            )}
          </Text>
        </>
      )}
    </Box>
  );
};
