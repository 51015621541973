import React, { useContext, useEffect } from "react";
import { HeaderContext, IHeaderProvider } from "../../State/HeaderContext";
import { Box } from "@chakra-ui/react";

interface HeaderProps {
  title?: any;
  description?: string;
  backgroundImageBase?: string;
  backgroundImage?: string;
  showLogo?: boolean;
}

export const HeaderSetter = ({
  title,
  description,
  backgroundImageBase,
  backgroundImage,
  showLogo,
}: HeaderProps) => {
  const { setHeaderState } = useContext<IHeaderProvider>(HeaderContext);

  useEffect(() => {
    setHeaderState({
      title,
      description,
      backgroundImageBase,
      backgroundImage,
      showLogo,
    });
  }, [description, backgroundImageBase, backgroundImage, showLogo, title]);

  return <Box pb={4}></Box>;
};
