import { useBreakpointValue } from "@chakra-ui/react";
import {
  CertInstanceDto,
  CertSectionInstanceDto,
  CompletionState,
  LessonQuestionAnswerDto,
  SurveyQuestionDto,
  UserCertificateDto,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import {
  Equity_Blue,
  Respect_Maroon,
  Respect_Maroon_20,
} from "../../Styles/HeadversityStyle";
import { postMessageToIframe } from "../../Utils/LessonsUtil";
import BackButton from "../Common/BackButton";
import { FullScreenModalDialog } from "../Common/FullScreenModalDialog";
import {
  Rise360NavMenu,
  Rise360NavMenuItem,
} from "../Shared/Rise360/Rise360Menu";
import Rise360Player, {
  FRAME_ID,
  GO_BACK,
} from "../Shared/Rise360/Rise360Player";
import { ContentBlock20 } from "../Solo/Shared/ContentBlock20";
import { CertStepViewModel, CertViewModel } from "./CertViewModels";
import { CertQuestionnaireModal } from "./CertQuestionnaireModal";
import { GlobalContext } from "../../State/GlobalContext";
import { EVENTS, track } from "../../Utils/Analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../Testing/dataTestIds";
import {
  isTimeForQualification,
  qualificationExists,
} from "../../Utils/CertUtil";
import { Rise360LessonViewModel } from "../Shared/Rise360/Rise360LessonViewModel";

interface CertBlockInterface {
  cert: CertViewModel;
}

export const CertBlock = ({ cert }: CertBlockInterface) => {
  const {
    setCertInstanceToServer,
    setCertSectionInstanceStateToServer,
    setCertInstanceStateToServer,
    getCertsFromServer,
    saveCertSectionQuestionAnswersToServer,
    certQualifications,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [questionnaireModalOpen, setQuestionnaireModalOpen] = useState(false);
  const [riseOpen, setRiseOpen] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [isReviewingLesson, setIsReviewingLesson] = useState(false);
  const [queryStringParams, setQueryStringParams] = useState([
    { name: "mode", value: "cert" },
  ]);
  const [curStep, setCurStep] = useState(-1);
  const [navItems, setNavItems] = useState<Rise360NavMenuItem[]>([]);
  const [forceQuestionnaireOpen, setForceQuestionnaireOpen] = useState(false);
  const [title, setTitle] = useState<string | undefined>("");
  const [isTimeToQualify, setIsTimeToQualify] = useState<boolean>(false);
  const [rise360Lesson, setRise360Lesson] = useState<
    Rise360LessonViewModel | undefined
  >(undefined);

  const handleStepStarted = async () => {
    if (curStep === 0) {
      // API: if no instanceId, create one
      if (cert.certification.certInstances.length === 0) {
        await setCertInstanceToServer(cert.certification.id);
      }
    }
  };

  const handleSectionStateChanged = async (
    step: CertStepViewModel,
    state: CompletionState
  ) => {
    const section = step.section;
    if (!section || !step.sectionInstance) return;

    // API: mark section started
    await setCertSectionInstanceStateToServer(step.sectionInstance.id, {
      state: state,
    } as CertSectionInstanceDto);
  };

  const handleStepComplete = async () => {
    if (curStep === cert.steps.length - 1) {
      trackCertEvent(EVENTS.CertComplete);
      // API: mark instance done
      await setCertInstanceStateToServer(
        cert.certification.certInstances[0].id,
        { state: CompletionState.done } as CertInstanceDto
      );
    } else {
      await getCertsFromServer();
    }
  };

  const openLesson = (step: CertStepViewModel, isReviewing: boolean) => {
    // override with any existing instance url
    const instanceId = step.sectionInstance?.lessonCourseUserInstanceId;
    if (instanceId && step.sectionInstance?.certSection.lessonCourse) {
      setRise360Lesson({
        lessonCourseUserInstanceId: instanceId,
        startingUrl: step.sectionInstance.certSection.lessonCourse.startingUrl,
      });
    }

    // set gui
    setIsReviewingLesson(isReviewing);
    setTitle(step.preTitle);
    setRiseOpen(true);

    // track
    trackCertEvent(
      isReviewing ? EVENTS.CertReviewingLesson : EVENTS.CertSectionLessonOpened,
      cert.steps.findIndex((x) => x === step),
      true
    );
  };

  useEffect(() => {
    if (isReviewingLesson) {
      setQueryStringParams([
        { name: "mode", value: "cert" },
        { name: "review", value: "1" },
      ]);
    } else {
      setQueryStringParams([{ name: "mode", value: "cert" }]);
    }
  }, [isReviewingLesson]);

  useEffect(() => {
    // go to the first not "done" step
    let index = cert.steps.findIndex((x) => !x.isDone);
    if (index === -1) {
      index = cert.steps.length - 1;
    }

    setCurStep(index);

    /*
     * simulate going to specific step
     *
    setCurStep(5);
    */
  }, [cert]);

  useEffect(() => {
    if (curStep === -1) return;

    if (curStep >= cert.steps.length) {
      setCurStep(cert.steps.length - 1);
      return;
    }

    if (forceQuestionnaireOpen) {
      setForceQuestionnaireOpen(false);
      setRiseOpen(false);
      setQuestionnaireModalOpen(true);
    }

    setTitle(cert.steps[curStep].preTitle);
  }, [curStep]);

  useEffect(() => {
    if (questionnaireModalOpen || riseOpen) {
      navigate(window.location.pathname + `?certId=${cert.certification.id}`, {
        replace: true,
      });
    }
  }, [questionnaireModalOpen, riseOpen]);

  useEffect(() => {
    const certParam = searchParams.get("certId");
    if (!certParam) {
      return;
    }

    if (cert.certification.id.toString() !== certParam) {
      // if the cert that the user is navigating to is another one, make sure the modal for this one is closed
      setQuestionnaireModalOpen(false);
    } else {
      // always start from the intro
      setQuestionnaireModalOpen(true);
    }
    setRiseOpen(false);
  }, [searchParams]);

  useEffect(() => {
    // if the user has completed the cert, and 90 days have passed since completion, and the user has not yet qualified, then let the user know
    const timeToQualify = isTimeForQualification(
      certQualifications.find((x) => x.certId === cert.certification.id)
    );
    if (timeToQualify !== isTimeToQualify) {
      setIsTimeToQualify(timeToQualify);
    }
  }, [certQualifications]);

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const progressValue = Math.round(
    (cert.steps.filter((x) => x.isDone).length / cert.steps.length) * 100
  );

  const trackCertEvent = (
    eventName: string,
    curStep?: number,
    certSectionInfo?: boolean,
    certSurveyInfo?: boolean,
    surveyQuestion?: SurveyQuestionDto,
    questionAlternativeId?: number,
    surveyAnswer?: string
  ) => {
    let propertiesToTrack: any = {
      HV_Cert: cert.certification.name_loc?.en,
      HV_CertId: cert.certification.id,
      HV_CertInstanceId: cert.certification.certInstances[0]?.id,
      HV_CertStep: curStep,
    };
    if (curStep !== undefined) {
      if (certSectionInfo) {
        propertiesToTrack.HV_CertSection =
          cert.steps[curStep].section?.name_loc?.en;
        propertiesToTrack.HV_CertSectionId =
          cert.steps[curStep].sectionInstance?.certSectionId;
        propertiesToTrack.HV_CertSectionInstanceId =
          cert.steps[curStep].sectionInstance?.id;
        propertiesToTrack.HV_LessonCourseId =
          cert.steps[curStep].sectionInstance?.certSection.lessonCourse.id;
        propertiesToTrack.HV_LessonCourseUserInstanceId =
          rise360Lesson?.lessonCourseUserInstanceId;
      }
      if (certSurveyInfo) {
        propertiesToTrack.HV_SurveyId =
          cert.steps[curStep].surveyInstance?.survey.id;
        propertiesToTrack.HV_SurveyInstanceId =
          cert.steps[curStep].surveyInstance?.id;
        propertiesToTrack.HV_SurveyQuestionId = surveyQuestion?.id;
        propertiesToTrack.HV_QuestionId = surveyQuestion?.questionId;
        propertiesToTrack.HV_QuestionAlternativeId = questionAlternativeId;
        propertiesToTrack.HV_Cert_SurveyAnswer = surveyAnswer;
      }
    }
    track(eventName, propertiesToTrack);
  };

  /*
   * simulate lesson completion
   *

  useEffect(() => {
    if (!rise360Lesson) return;

    handleSectionStateChanged(cert.steps[curStep], CompletionState.done);

    setForceQuestionnaireOpen(true);
  }, [rise360Lesson]);
  */

  return (
    <>
      <ContentBlock20
        width={!isDesktop ? `${window.innerWidth - 40}px` : undefined}
        height="225px"
        imageUrl={cert.certification.imageThumbnailUrl as string}
        title={cert.certification.name as string}
        subtitle={cert.certification.shortDescription as string}
        progressValue={progressValue}
        onClick={() => {
          // always start from the intro
          setQuestionnaireModalOpen(true);
          trackCertEvent(EVENTS.CertOpened, curStep);
        }}
        doneState={
          progressValue === 100
            ? CompletionState.done
            : progressValue > 0
            ? CompletionState.inProgress
            : CompletionState.notStarted
        }
        titleAreaBg={"#ffffffee"}
        titleColor={Equity_Blue}
        subtitleColor={Equity_Blue}
        dataTestId={`${HVTestId.CertBlock.block}${cert.certification.id}`}
        bannerText={
          isTimeToQualify
            ? HVLocalizeStrings.CERT_MODAL_QUALIFY_HEADER
            : undefined
        }
        bannerBgColor={Respect_Maroon_20}
        progressIcon={isTimeToQualify ? "calendar" : undefined}
      />

      {questionnaireModalOpen && (
        <CertQuestionnaireModal
          open={questionnaireModalOpen}
          preTitle={cert.steps[curStep].preTitle ?? ""}
          postTitle={cert.steps[curStep].postTitle}
          bgImage={cert.certification.imageUrl as string}
          steps={cert.steps}
          introInstructions={cert.steps[curStep].preInstructions}
          outroInstructions={
            cert.steps[curStep].sectionInstance?.certSection.lessonCourse &&
            curStep > 0
              ? cert.steps[curStep - 1].postInstructions
              : cert.steps[curStep].postInstructions
          }
          isTimeToQualify={isTimeForQualification(
            cert.certification.certInstances.length > 0
              ? cert.certification.certInstances[0].certQualification
              : undefined
          )}
          qualificationExists={qualificationExists(
            cert.certification.certInstances
          )}
          surveyInstance={cert.steps[curStep].surveyInstance}
          instructionsOnly={
            cert.steps[curStep].sectionInstance?.certSection.lessonCourse !==
            undefined
          }
          canGoBack={
            curStep > 0 &&
            cert.steps[curStep - 1].sectionInstance?.certSection
              .lessonCourse !== undefined
          }
          onReviewStep={(step: CertStepViewModel) => {
            const instanceId = step.sectionInstance?.lessonCourseUserInstanceId;
            if (!instanceId) return;

            openLesson(step, true);
          }}
          guid={cert.certification.certAssignments[0]?.guid}
          certificate={
            {
              id: cert.certification.id,
              certName: cert.certification.name,
              completedDate:
                cert.certification?.certInstances[0]?.completedDate,
              certificateImageUrl:
                cert.certification.certificateImageUrl ??
                `https://cdn.headversity.com/cert/${HVLocalizeStrings.CERT_LINKEDIN_SHARE_IMAGE_FILENAME}`,
            } as UserCertificateDto
          }
          onStarted={() => {
            handleStepStarted();
            trackCertEvent(EVENTS.CertStarted, curStep);
          }}
          onComplete={() => {
            trackCertEvent(EVENTS.CertStepComplete, curStep, true, true);
            handleStepComplete();
          }}
          onClose={(shouldContinue: boolean) => {
            if (shouldContinue && progressValue !== 100) {
              const step = cert.steps[curStep];
              if (
                step.sectionInstance &&
                step.sectionInstance.certSection.lessonCourse
              ) {
                handleSectionStateChanged(step, CompletionState.inProgress);

                openLesson(step, false);

                return;
              }
            } else {
              // get latest for user who closes midway through a survey
              getCertsFromServer();
              navigate(window.location.pathname, { replace: true });
            }

            setQuestionnaireModalOpen(false);
          }}
          curStep={curStep}
          trackCertEvent={trackCertEvent}
        />
      )}

      {rise360Lesson && riseOpen && (
        <FullScreenModalDialog
          title={title}
          titleBgColor={Respect_Maroon}
          titlePaddingLeft={isReviewingLesson && !isDesktop ? "36px" : ""}
          open={riseOpen}
          setOpen={(val: boolean) => {
            setQuestionnaireModalOpen(true);
            setRiseOpen(false);

            /*
             * SIMULATE GOING TO NEXT SECTION
             *
            handleSectionStateChanged(
              cert.steps[curStep],
              CompletionState.done
            );

            setForceQuestionnaireOpen(true);
            */
          }}
          leftHeader={
            <>
              {isReviewingLesson && <Rise360NavMenu items={navItems} />}
              {!isReviewingLesson && showBackButton && (
                <BackButton
                  /*
                  // TODO: post message should really be at the Rise360Player layer (see comments in PresentationPresentView.tsx)
                  */
                  onButtonClick={() => {
                    trackCertEvent(EVENTS.CertRiseBack, curStep, true);
                    postMessageToIframe(FRAME_ID, GO_BACK);
                  }}
                />
              )}
            </>
          }
        >
          <Rise360Player
            rise360Lesson={rise360Lesson}
            queryStringParams={queryStringParams}
            onLessonComplete={() => {
              trackCertEvent(EVENTS.CertSectionLessonComplete, curStep, true);
              handleSectionStateChanged(
                cert.steps[curStep],
                CompletionState.done
              );

              setForceQuestionnaireOpen(true);
            }}
            lessonTitle={cert.steps[curStep].section?.name as string}
            onLessonListChanged={(lessonList) => {
              const items = [] as Rise360NavMenuItem[];
              for (let x = 0; x < lessonList.length; x++) {
                if (lessonList[x].stepNumber) {
                  items.push({
                    text: lessonList[x].title,
                    index: x,
                  } as Rise360NavMenuItem);
                }
              }
              setNavItems(items);
            }}
            onShouldShowBackButton={setShowBackButton}
            onQuestionAnswer={(obj, slideIndex) => {
              const sectionInstanceId = cert.steps[curStep]?.sectionInstance
                ?.id as number;
              if (sectionInstanceId) {
                saveCertSectionQuestionAnswersToServer(sectionInstanceId, {
                  slideIndex,
                  questionAnswerDtos: obj,
                } as LessonQuestionAnswerDto);
              }
            }}
          />
        </FullScreenModalDialog>
      )}
    </>
  );
};
