import { Text } from "@chakra-ui/react";
import {
  Border_Radius,
  ERROR_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import React from "react";

interface FormErrorProps {
  message: string | null | undefined;
}

export const ErrorText = ({ message }: FormErrorProps) =>
  message && message.length > 0 ? (
    <Text
      fontWeight={"600"}
      color={ERROR_TEXT_COLOR}
      fontSize={"sm"}
      lineHeight={1.2}
      mb={2}
      dangerouslySetInnerHTML={{ __html: message as any }}
      data-testid={HVTestId.ErrorText.text}
    />
  ) : (
    <></>
  );
