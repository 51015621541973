import {
  ReportDto,
  UserCountsReportDto,
  UserEnrollmentReportDto,
} from "@headversity/contract";
import { useCallback, useState } from "react";
import { reportUsers } from "../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getKey } from "../../../Utils/Helpers";
import {
  getPointStartFromDate,
  parseDateFromYearMonth,
} from "../../../Utils/ChartsUtil";

export interface IUsersReportProvider {
  fetchReport: () => Promise<void>;
  userEnrollmentData?: UserEnrollmentSeriesData;
  userPercentageData: UserPercentageData;
  intervalDays: number;
  lowData: boolean;
  isLoading: boolean;
}

export interface UserEnrollmentSeriesData {
  data: number[];
  dates: string[];
  graphStartDate: number;
}

type UserPercentageData = {
  enrolledUserHeading: string;
  enrolledUsersDescription: string;
  activeUsersHeading: string;
  activeUsersDescription: string;
};

const mapUserCountsData = (
  dto: ReportDto<UserCountsReportDto>
): UserPercentageData => {
  if (dto.noData) {
    return {
      enrolledUserHeading: "0%",
      enrolledUsersDescription: "0",
      activeUsersHeading: "0%",
      activeUsersDescription: "0",
    };
  }

  const report = dto.report!;

  const enrolledUserHeading = `${Math.round(
    report.enrollment_percentage * 100.0
  )}%`;
  const enrolledUsersDescription = `${dto.report!.enrollment_count} ${
    HVLocalizeStrings.REACH_INSIGHTS_OUT_OF
  } ${report.total_licenses} ${
    HVLocalizeStrings.REACH_INSIGHTS_ELIGIBLE_USERS
  }`;
  const activeUsersHeading = `${Math.round(report.active_percentage * 100.0)}%`;
  const activeUsersDescription = `${report.active_users} ${HVLocalizeStrings.REACH_INSIGHTS_OUT_OF} ${report.enrollment_count} ${HVLocalizeStrings.REACH_INSIGHTS_ENROLLED_USERS}`;
  const result: UserPercentageData = {
    enrolledUserHeading,
    enrolledUsersDescription,
    activeUsersHeading,
    activeUsersDescription,
  };
  return result;
};

const mapEnrollmentData = (
  dto: ReportDto<UserEnrollmentReportDto[]>
): UserEnrollmentSeriesData | undefined => {
  if (dto.noData) {
    return undefined;
  }
  const report = dto.report!;
  const mapped = report.map((uer) => Number(uer.running_enrollment_count));
  const dateString = report[0].date;
  const dateUTC = parseDateFromYearMonth(dateString);
  const graphStartDate = getPointStartFromDate(dateUTC);
  return {
    data: mapped,
    dates: report.map((uer) => uer.date),
    graphStartDate,
  };
};

export const useUsersReport = (): IUsersReportProvider => {
  const [userEnrollmentData, setUserEnrollmentData] = useState<
    UserEnrollmentSeriesData | undefined
  >();
  const [userPercentageData, setUserPercentageData] =
    useState<UserPercentageData>({
      enrolledUserHeading: "0%",
      enrolledUsersDescription: "0",
      activeUsersHeading: "0%",
      activeUsersDescription: "0",
    });
  const [lowData, setLowData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [intervalDays, setIntervalDays] = useState<number>(180);

  const fetchReportHandler = useCallback(async () => {
    setIsLoading(true);
    const reportResp = await reportUsers(getKey());
    const mappedPercentData = mapUserCountsData(reportResp.data.userCounts);
    setUserPercentageData(mappedPercentData);
    setUserEnrollmentData(mapEnrollmentData(reportResp.data.userEnrollment));
    setLowData(
      reportResp.data.userCounts.noData || reportResp.data.userEnrollment.noData
    );
    setIntervalDays(reportResp.data.intervalDays);
    setIsLoading(false);
  }, []);
  return {
    fetchReport: fetchReportHandler,
    userEnrollmentData,
    userPercentageData,
    lowData,
    isLoading,
    intervalDays,
  };
};
